import * as React from "react";
import {connect} from "react-redux";
import {Button, Form, Grid, Header, Icon, Message, Segment} from "semantic-ui-react";
import {find} from "lodash";
import {AppState} from "../../state";
import {
    IUserInfo,
    IUserSettings,
    IClientConcept,
    IBusinessUnit,
    BusinessUnitType
} from "./api/applicationModels";
import DropdownTreeSelector from "./components/DropdownTreeSelector";
import {actionCreators} from "./state/actions";
import {getNewClientConceptFromSelectedBusinessUnitId} from "../../util/appParamsUtil";

interface IReduxProps {
    availableClientConcepts: IClientConcept[];
    selectedClientConcept: IClientConcept;
    userSettings: IUserSettings;
    userInfo: IUserInfo;
    savingUserSettings: boolean;
    saveUserSettingsResult: number;
    userSettingsErrorMessage: string | null;
    clientTreeHierarchy: IBusinessUnit[];
    rootGroupId: string;
    currentSelectedBusinessUnitId: string;
}

interface IState {
    currentUserSettings: IUserSettings;
    settingsState: "unchanged" | "unsaved changes" | "saved changes";
}

type UserSettingsProps = IReduxProps & typeof actionCreators;

class UserSettings extends React.Component<UserSettingsProps, IState> {
    constructor(props: UserSettingsProps) {
        super(props);

        this.changeUserSettings = this.changeUserSettings.bind(this);

        this.state = {
            currentUserSettings: props.userSettings,
            settingsState: "unchanged"
        };
    }

    componentDidUpdate(newProps: IReduxProps) {
        if (newProps.saveUserSettingsResult !== this.props.saveUserSettingsResult) {
            this.setState({settingsState: "saved changes"});
        }
    }

    // as more settings are added, they should end up calling this to preserve the settingsState functionality
    changeUserSettings(newSettings: IUserSettings) {
        let settingsState = this.state.settingsState;

        if (
            JSON.stringify(newSettings) !==
            JSON.stringify(this.state.currentUserSettings)
        ) {
            settingsState = "unsaved changes";
        } else if (this.state.settingsState === "saved changes") {
            return; // if nothing was changed and the button is green, keep it
        } else {
            settingsState = "unchanged";
        }

        this.setState({
            currentUserSettings: newSettings,
            settingsState
        });
    }

    handleBusinessUnitChange(businessUnitId: string) {
        const selectedBusinessUnit = find(
            this.props.clientTreeHierarchy,
            (bu) => bu.businessUnitId === businessUnitId
        );
        let isLocation = false;
        if (
            selectedBusinessUnit &&
            selectedBusinessUnit.businessUnitType === BusinessUnitType.Location
        ) {
            isLocation = true;
        }

        const newSettings: IUserSettings = {
            defaultBusinessUnitId: businessUnitId,
            isLocation: isLocation
        };
        this.changeUserSettings(newSettings);
    }

    handleSubmit() {
        if (this.state.settingsState === "unsaved changes") {
            // Change concepts if the new business unit has different concept than the default business unit (only if there was no business unit currently selected)
            // This happens when user goes to /dashboard and doesn't have default business unit selected
            if (this.props.currentSelectedBusinessUnitId === "") {
                const newClientConcept =
                    getNewClientConceptFromSelectedBusinessUnitId(
                        this.props.selectedClientConcept.clientConcept_PK,
                        this.props.availableClientConcepts,
                        this.props.clientTreeHierarchy,
                        this.state.currentUserSettings.defaultBusinessUnitId
                    );
                newClientConcept && this.props.changeClientConcept(newClientConcept);
            }
            this.props.switchCurrentSelectedBusinessUnit(
                this.state.currentUserSettings.defaultBusinessUnitId
            );
            this.props.saveUserSettings(this.state.currentUserSettings);
        }
    }

    renderButton() {
        let button;
        const size = "large";

        if (this.props.savingUserSettings) {
            button = (
                <Button primary loading size={size}>
                    Saving...
                </Button>
            );
        } else {
            switch (this.state.settingsState) {
                case "unsaved changes":
                    button = (
                        <Button primary size={size}>
                            Save Settings
                        </Button>
                    );
                    break;
                case "saved changes":
                    button = (
                        <Button positive size={size}>
                            <Icon name="checkmark" /> Settings Saved
                        </Button>
                    );
                    break;

                case "unchanged":
                default:
                    button = (
                        <Button primary size={size} disabled>
                            Save Settings
                        </Button>
                    );
            }
        }

        return button;
    }

    render() {
        return (
            <Grid className={"layout-grid"} stackable>
                <Grid.Row>
                    <Grid.Column stretched>
                        <Segment basic padded="very">
                            <Header size="huge">Settings</Header>
                            <Segment
                                textAlign="left"
                                padded="very"
                                className="body-segment"
                            >
                                <Form onSubmit={this.handleSubmit.bind(this)}>
                                    <Header>
                                        Select Primary Group or Restaurant
                                    </Header>
                                    <Form.Field inline width={6}>
                                        <DropdownTreeSelector
                                            clientTreeHierarchy={
                                                this.props.clientTreeHierarchy
                                            }
                                            onSelect={this.handleBusinessUnitChange.bind(
                                                this
                                            )}
                                            selectedValue={
                                                this.state.currentUserSettings
                                                    .defaultBusinessUnitId
                                            }
                                        />
                                    </Form.Field>
                                    {this.renderButton()}
                                </Form>
                                {this.props.userSettingsErrorMessage && (
                                    <Message error>
                                        <Message.Header>Error</Message.Header> Failed
                                        to save the user settings:{" "}
                                        {this.props.userSettingsErrorMessage}
                                    </Message>
                                )}
                            </Segment>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    availableClientConcepts: state.application.availableClientConcepts,
    selectedClientConcept: state.application.selectedClientConcept,
    userSettings: state.application.userSettings,
    userInfo: state.application.userInfo,
    savingUserSettings: state.application.savingUserSettings,
    saveUserSettingsResult: state.application.saveUserSettingsResult,
    userSettingsErrorMessage: state.application.userSettingsErrorMessage,
    clientTreeHierarchy: state.application.clientTreeHierarchy,
    rootGroupId: state.application.selectedClientConcept.rootGroupId,
    currentSelectedBusinessUnitId: state.application.currentSelectedBusinessUnitId
});

export default connect(
    mapStateToProps,
    {...actionCreators},
    (state, actions, props) => ({...state, ...actions, ...props})
)(UserSettings);

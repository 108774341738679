import apiUtil from "../../../util/apiUtil";
import {IAuthRequest, ILoginResponse} from "./authModels";

// functions here should use fetch to request data from the api
// and return objects that are defined in authModels.ts

// const URL = "/auth";
const authApi = {
    authenticate(authRequest: IAuthRequest): Promise<ILoginResponse> {
        return apiUtil.postData("/api/authenticate", authRequest);
    }
};

export default authApi;

import {find, includes, isEqual} from "lodash";
import * as React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Link} from "react-router-dom";
import {
    Button,
    Card,
    Checkbox,
    Icon,
    Placeholder,
    Statistic,
    Popup,
    SemanticWIDTHS,
    Dropdown
} from "semantic-ui-react";
import {actionCreators as applicationActionCreators} from "features/application/state/actions";
import {ErrorMessage, NoAccessMessage} from "components/SystemMessages";
import dataFormatUtil from "util/dataFormatUtil";
import {dateToString} from "util/dateUtil";
import metricDataUtil from "util/metricDataUtil";
import {getXtdDisplayText} from "util/xtdUtil";
import {
    IClientConcept,
    IBusinessUnit,
    DataStatus,
    IProblemDetails,
    coachFreemiumPageCodes,
    detectFreemiumPageCodes,
    deliveryFreemiumPageCodes
} from "../../../application/api/applicationModels";
import {actionCreators} from "../../../application/state/actions";
import {
    BusinessUnitAggregation,
    ThresholdContext
} from "../../../metricDetails/api/metricDetailsModels";
import metricApi from "../../api/dashboardAPI";
import {
    ICardRequest,
    IGroupKpiData,
    IMetricDataDailyThresholdViolationCounts,
    IModuleMetricDefinition,
    IMetricThreshold,
    Xtd
} from "../../api/dashboardModels";
import CardThresholdTooltip from "./CardThresholdTooltip";
import HouseContent from "./HouseContent";
import {
    getPage_PKForClientConceptAndPageName,
    getPageForPage_PK,
    MetricDetailsUrlModel
} from "util/appParamsUtil";
import {AppState} from "state";
import {PageCode} from "features/configuration/api/uiConfigurationModels";
import efficientHireLogo from "img/efficient-hire.svg";
import {ErrorMessageContent} from "util/errorUtil";
import {EditableCardHeader} from "./EditableCardHeader";

interface IProps extends IReduxProps {
    clientConcept: IClientConcept;
    clientCode: string;
    businessUnit: IBusinessUnit;
    date: Date;
    xtd: Xtd;
    businessUnitAggregation: BusinessUnitAggregation;
    calendarId: number;
    configuredMetricDefinitons: IModuleMetricDefinition[];
    page_PK: number;
    cardsPerRow?: SemanticWIDTHS;
    isEdit?: boolean;
    handleDrillDownPageDropdownChange?: (
        drilldownPage_PK: number,
        id: number[],
        drilldownPageCode: PageCode | undefined
    ) => void;
    handleCardHeaderNameChange?: (
        ids: number[],
        newMetricName: string,
        defaultCardHeaderName: string
    ) => void;
}

interface IState {
    dataStatusMetricDefinitions: DataStatus;
    dataMetricDefinitionsErrorMessage: string;
    dataStatusCards: DataStatus;
    dataCardsErrorMessage: string;
    dataStatusHouses: DataStatus;
    dataHousesErrorMessage: string;
    metricDefinitions: IModuleMetricDefinition[];
    cards: IGroupKpiData;
    houses: IMetricDataDailyThresholdViolationCounts[];
    activeMetrics: IModuleMetricDefinition[][];
}

// Xtds to display is hard coded
const xtdsOnCards = [Xtd.DAILY, Xtd.WTD, Xtd.PTD, Xtd.QTD, Xtd.YTD];

class Cards extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            dataStatusMetricDefinitions: DataStatus.Undefined,
            dataMetricDefinitionsErrorMessage: "",
            dataStatusCards: DataStatus.Undefined,
            dataCardsErrorMessage: "",
            dataStatusHouses: DataStatus.Undefined,
            dataHousesErrorMessage: "",
            metricDefinitions: [],
            cards: {
                metricData: {} as any,
                metricThresholds: []
            },
            houses: [],
            activeMetrics: []
        };

        this.handleToggle = this.handleToggle.bind(this);
        this.renderCards = this.renderCards.bind(this);
        this.renderHouses = this.renderHouses.bind(this);
        this.renderMetricNumber = this.renderMetricNumber.bind(this);
    }
    abortController = {
        current: new AbortController()
    };

    componentDidMount() {
        this.fetchCardsMetricDefinitions(
            this.props.clientConcept.clientConcept_PK,
            this.abortController.current.signal
        );
        this.fetchCards({
            clientConcept_PK: this.props.clientConcept.clientConcept_PK,
            businessUnit: this.props.businessUnit,
            date: this.props.date,
            calendarId: this.props.calendarId,
            concept: this.props.clientConcept.concept,
            page_PK: this.props.page_PK,
            abortSignal: this.abortController.current.signal
        });
        if (this.props.configuredMetricDefinitons.length === 0) {
            this.fetchHouses({
                clientConcept_PK: this.props.clientConcept.clientConcept_PK,
                businessUnit: this.props.businessUnit,
                date: this.props.date,
                calendarId: this.props.calendarId,
                concept: this.props.clientConcept.concept,
                page_PK: this.props.page_PK,
                abortSignal: this.abortController.current.signal
            });
        }
    }

    componentDidUpdate(prevProps: IProps) {
        // Metric definitions needs to be refetched when client concept changes but
        // can be preserved when other parameters change
        if (
            this.props.clientConcept.clientConcept_PK !==
            prevProps.clientConcept.clientConcept_PK
        ) {
            this.fetchCardsMetricDefinitions(
                this.props.clientConcept.clientConcept_PK,
                this.abortController.current.signal
            );
        }

        if (
            dateToString(this.props.date) !== dateToString(prevProps.date) ||
            this.props.businessUnit.businessUnitId !==
                prevProps.businessUnit.businessUnitId ||
            (this.props.metricConfigurationSaveButtonClicked === true &&
                this.props.metricConfigurationSaveButtonClicked !==
                    prevProps.metricConfigurationSaveButtonClicked) ||
            // This condition is used to trigger changes when users are editing the Cards
            (this.props.configuredMetricDefinitons.length > 0 &&
                !isEqual(
                    this.props.configuredMetricDefinitons,
                    prevProps.configuredMetricDefinitons
                ))
        ) {
            // This condition is used to trigger changes when users are editing the Cards
            if (
                this.props.configuredMetricDefinitons.length > 0 &&
                !isEqual(
                    this.props.configuredMetricDefinitons,
                    prevProps.configuredMetricDefinitons
                )
            ) {
                const activeMetrics = metricDataUtil.getMetricsInComponent(
                    this.props.configuredMetricDefinitons
                );

                this.setState({
                    activeMetrics: activeMetrics,
                    metricDefinitions: this.props.configuredMetricDefinitons
                });
            }

            this.abortController.current.abort();
            this.abortController.current = new AbortController();

            this.fetchCards({
                clientConcept_PK: this.props.clientConcept.clientConcept_PK,
                businessUnit: this.props.businessUnit,
                date: this.props.date,
                calendarId: this.props.calendarId,
                concept: this.props.clientConcept.concept,
                metricCodes:
                    this.props.configuredMetricDefinitons.length > 0
                        ? this.props.configuredMetricDefinitons.map(
                              (x) => x.metricCode
                          )
                        : [],
                page_PK: this.props.page_PK,
                abortSignal: this.abortController.current.signal
            });
            if (this.props.configuredMetricDefinitons.length === 0) {
                this.fetchHouses({
                    clientConcept_PK: this.props.clientConcept.clientConcept_PK,
                    businessUnit: this.props.businessUnit,
                    date: this.props.date,
                    calendarId: this.props.calendarId,
                    concept: this.props.clientConcept.concept,
                    page_PK: this.props.page_PK,
                    abortSignal: this.abortController.current.signal
                });
            }
        }
    }

    public componentWillUnmount() {
        this.abortController.current.abort();
    }

    fetchCardsMetricDefinitions(clientConcept_PK: number, abortSignal: AbortSignal) {
        this.setState({
            dataStatusMetricDefinitions: DataStatus.Loading
        });

        metricApi
            .fetchCardsMetricDefinitions(clientConcept_PK, abortSignal)
            .then((metricDefinitions) => {
                // Order cards, metrics, and metric values so they are displayed correctly
                const activeMetrics =
                    metricDataUtil.getMetricsInComponent(metricDefinitions);

                this.setState({
                    activeMetrics: activeMetrics,
                    metricDefinitions: metricDefinitions,
                    dataStatusMetricDefinitions: DataStatus.Loaded
                });
            })
            .catch((errorResponse: IProblemDetails) => {
                if (errorResponse.status === 403) {
                    this.setState({
                        dataStatusMetricDefinitions: DataStatus.Forbidden,
                        dataMetricDefinitionsErrorMessage: errorResponse.detail
                    });
                } else {
                    this.setState({
                        dataStatusMetricDefinitions: DataStatus.Failed,
                        dataMetricDefinitionsErrorMessage: errorResponse.detail
                    });
                }
            });
    }

    fetchCards(request: ICardRequest) {
        this.setState({dataStatusCards: DataStatus.Loading});

        metricApi
            .fetchCards(request)
            .then((data) => {
                this.setState({
                    cards: data,
                    dataStatusCards: DataStatus.Loaded
                });
            })
            .catch((errorResponse: IProblemDetails) => {
                if (errorResponse.status === 403) {
                    this.setState({
                        dataStatusCards: DataStatus.Forbidden,
                        dataCardsErrorMessage: errorResponse.detail
                    });
                } else if (errorResponse.name !== "AbortError") {
                    this.setState({
                        dataStatusCards: DataStatus.Failed,
                        dataCardsErrorMessage: errorResponse.detail
                    });
                }
            });
    }

    fetchHouses(request: ICardRequest) {
        this.setState({
            dataStatusHouses: DataStatus.Loading
        });

        metricApi
            .fetchHouses(request)
            .then((data) => {
                this.setState({
                    houses: data,
                    dataStatusHouses: DataStatus.Loaded
                });
            })
            .catch((errorResponse: IProblemDetails) => {
                if (errorResponse.name !== "AbortError") {
                    this.setState({
                        dataStatusHouses: DataStatus.Failed,
                        dataHousesErrorMessage: errorResponse.detail
                    });
                }
            });
    }

    handleToggle(
        sortOrderOfCardToToggle: number,
        e: React.FormEvent<HTMLInputElement>
    ) {
        // this is needed so the card drilldown doesn't fire when we click toggle
        e.preventDefault();

        this.setState({
            activeMetrics: updateActiveMetricsOnCards(
                this.state.activeMetrics,
                sortOrderOfCardToToggle
            )
        });
    }

    renderMetricNumber(metricsToDisplay: IModuleMetricDefinition[], xtd: Xtd) {
        const metricData = this.state.cards.metricData.metricData;
        const metricThresholds =
            this.state.cards.metricData.metricDataThresholdViolation;

        const metricNumberView = (
            <>
                {metricsToDisplay.map((metric, index) => {
                    const metricCode = metric.metricCode;
                    const metricValue =
                        metricData[metricCode] && metricData[metricCode][xtd];
                    const doesMetricViolateThreshold =
                        metricThresholds[metricCode] &&
                        metricThresholds[metricCode][xtd];

                    return (
                        <React.Fragment key={index + xtd}>
                            {formatCardValue(
                                dataFormatUtil.dataTableDisplayValue(
                                    metricValue,
                                    metric
                                ),
                                xtd,
                                doesMetricViolateThreshold
                            )}
                            {index !== metricsToDisplay.length - 1 &&
                                formatCardValue("/", xtd)}
                        </React.Fragment>
                    );
                })}
            </>
        );

        return (
            <div key={xtd} className={xtd === Xtd.DAILY ? "" : "text-align-right"}>
                {metricNumberView}
            </div>
        );
    }

    renderHouses(
        metricDefinition: IModuleMetricDefinition,
        metricDetailsUrlModel: MetricDetailsUrlModel,
        hasDrilldown: boolean,
        hasAccessToFullVersionPage: boolean,
        handleFreemiumMarketingModal: (freemiumMarketingModalOpen: boolean) => void
    ) {
        if (this.state.dataStatusHouses === DataStatus.Loading) {
            const placeHolderStyle = {
                display: "inline-block",
                width: "100px"
            };

            return (
                <Placeholder style={placeHolderStyle}>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        } else if (this.state.dataStatusHouses === DataStatus.Loaded) {
            // Note that "houses" data might contain less metrics than the total number of metrics on cards.
            // The reason for that is that for example VOC - we display houses for Highly Satisfied but not Dissatisfied
            const metricHousesToRender = find(
                this.state.houses,
                (m: IMetricDataDailyThresholdViolationCounts) => {
                    return (
                        m.metricCode.toUpperCase() ===
                        metricDefinition.metricCode.toUpperCase()
                    );
                }
            );

            return (
                <>
                    <HouseContent
                        metricDefinition={metricDefinition}
                        metricDetailsUrlModel={metricDetailsUrlModel}
                        thresholdContext={ThresholdContext.Good}
                        hasDrilldown={hasDrilldown}
                        numberOfLocationsForThreshold={
                            metricHousesToRender
                                ? metricHousesToRender.locationsWithinThreshold
                                : null
                        }
                        hasAccessToFullVersionPage={hasAccessToFullVersionPage}
                        handleFreemiumMarketingModal={handleFreemiumMarketingModal}
                    />
                    <HouseContent
                        metricDefinition={metricDefinition}
                        metricDetailsUrlModel={metricDetailsUrlModel}
                        thresholdContext={ThresholdContext.Bad}
                        hasDrilldown={hasDrilldown}
                        numberOfLocationsForThreshold={
                            metricHousesToRender
                                ? metricHousesToRender.locationsViolatingThreshold
                                : null
                        }
                        hasAccessToFullVersionPage={hasAccessToFullVersionPage}
                        handleFreemiumMarketingModal={handleFreemiumMarketingModal}
                    />
                    <HouseContent
                        metricDefinition={metricDefinition}
                        metricDetailsUrlModel={metricDetailsUrlModel}
                        thresholdContext={ThresholdContext.NotReporting}
                        hasDrilldown={hasDrilldown}
                        numberOfLocationsForThreshold={
                            metricHousesToRender
                                ? metricHousesToRender.notReporting
                                : null
                        }
                        hasAccessToFullVersionPage={hasAccessToFullVersionPage}
                        handleFreemiumMarketingModal={handleFreemiumMarketingModal}
                    />
                </>
            );
        } else if (this.state.dataStatusHouses === DataStatus.Failed) {
            return <ErrorMessage inline text={this.state.dataHousesErrorMessage} />;
        }
    }

    renderCards() {
        const drilldownPages = [
            {
                key: "no drilldown",
                text: "No drilldown page",
                value: 0
            },
            ...this.props.clientConceptPages
                .filter((page) => {
                    return page.pageCode.startsWith("MetricDetail_");
                })
                .map((page) => ({
                    key: page.page_PK,
                    text: page.pageName,
                    value: page.page_PK
                }))
        ];
        const {activeMetrics, cards} = this.state;
        const {
            isFreemium,
            doesUserHaveAccessToDetectForSelectedConcept,
            doesUserHaveAccessToDeliveryPageForSelectedConcept,
            cardsPerRow,
            isEdit
        } = this.props;

        return (
            <Card.Group itemsPerRow={cardsPerRow}>
                {
                    // Loop through active metric on each card
                    activeMetrics.map((cardMetrics, index) => {
                        // Collect the ids of all metrics, active and inactive
                        const allMetricsIds = cardMetrics.map((m) => m.id);
                        const activeCardMetrics = cardMetrics.filter(
                            (m) => m.active
                        ); // Get the active metrics on each card
                        const allActiveMetricsIds = activeCardMetrics.map(
                            (m) => m.id
                        );
                        const hasToggle =
                            activeCardMetrics[0].toggleOrder !== 0 ? true : false; // If ToggleOrder === 0 then it means that there is no toggle for this card
                        const metricSubHeaders: string[] = [];

                        const metricThresholdsForActiveMetrics: IMetricThreshold[] =
                            [];

                        // Default Card Header name, based on system metric names only (no alternate MetricOrderCategory)
                        const defaultCardHeaderName = activeCardMetrics
                            .map((m) => m.metricName)
                            .join(" / ");
                        // Displayed Card Header name, based on metricOrderCategory, if it exists, otherwise defaultCardHeaderName
                        const headerName =
                            activeCardMetrics[0].metricOrderCategory ||
                            defaultCardHeaderName;

                        const drilldownPageCode = activeCardMetrics[0]
                            .drilldownPageCode as PageCode;
                        const drilldownPage_PK =
                            activeCardMetrics[0].drilldownPage_PK;
                        activeCardMetrics.forEach((m) => {
                            if (m && m.active) {
                                // only display a subheader if it is different from defaultCardHeaderName
                                if (
                                    m.metricOrderCategory &&
                                    m.metricOrderCategory.trim() !==
                                        defaultCardHeaderName
                                ) {
                                    metricSubHeaders.push(m.metricName || "");
                                }

                                if (
                                    cards.metricThresholds &&
                                    cards.metricThresholds.length > 0
                                ) {
                                    // find the threshold values for the current metric
                                    const thresholdForMetric = find(
                                        cards.metricThresholds,
                                        (t) => {
                                            return t.metricCode === m.metricCode;
                                        }
                                    );
                                    thresholdForMetric &&
                                        metricThresholdsForActiveMetrics.push(
                                            thresholdForMetric
                                        );
                                }
                            }
                        });

                        const hasDrilldown = activeCardMetrics[0].drilldownPage_PK
                            ? true
                            : false;
                        const placeHolderStyle = {
                            display: "inline-block",
                            width: "100px"
                        };

                        // Find what metrics are behind the toggle and return their names
                        const metricsUnderToggle: string[] = [];
                        if (hasToggle) {
                            activeMetrics[index].forEach((m) => {
                                if (!m.active) {
                                    metricsUnderToggle.push(m.metricName);
                                }
                            });
                        }

                        // Freemium users have access to certain pages in Coach, Detect or Delivery
                        let hasAccessToFullVersionPage = isFreemium ? false : true;
                        if (includes(coachFreemiumPageCodes, drilldownPageCode)) {
                            hasAccessToFullVersionPage = true;
                        } else if (
                            includes(deliveryFreemiumPageCodes, drilldownPageCode) &&
                            doesUserHaveAccessToDeliveryPageForSelectedConcept
                        ) {
                            hasAccessToFullVersionPage = true;
                        } else if (
                            includes(detectFreemiumPageCodes, drilldownPageCode) &&
                            doesUserHaveAccessToDetectForSelectedConcept
                        ) {
                            hasAccessToFullVersionPage = true;
                        }

                        const enableDrilldownLink =
                            hasDrilldown && hasAccessToFullVersionPage && !isEdit;

                        const efficientHireMetricCodes = [
                            "HiringReviewRate",
                            "HiringAverageReviewTime",
                            "HiringAverageMessageTime",
                            "HiringRate"
                        ];
                        const isEfficientHire = find(activeCardMetrics, (metric) =>
                            efficientHireMetricCodes.includes(metric.metricCode)
                        );

                        // Define elements which vary on partner cards
                        let metricDetailsUrlModel: MetricDetailsUrlModel;
                        let xtdsToShow: Xtd[];
                        let topOfCard: JSX.Element | null;

                        // Set variables for partner or standard card
                        if (!isEfficientHire) {
                            // Standard card

                            // Create a metric detail url model for the drilldown link
                            const page = getPageForPage_PK(
                                this.props.clientConceptPages,
                                activeCardMetrics[0].drilldownPage_PK
                            );

                            metricDetailsUrlModel = new MetricDetailsUrlModel(
                                this.props.clientConcept,
                                page?.page_PK || 0,
                                this.props.businessUnit.businessUnitId,
                                null,
                                this.props.date,
                                page?.defaultXtd || Xtd.DAILY,
                                BusinessUnitAggregation.Locations,
                                undefined
                            );

                            // All XTDs are included
                            xtdsToShow = xtdsOnCards;

                            // Top of card shows Daily metric
                            topOfCard = (
                                <div className="content-box-container">
                                    <div className="content-box xtd">
                                        {displayXTD(Xtd.DAILY)}
                                    </div>
                                    <div className="content-box">
                                        {this.state.dataStatusCards !==
                                        DataStatus.Loading ? (
                                            this.renderMetricNumber(
                                                activeCardMetrics,
                                                Xtd.DAILY
                                            )
                                        ) : (
                                            <Placeholder style={placeHolderStyle}>
                                                <Placeholder.Line />
                                            </Placeholder>
                                        )}
                                    </div>
                                </div>
                            );
                        } else {
                            // Partner card

                            // Create a metric detail url model for the drilldown link
                            const page_PK = getPage_PKForClientConceptAndPageName(
                                this.props.clientConceptPages,
                                this.props.clientConcept.clientConcept_PK,
                                PageCode.MetricDetail_Custom,
                                "Hiring"
                            );
                            metricDetailsUrlModel = new MetricDetailsUrlModel(
                                this.props.clientConcept,
                                page_PK,
                                this.props.businessUnit.businessUnitId,
                                null,
                                this.props.date,
                                Xtd.WTD,
                                BusinessUnitAggregation.Locations,
                                undefined
                            );

                            // Limit XTDs
                            xtdsToShow = [Xtd.WTD, Xtd.PTD];

                            // Top of card has partner's logo and link
                            topOfCard = (
                                <Card.Content extra>
                                    <a
                                        href={
                                            "https://" +
                                            this.props.clientCode +
                                            ".jobaline.com/Employer/EmployerStatistics"
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Button
                                            basic
                                            className={"btn-blue partner-button"}
                                        >
                                            <img
                                                src={efficientHireLogo}
                                                alt="Efficient Hire Logo"
                                                className="partner-logo"
                                            />
                                            <Icon
                                                name="sign-in"
                                                size="large"
                                                alt="Sign-in Icon"
                                                className="sign-in-icon"
                                            />
                                        </Button>
                                    </a>
                                </Card.Content>
                            );
                        }

                        // Handles cardHeader editing on Edit Metrics page
                        const onCardHeaderNameChange = (
                            newCardHeaderName: string
                        ) => {
                            const updatedMetrics = activeMetrics.map((metrics) =>
                                metrics.map((metric) => {
                                    if (allActiveMetricsIds.includes(metric.id)) {
                                        metric.metricOrderCategory =
                                            newCardHeaderName || undefined;
                                    }
                                    return metric;
                                })
                            );

                            if (this.props.handleCardHeaderNameChange) {
                                this.props.handleCardHeaderNameChange(
                                    allActiveMetricsIds as number[],
                                    newCardHeaderName.trim(), // save trimmed cardHeaderName
                                    defaultCardHeaderName
                                );
                            }

                            this.setState({
                                activeMetrics: updatedMetrics
                            });
                        };

                        const getLastSavedMetricOrderCategory = (
                            metricId: number | undefined
                        ) => {
                            const metric = this.state.metricDefinitions.find(
                                (m) => m.id === metricId
                            );
                            return metric?.metricOrderCategory || undefined;
                        };

                        // Determines whether CardHeaderName has been modified
                        const getCardHeaderNameIsModified = () => {
                            const lastSavedMetricOrderCategory =
                                getLastSavedMetricOrderCategory(
                                    activeCardMetrics[0].id
                                );
                            const newMetricOrderCategory =
                                activeCardMetrics[0].metricOrderCategory?.trim() ||
                                undefined;

                            if (lastSavedMetricOrderCategory) {
                                // If there is a previously saved metricOrderCategory (alternate Card header name)
                                return (
                                    newMetricOrderCategory !==
                                    lastSavedMetricOrderCategory
                                );
                            } else {
                                // No previously saved metricOrderCategory, so also check that new cardHeaderName is not same as defaultCardHeaderName
                                return (
                                    newMetricOrderCategory !==
                                        lastSavedMetricOrderCategory &&
                                    newMetricOrderCategory !== defaultCardHeaderName
                                );
                            }
                        };

                        return (
                            <Card key={index} link={hasDrilldown ? true : false}>
                                <Card.Content
                                    as={enableDrilldownLink ? Link : undefined}
                                    to={
                                        enableDrilldownLink
                                            ? metricDetailsUrlModel.url
                                            : undefined
                                    }
                                    onClick={
                                        hasDrilldown && !hasAccessToFullVersionPage
                                            ? (e: React.MouseEvent<HTMLElement>) => {
                                                  e.preventDefault();
                                                  this.props.handleFreemiumMarketingModal(
                                                      true
                                                  );
                                              }
                                            : undefined
                                    }
                                    style={{marginBottom: "0px"}}
                                >
                                    <Card.Header>
                                        {hasToggle && (
                                            <Popup
                                                trigger={
                                                    <Checkbox
                                                        className="right floated colored-toggle"
                                                        style={{
                                                            paddingLeft: "8px"
                                                        }}
                                                        toggle
                                                        onChange={(e) =>
                                                            this.handleToggle(
                                                                activeCardMetrics[0]
                                                                    .sortOrder || 0,
                                                                e
                                                            )
                                                        }
                                                    />
                                                }
                                                content={metricsUnderToggle.join(
                                                    " / "
                                                )}
                                                position="top center"
                                                popperDependencies={[
                                                    headerName,
                                                    EditableCardHeader
                                                ]}
                                            />
                                        )}
                                        {/* We do [0] here because we assume that all submetrics (different MetricValueOrder) will have the same (primary) metric name and metricOrderCategory */}
                                        {this.props.isEdit ? (
                                            // Card header is editable on Edit Metrics page, allowing users to set MetricOrderCategory
                                            <EditableCardHeader
                                                defaultCardHeaderName={
                                                    defaultCardHeaderName
                                                }
                                                currentAlternateCardHeaderName={
                                                    activeCardMetrics[0]
                                                        .metricOrderCategory || ""
                                                }
                                                onChange={(newCardHeaderName) =>
                                                    onCardHeaderNameChange(
                                                        newCardHeaderName
                                                    )
                                                }
                                                valueIsModified={getCardHeaderNameIsModified()}
                                            />
                                        ) : (
                                            <>
                                                <span className="card-header-text">
                                                    {headerName}
                                                </span>{" "}
                                                {CardThresholdTooltip(
                                                    metricThresholdsForActiveMetrics,
                                                    activeCardMetrics,
                                                    xtdsToShow
                                                )}
                                            </>
                                        )}{" "}
                                    </Card.Header>
                                    <Card.Meta>
                                        {/* If metrics have sub-headers they will be joined here */}
                                        <div className="subheader">
                                            {metricSubHeaders.join(" / ")}
                                        </div>
                                    </Card.Meta>
                                    {this.props.isEdit && (
                                        <>
                                            <br></br>
                                            <div> {"Selected Drilldown:"} </div>
                                            <div style={{marginTop: "8px"}}>
                                                <Dropdown
                                                    // style={{}}
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "16px"
                                                    }}
                                                    compact
                                                    selection
                                                    options={drilldownPages}
                                                    value={drilldownPage_PK || 0}
                                                    onChange={(event, data) => {
                                                        const selectedPage_PK =
                                                            data.value as number;
                                                        const selectedPage =
                                                            getPageForPage_PK(
                                                                this.props
                                                                    .clientConceptPages,
                                                                selectedPage_PK
                                                            );

                                                        const updatedMetrics =
                                                            activeMetrics.map(
                                                                (metrics) =>
                                                                    metrics.map(
                                                                        (metric) => {
                                                                            if (
                                                                                allMetricsIds.includes(
                                                                                    metric.id
                                                                                )
                                                                            ) {
                                                                                return {
                                                                                    ...metric,
                                                                                    drilldownPage_PK:
                                                                                        selectedPage_PK,
                                                                                    drilldownPageCode:
                                                                                        selectedPage?.pageCode
                                                                                };
                                                                            }
                                                                            return metric;
                                                                        }
                                                                    )
                                                            );

                                                        if (
                                                            this.props
                                                                .handleDrillDownPageDropdownChange
                                                        ) {
                                                            this.props.handleDrillDownPageDropdownChange(
                                                                selectedPage_PK,
                                                                allMetricsIds as number[],
                                                                selectedPage?.pageCode
                                                            );
                                                        }
                                                        this.setState({
                                                            activeMetrics:
                                                                updatedMetrics
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}

                                    {topOfCard}
                                    {/* This returns all Xtds side by side with metric numbers. Note that the Daily number
                                    is displayed differently and that's done above. */}
                                    <Card.Description>
                                        <div className="content-box xtd">
                                            {xtdsToShow.map((xtd) => {
                                                if (xtd !== Xtd.DAILY) {
                                                    return (
                                                        <div key={xtd}>
                                                            {displayXTD(xtd)}
                                                        </div>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </div>
                                        <div className="content-box">
                                            {this.state.dataStatusCards !==
                                            DataStatus.Loading ? (
                                                xtdsToShow.map((xtd) => {
                                                    if (xtd !== Xtd.DAILY) {
                                                        return this.renderMetricNumber(
                                                            activeCardMetrics,
                                                            xtd
                                                        );
                                                    }

                                                    return null;
                                                })
                                            ) : (
                                                <Placeholder
                                                    style={placeHolderStyle}
                                                >
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                    <Placeholder.Line />
                                                </Placeholder>
                                            )}
                                        </div>
                                    </Card.Description>
                                </Card.Content>

                                {/* Display the "houses".
                            These are counts of locations violating and within daily thresholds as well as not reporting locations.
                            We display houses for the first metric on a card */}
                                {this.props.isEdit ? null : (
                                    <Card.Content extra>
                                        <div>
                                            {this.renderHouses(
                                                activeCardMetrics[0],
                                                metricDetailsUrlModel,
                                                hasDrilldown,
                                                hasAccessToFullVersionPage,
                                                this.props
                                                    .handleFreemiumMarketingModal
                                            )}
                                        </div>
                                    </Card.Content>
                                )}
                            </Card>
                        );
                    })
                }
            </Card.Group>
        );
    }
    render() {
        const {
            metricDefinitions,
            dataStatusMetricDefinitions,
            dataMetricDefinitionsErrorMessage,
            dataStatusCards,
            dataCardsErrorMessage
        } = this.state;

        if (
            metricDefinitions &&
            metricDefinitions.length > 0 &&
            dataStatusMetricDefinitions !== DataStatus.Loading &&
            dataStatusCards !== DataStatus.Failed
        ) {
            return <>{this.renderCards()}</>;
        } else if (
            dataStatusMetricDefinitions === DataStatus.Forbidden ||
            dataStatusCards === DataStatus.Forbidden
        ) {
            return <NoAccessMessage text={ErrorMessageContent.NoPageAccess} />;
        } else if (
            dataStatusMetricDefinitions === DataStatus.Failed ||
            dataStatusCards === DataStatus.Failed
        ) {
            return (
                <ErrorMessage
                    text={dataMetricDefinitionsErrorMessage || dataCardsErrorMessage}
                />
            );
        } else {
            return <Card.Group>{renderPlacholders()}</Card.Group>;
        }
    }
}

/**
 * Helper function to render Cards placeholders. The number of placeholders is hard coded to 5.
 */
function renderPlacholders() {
    const cards: any[] = [];
    let i: number;
    for (i = 0; i <= 4; i++) {
        cards.push(
            <Card key={i}>
                <Card.Content>
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder.Line length="short" />
                        </Placeholder.Header>
                        <Placeholder.Header>
                            <Placeholder.Line length="medium" />
                        </Placeholder.Header>
                        <Placeholder.Paragraph></Placeholder.Paragraph>
                        <Placeholder.Paragraph></Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                            <Placeholder.Line length="short" />
                            <Placeholder.Line length="medium" />
                            <Placeholder.Line length="long" />
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Card.Content>
            </Card>
        );
    }
    return cards;
}

/**
 * Helper function to format values on cards. We use SUIR Statistic component for the formatting.
 * @param valueFormatted
 * @param xtd
 * @param dataViolatingThreshold
 */
function formatCardValue(
    valueFormatted: string | null,
    xtd: Xtd,
    dataViolatingThreshold?: boolean
) {
    const metricDisplayColor =
        dataViolatingThreshold !== undefined &&
        dataViolatingThreshold !== null &&
        dataViolatingThreshold
            ? "red"
            : "black";
    const metricDisplayFont =
        dataViolatingThreshold !== undefined &&
        dataViolatingThreshold !== null &&
        dataViolatingThreshold
            ? "font-bold"
            : "font-normal";

    const margin = xtd === Xtd.DAILY ? "statistic-margin-daily" : "statistic-margin";

    return (
        <Statistic
            className={margin + " " + metricDisplayFont}
            color={metricDisplayColor}
            size={xtd === Xtd.DAILY ? "tiny" : "mini"}
            value={valueFormatted}
        />
    );
}

function displayXTD(xtd: Xtd) {
    return (
        <Statistic
            className="statistic-margin"
            color="black"
            size="mini"
            value={getXtdDisplayText(xtd)}
        />
    );
}

/**
 * Returns an updated array containing metrics which are displayed on cards. This is used for toggling functionality.
 * @param metricDefinitions
 * @param activeMetrics
 * @param sortOrderOfCardToToggle
 */
function updateActiveMetricsOnCards(
    activeMetrics: IModuleMetricDefinition[][],
    sortOrderOfCardToToggle: number
) {
    // Find metrics for the given sort order.
    const metrics = find(activeMetrics, (m: IModuleMetricDefinition[]) => {
        return m[0].sortOrder === sortOrderOfCardToToggle;
    });

    // Flip metrics active --> inactive and vice versa. Note that this code only supports toggling between two sets of metrics.
    metrics &&
        metrics.forEach((m) => {
            m.active = !m.active;
        });

    return activeMetrics;
}

const mapStateToProps = (state: AppState) => {
    return {
        isFreemium: state.application.userInfo.isFreemium,
        clientCode: state.application.userInfo.clientCode,
        doesUserHaveAccessToDetectForSelectedConcept:
            state.application.doesUserHaveAccessToDetectForSelectedConcept,
        doesUserHaveAccessToDeliveryPageForSelectedConcept:
            state.application.doesUserHaveAccessToDeliveryPageForSelectedConcept,
        metricConfigurationSaveButtonClicked:
            state.application.metricConfigurationParams.saveButtonClicked,
        clientConceptPages: state.application.clientConceptPages
    };
};

const mapDispatch = {
    ...actionCreators,
    ...applicationActionCreators
};

const connector = connect(mapStateToProps, mapDispatch);
type IReduxProps = ConnectedProps<typeof connector>;
export default connector(Cards);

import {combineReducers} from "redux";
import * as application from "../../application/state/actions";
import * as metricDetails from "./actions";
import {differenceWith} from "lodash";
import {IBusinessUnit} from "../../application/api/applicationModels";

export type MetricDetailsState = Readonly<{
    selectedBusinessUnitsForCompare: IBusinessUnit[];
    isSurveyTabActive: boolean;
    selectedBusinessUnitsInMetricDetailTable: IBusinessUnit[];
}>;

// each item in this combineReducers corresponds to a subset of MetricDetailsState
// all the reducers get combined into a mega-reducer found in src/state/AppReducer
type Actions = metricDetails.MetricDetailsAction | application.ApplicationAction;

export default combineReducers<MetricDetailsState, Actions>({
    selectedBusinessUnitsForCompare: (state = [], action) => {
        switch (action.type) {
            case metricDetails.BUSINESSUNIT_SELECTION_UPDATE:
                return action.selectedBusinessUnits;
            case metricDetails.BUSINESSUNIT_UNSELECT_ALL:
                return [];
            // on business unit change we need to remove selected business units, otherwise the chart is inconsistent with the table below
            case application.SWITCH_CURRENT_SELECTED_BUSINESS_UNIT:
                return [];
            default:
                return state;
        }
    },

    isSurveyTabActive: (state = false, action) => {
        if (action.type === metricDetails.SURVEY_TAB_ACTIVATE) {
            return action.isActive;
        } else {
            return state;
        }
    },

    selectedBusinessUnitsInMetricDetailTable: (state = [], action) => {
        switch (action.type) {
            case metricDetails.METRICDETAIL_TABLE_BUSINESSUNIT_UNSELECT_ALL:
                return [];
            case metricDetails.METRICDETAIL_TABLE_BUSINESSUNITS_UNSELECT:
                return differenceWith(state, action.businessUnits, (buA, buB) => {
                    return buA.businessUnitId === buB.businessUnitId;
                });
            case metricDetails.METRICDETAIL_TABLE_BUSINESSUNITS_SELECT:
                return [...state, ...action.businessUnits];
            case application.SWITCH_CURRENT_SELECTED_BUSINESS_UNIT:
                return [];
            default:
                return state;
        }
    }
});

/*export const pageCodeOptions = {
    Coach: [
        {key: "Dashboard", text: "Dashboard", value: "Dashboard"},
        {
            key: "MetricDetail_ChannelSales",
            text: "MetricDetail_ChannelSales",
            value: "MetricDetail_ChannelSales"
        },
        {
            key: "MetricDetail_Delivery",
            text: "MetricDetail_Delivery",
            value: "MetricDetail_Delivery"
        },
        {
            key: "MetricDetail_FoodCost",
            text: "MetricDetail_FoodCost",
            value: "MetricDetail_FoodCost"
        },
        {
            key: "MetricDetail_Labor",
            text: "MetricDetail_Labor",
            value: "MetricDetail_Labor"
        },
        {
            key: "MetricDetail_Loss",
            text: "MetricDetail_Loss",
            value: "MetricDetail_Loss"
        },
        {
            key: "MetricDetail_NetSales",
            text: "MetricDetail_NetSales",
            value: "MetricDetail_NetSales"
        },
        {
            key: "MetricDetail_SOS",
            text: "MetricDetail_SOS",
            value: "MetricDetail_SOS"
        },
        {
            key: "MetricDetail_Tracking",
            text: "MetricDetail_Tracking",
            value: "MetricDetail_Tracking"
        },
        {
            key: "MetricDetail_VOC",
            text: "MetricDetail_VOC",
            value: "MetricDetail_VOC"
        },
        {
            key: "MetricDetail_FlashPage",
            text: "MetricDetail_FlashPage",
            value: "MetricDetail_FlashPage"
        },
        {
            key: "EmployeeRetention",
            text: "EmployeeRetention",
            value: "EmployeeRetention"
        }
    ],
    Detect: [
        {
            key: "MetricDetail_EmployeeWages",
            text: "MetricDetail_EmployeeWages",
            value: "MetricDetail_EmployeeWages"
        },
        {key: "MetricDetails", text: "MetricDetails", value: "MetricDetails"},
        {key: "Monitor", text: "Monitor", value: "Monitor"}
    ]
};*/

export const moduleTypeOptionsForPageCode = {
    MetricDetails: [
        {
            key: "TransactionDetails",
            text: "TransactionDetails",
            value: "TransactionDetails"
        }
    ],
    Monitor: [
        {
            key: "TransactionDetails",
            text: "TransactionDetails",
            value: "TransactionDetails"
        },
        {
            key: "LocationDetails",
            text: "LocationDetails",
            value: "LocationDetails"
        }
    ],
    FoodCost: [
        {
            key: "InventoryDetails",
            text: "InventoryDetails",
            value: "InventoryDetails"
        },
        {key: "LineChart", text: "LineChart", value: "LineChart"},
        {
            key: "MetricDetailGrid",
            text: "MetricDetailGrid",
            value: "MetricDetailGrid"
        }
    ],
    Dashboard: [
        {
            key: "MetricStanding",
            text: "MetricStanding",
            value: "MetricStanding"
        },
        {key: "MetricTile", text: "MetricTile", value: "MetricTile"}
    ],
    MetricDetail_Pages: [
        {key: "ColumnChart", text: "ColumnChart", value: "ColumnChart"},
        {key: "LineChart", text: "LineChart", value: "LineChart"},
        {
            key: "MetricDetailGrid",
            text: "MetricDetailGrid",
            value: "MetricDetailGrid"
        }
    ],
    EmployeeRetention: [
        {
            key: "MetricDetailGrid",
            text: "MetricDetailGrid",
            value: "MetricDetailGrid"
        }
    ]
};

export const drilldownPageCodeOptions = [
    {
        key: "MetricDetail_ChannelSales",
        text: "MetricDetail_ChannelSales",
        value: "MetricDetail_ChannelSales"
    },
    {
        key: "MetricDetail_Delivery",
        text: "MetricDetail_Delivery",
        value: "MetricDetail_Delivery"
    },
    {
        key: "MetricDetail_FoodCost",
        text: "MetricDetail_FoodCost",
        value: "MetricDetail_FoodCost"
    },
    {
        key: "MetricDetail_Labor",
        text: "MetricDetail_Labor",
        value: "MetricDetail_Labor"
    },
    {
        key: "MetricDetail_Loss",
        text: "MetricDetail_Loss",
        value: "MetricDetail_Loss"
    },
    {
        key: "MetricDetail_NetSales",
        text: "MetricDetail_NetSales",
        value: "MetricDetail_NetSales"
    },
    {
        key: "MetricDetail_SOS",
        text: "MetricDetail_SOS",
        value: "MetricDetail_SOS"
    },
    {
        key: "MetricDetail_Tracking",
        text: "MetricDetail_Tracking",
        value: "MetricDetail_Tracking"
    },
    {
        key: "MetricDetail_Recovery",
        text: "MetricDetail_Recovery",
        value: "MetricDetail_Recovery"
    },
    {
        key: "MetricDetail_VOC",
        text: "MetricDetail_VOC",
        value: "MetricDetail_VOC"
    },
    {
        key: "MetricDetail_FlashPage",
        text: "MetricDetail_FlashPage",
        value: "MetricDetail_FlashPage"
    },
    {
        key: "MetricDetail_EmployeeWages",
        text: "MetricDetail_EmployeeWages",
        value: "MetricDetail_EmployeeWages"
    }
];

export const drilldownTypeOptions = [
    {
        key: "InventoryDetailsDrilldown",
        text: "InventoryDetailsDrilldown",
        value: "InventoryDetailsDrilldown"
    },
    {
        key: "TransactionDrilldown",
        text: "TransactionDrilldown",
        value: "TransactionDrilldown"
    },
    {
        key: "VOCDrilldown",
        text: "VOCDrilldown",
        value: "VOCDrilldown"
    }
];

export const dayPartTypeOptions = [
    {
        key: "Custom",
        text: "Custom",
        value: "Custom"
    },
    {
        key: "Hourly",
        text: "Hourly",
        value: "Hourly"
    }
];

export const trueFalseOptions = [
    {
        key: "True",
        text: "True",
        value: true
    },
    {
        key: "False",
        text: "False",
        value: false
    }
];

export const toggleOrderOptions = [
    {
        key: "1",
        text: "1",
        value: 1
    },
    {
        key: "2",
        text: "2",
        value: 2
    }
];

export const moduleTypeSortOrderOptions = [
    {
        key: "1",
        text: "1",
        value: 1
    },
    {
        key: "2",
        text: "2",
        value: 2
    }
];

export const metricOrderOptions = [
    {
        key: "0",
        text: "0",
        value: 0
    },
    {
        key: "1",
        text: "1",
        value: 1
    },
    {
        key: "2",
        text: "2",
        value: 2
    }
];

import * as React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter, Link} from "react-router-dom";
import {Divider, Grid, Header, Button, Icon, Segment} from "semantic-ui-react";
import DatePicker from "../../components/DatePicker";
import {
    LoadingMessage,
    ErrorMessage as Message
} from "../../components/SystemMessages";
import {AppState} from "../../state";
import {DashboardUrlModel, RouteType} from "../../util/appParamsUtil";
import {getCurrentSelectedBusinessUnitId} from "../../util/stateUtil";
import {
    IClientConcept,
    IBusinessUnit,
    IClientConceptPage
} from "../application/api/applicationModels";
import ClientTreeHierarchyContainer from "../application/components/ClientTreeHierarchyContainer";
import {actionCreators} from "../application/state/actions";
import {BusinessUnitAggregation} from "../metricDetails/api/metricDetailsModels";
import {Xtd} from "./api/dashboardModels";
import Cards from "./components/Cards/Cards";
import MetricTableContainer from "./components/MetricTableContainer";
import {find} from "lodash";
import {ErrorMessageContent} from "../../util/errorUtil";
import {IAppRouteProps} from "util/appParamsUtil";
import DrillDownPageSelector from "features/application/components/DrillDownPageSelector";
import {PageCode} from "features/configuration/api/uiConfigurationModels";
import MetricConfigurationModal from "features/application/components/metricConfiguration/MetricConfigurationModal";
import {dateToString} from "util/dateUtil";

interface IReduxProps {
    clientTreeHierarchy: IBusinessUnit[];
    clientConcept: IClientConcept;
    businessUnitId: string;
    rootGroupId: string;
    date: Date;
    fromDate: Date | null;
    xtd: Xtd;
    businessUnitAggregation: BusinessUnitAggregation;
    calendarId: number;
    screenWidth: number;
    isAdmin: boolean;
    isDelagetUser: boolean;
    clientConceptPages: IClientConceptPage[];
}

type IDashboardProps = IReduxProps &
    RouteComponentProps<IAppRouteProps> &
    typeof actionCreators;

class Dashboard extends React.Component<IDashboardProps> {
    public renderDashboard() {
        // params come from the redux store
        const businessUnit = find(this.props.clientTreeHierarchy, (bu) => {
            return bu.businessUnitId === this.props.businessUnitId;
        }) as IBusinessUnit;
        let page_PK = Number(this.props.match.params.page_PK);

        if (this.props.match.params.page_PK === undefined) {
            const page = find(
                this.props.clientConceptPages,
                (p) =>
                    p.pageCode === PageCode.Dashboard &&
                    p.clientConcept_PK === this.props.clientConcept.clientConcept_PK
            );

            if (page) {
                page_PK = page.page_PK;
            }
        }

        if (this.props.date !== null && businessUnit) {
            return (
                <React.Fragment>
                    <Cards
                        clientConcept={this.props.clientConcept}
                        businessUnit={businessUnit}
                        date={this.props.date}
                        xtd={this.props.xtd}
                        businessUnitAggregation={this.props.businessUnitAggregation}
                        calendarId={this.props.calendarId}
                        configuredMetricDefinitons={[]}
                        page_PK={page_PK}
                    />
                    <Divider hidden={true} />
                    <Segment style={{overflow: "auto"}} className={"body-segment"}>
                        <Header size="large">Metric Standings</Header>
                        <Divider hidden={true} />
                        <MetricTableContainer
                            clientConcept_PK={
                                this.props.clientConcept.clientConcept_PK
                            }
                            rootGroupId={this.props.rootGroupId}
                            date={this.props.date}
                            calendarId={this.props.calendarId}
                        />
                    </Segment>
                </React.Fragment>
            );
        } else if (
            (this.props.businessUnitId && !businessUnit) ||
            !this.props.date
        ) {
            return <Message text={ErrorMessageContent.SomethingWentWrong} />;
        } else {
            return <LoadingMessage />;
        }
    }
    public render() {
        // Dashboard page is special and doesn't require for the parameters to be in the ULR
        // Find the page_PK if that's the case
        let page_PK = Number(this.props.match.params.page_PK);

        if (this.props.match.params.page_PK === undefined) {
            const page = find(
                this.props.clientConceptPages,
                (p) =>
                    p.pageCode === PageCode.Dashboard &&
                    p.clientConcept_PK === this.props.clientConcept.clientConcept_PK
            );

            if (page) {
                page_PK = page.page_PK;
            }
        }

        const dashboardUrlModel = new DashboardUrlModel(
            this.props.clientConcept,
            this.props.businessUnitId,
            this.props.date
        );

        const configurationPageLink = `/dashboard/PageConfiguration/${page_PK}/${dateToString(
            this.props.date
        )}${
            this.props.fromDate
                ? "?fromDate=" + dateToString(this.props.fromDate)
                : ""
        }`;

        return (
            <Grid className={"layout-grid"} stackable>
                <Grid.Row>
                    <Grid.Column stretched>
                        <Segment basic padded="very">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <div>
                                            <div style={{float: "left"}}>
                                                <ClientTreeHierarchyContainer
                                                    urlModel={dashboardUrlModel}
                                                    routeType={RouteType.DASHBOARD}
                                                />
                                            </div>
                                            <div className="datePickerContainer">
                                                <div style={{width: "200px"}}>
                                                    {" "}
                                                    <DatePicker
                                                        urlModel={dashboardUrlModel}
                                                    />
                                                </div>
                                            </div>
                                            {this.props.isAdmin && (
                                                <Button
                                                    as={Link}
                                                    to={configurationPageLink}
                                                    style={{
                                                        float: "right",
                                                        height: "41px",
                                                        marginLeft: "5px",
                                                        lineHeight: "21px",
                                                        verticalAlign: "top" //needed to bring this inline with the Configuration button next to it
                                                    }}
                                                    className="btn btn-blue-solid"
                                                    primary
                                                    type="button" //type needs to be button otherwise it defaults to submit behavior
                                                >
                                                    <Icon name="cog" />
                                                    Edit Metrics
                                                </Button>
                                            )}
                                            <div className="metricConfigurationArea">
                                                {this.props.isAdmin &&
                                                    this.props.isDelagetUser && (
                                                        <MetricConfigurationModal
                                                            page_PK={page_PK}
                                                            style={{
                                                                height: "41px",
                                                                margin: "0px"
                                                            }}
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column
                                        textAlign="left"
                                        verticalAlign="bottom"
                                    >
                                        <DrillDownPageSelector
                                            page_PK={page_PK}
                                            clientConcept={this.props.clientConcept}
                                            businessUnitId={
                                                this.props.businessUnitId
                                            }
                                            date={this.props.date}
                                            fromDate={this.props.fromDate}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <div style={{clear: "both"}} />

                            <Divider hidden={true} />
                            {this.renderDashboard()}
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    clientTreeHierarchy: state.application.clientTreeHierarchy,
    clientConcept: state.application.selectedClientConcept,
    businessUnitId: getCurrentSelectedBusinessUnitId(state),
    rootGroupId: state.application.selectedClientConcept.rootGroupId,
    date: state.application.date,
    fromDate: state.application.fromDate,
    xtd: state.application.xtd,
    businessUnitAggregation: state.application.businessUnitAggregation,
    calendarId: state.application.selectedClientConcept.calendarId,
    screenWidth: state.application.screenWidth,
    isAdmin: state.application.userInfo.isAdmin,
    isDelagetUser: state.application.userInfo.isDelagetUser,
    clientConceptPages: state.application.clientConceptPages
});

export default withRouter(
    connect(mapStateToProps, actionCreators, (state, actions, props) => ({
        ...state,
        ...actions,
        ...props
    }))(Dashboard)
);

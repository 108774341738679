import {FC} from "react";
import {Icon, Popup, SemanticICONS} from "semantic-ui-react";

interface IDataTableMetricValueDisplayElement {
    isMobileScreen: boolean;
    metricName: string;
    metricDisplayValue1: JSX.Element | string | number | boolean | null | undefined;
    metricDisplayValue2?: JSX.Element | string | number | boolean | null | undefined;
    metricValueStyle?: string;
    onClickFunction?: () => void;
    iconName?: SemanticICONS;
    hasHoverOver?: boolean;
}

/**
 * This component helps us render metric table values. On mobile screen, we display the values in stackable tables.
 * We also add the metric name in front of each value to make the table more readable.
 *
 * On larger screens, we display a regular table.
 */
const DataTableMetricValueDisplayElement: FC<
    IDataTableMetricValueDisplayElement
> = ({
    isMobileScreen,
    metricName, // Metric name is displayed in the column on mobile screen
    metricDisplayValue1,
    metricDisplayValue2,
    metricValueStyle, // Optional additional css styling
    onClickFunction, // Optional onClick functionality for the value
    iconName,
    hasHoverOver // Optional hover over functionality. Adds elipsis for long text and add a popup with the column value
}) => {
    let metricDisplayValue = metricDisplayValue1;
    // Some columns display an additional value in the column.
    // In that case display them on separate lines
    if (metricDisplayValue2) {
        metricDisplayValue = (
            <>
                {metricDisplayValue1}
                <br />
                {metricDisplayValue2}
            </>
        );
    }

    let metricValueComponent: JSX.Element | null = null;

    if (isMobileScreen) {
        metricValueComponent = (
            <div>
                <p
                    className={"mobile-screen-value-metric-name"}
                >{`${metricName}: `}</p>
                <p
                    className={`mobile-screen-value-metric-value ${
                        metricValueStyle ? metricValueStyle : ""
                    }`}
                    onClick={onClickFunction}
                >
                    {iconName && (
                        <Icon size="small" name={iconName as SemanticICONS}></Icon>
                    )}
                    {metricDisplayValue}
                </p>
            </div>
        );
    } else {
        metricValueComponent = (
            <div className={metricValueStyle} onClick={onClickFunction}>
                {iconName && (
                    <Icon size="small" name={iconName as SemanticICONS}></Icon>
                )}
                {metricDisplayValue}
            </div>
        );
    }

    if (hasHoverOver) {
        return (
            <Popup
                trigger={metricValueComponent}
                content={metricDisplayValue}
                position="top center"
            />
        );
    } else {
        return metricValueComponent;
    }
};

export default DataTableMetricValueDisplayElement;

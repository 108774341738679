import {floor, find, padEnd, round} from "lodash";
import moment from "moment";
import {IModuleMetricDefinition} from "../features/dashboard/api/dashboardModels";
import {IColumnMetadata} from "../features/application/api/applicationModels";
import {
    dateToString,
    dateWithTimeToString,
    getDateFromString,
    timeToString
} from "./dateUtil";
import store from "state/store";
import {BusinessUnitAggregation} from "features/metricDetails/api/metricDetailsModels";
import {PrettyTimeSpan} from "./prettyTimeSpan/prettyTimeSpan";

export enum DataType {
    None = "None",
    Money = "Money",
    Number = "Number",
    Percent = "Percent",
    TimeSpan = "TimeSpan",
    Boolean = "Boolean",
    Date = "Date",
    DateTime = "DateTime",
    String = "String",
    TimeOfDay = "TimeOfDay", //Todo: Rename this to be less confusing. Something like MinutesFromMidnight. This is really the number of minutes from midnight and the value is a number
    Time = "Time", //Use this when the input is a timestamp and the output desired is the time component of that timestamp
    // The value returned represents a metricDefinitionId (MetricDefinition table in the Minnetonka db).
    // Then the front-end formatter function replaces it by the metric client concept alias. This was convenient
    // because our data model only support numbers as well (not strings)
    MetricDefinitionId = "MetricDefinitionId",
    PrettyTimeSpan = "PrettyTimeSpan"
}

export const nullValueDisplayString = "-";

export const itemsPerPage = 20; // hardcoded number of items per page
export const surveysPerPage = 50; // hardcoded number of surveys per page

const dataFormatUtil = {
    dataTableDisplayValue(
        value: undefined | null | number | string | boolean,
        // If the dataTable definition is not provided we set it to a default value
        dataTableDefiniton: IColumnMetadata | IModuleMetricDefinition,
        //this parameter is useful when formatting numbers outside of a datatable where "-" may not be appropriate
        useNullValueDisplayString = true
    ): string {
        if (value === null || value === undefined) {
            if (useNullValueDisplayString) {
                return nullValueDisplayString;
            } else {
                return "";
            }
        }
        const {dataType: type, decimalPlaces, unit} = dataTableDefiniton;
        let displayValue = "";
        switch (type) {
            case DataType.Money:
                //some js regular expression magic at the end to put in the commas: https://stackoverflow.com/a/14428340
                displayValue = this.formatMoney(value, decimalPlaces, unit);

                break;
            case DataType.Number:
                //more js regular expression magic at the end to put in the commas: https://stackoverflow.com/a/2901298
                displayValue = round(Number(value), decimalPlaces)
                    .toFixed(decimalPlaces)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                break;
            case DataType.Percent:
                displayValue =
                    round(Number(value) * 100, decimalPlaces).toFixed(
                        decimalPlaces
                    ) + "%";
                break;
            case DataType.TimeSpan:
                displayValue = this.formatToSOSDisplayType(Number(value));
                break;
            case DataType.PrettyTimeSpan:
                displayValue = this.formatPrettyTimeSpan(Number(value));
                break;
            case DataType.TimeOfDay:
                displayValue = this.formatToTimeOfDayDisplayType(Number(value));
                break;
            case DataType.Time:
                displayValue = timeToString(getDateFromString(value.toString()));
                break;
            case DataType.Boolean:
                displayValue = value ? "True" : "False";
                break;
            case DataType.Date:
                displayValue = dateToString(getDateFromString(value.toString()));
                break;
            case DataType.DateTime: {
                const dateValue: Date = getDateFromString(value.toString());
                displayValue = dateWithTimeToString(dateValue);
                break;
            }
            case DataType.String:
                displayValue = value.toString().toUpperCase();
                break;
            case DataType.None:
                displayValue = value.toString();
                break;
            case DataType.MetricDefinitionId: {
                // The metric values comes in as MetricDefinitionId (number). Replace it with client concept metric definition's alias
                // which is in the redux store
                const appState = store.getState().application;
                const selectedClientConcept = appState.selectedClientConcept;
                const metricDefinition = find(
                    appState.clientConceptMetricDefinitions,
                    (m) =>
                        m.clientConcept_PK ===
                            selectedClientConcept.clientConcept_PK &&
                        m.metricDefinitionId === value
                );

                displayValue = metricDefinition ? metricDefinition.alias : "";
                break;
            }
            default:
                displayValue = round(Number(value), 2).toFixed(2).toString();
                break;
        }
        return displayValue;
    },

    formatToSOSDisplayType(value: number): string {
        let displayValue = "";
        const totalSeconds = round(value, 0);

        if (totalSeconds < 60) {
            displayValue = "00:" + timeDisplayInTwoDigits(totalSeconds);
        } else {
            const mins = floor(totalSeconds / 60, 0);
            const secs = totalSeconds - mins * 60;

            if (mins < 60) {
                displayValue =
                    timeDisplayInTwoDigits(mins) +
                    ":" +
                    timeDisplayInTwoDigits(secs);
            } else {
                const hours = floor(mins / 60, 0);
                displayValue =
                    timeDisplayInTwoDigits(hours) +
                    ":" +
                    timeDisplayInTwoDigits(mins - hours * 60) +
                    ":" +
                    timeDisplayInTwoDigits(secs);
            }
        }
        return displayValue;
    },

    formatPrettyTimeSpan(value: number): string {
        const prettyTimeSpan = PrettyTimeSpan.fromSeconds(Number(value));

        return prettyTimeSpan.toString();
    },

    formatToTimeOfDayDisplayType(value: number): string {
        const hours = value / 60;
        const minutes = value % 60;
        return moment.utc().hours(hours).minutes(minutes).format("hh:mm A");
    },

    /**
     * Converts a formatted display value back to a raw data based on data type. Also validates that the display value can be converted.
     * @param value
     * @param dataType
     */
    metricDataDisplayValueToRawData(
        value: string | undefined | null,
        dataType: DataType | undefined
    ): number | boolean | null | undefined {
        if (value === null || value === "") {
            return null;
        }
        if (value === undefined) {
            return undefined;
        }

        let rawValue: number | boolean | null | undefined;
        // regex to match a floating point number (with optional commas)
        const numReg = new RegExp(
            /^[+-]?(\d+|\d*\.\d*|\d{1,3}(,\d{3})*(\.\d*)?){1}$/
        );
        const split = value.split(":");
        switch (dataType) {
            // TODO: Implement missing DataTypes, i.e. Date
            case DataType.Boolean:
                rawValue = value === "True" ? true : false;
                break;
            case DataType.Money:
                // Remove '$' if it's a first character
                if (value[0] === "$") {
                    value = removeCharacter(value, 0);
                }
                // Remove all ','
                rawValue = numReg.test(value)
                    ? parseFloat(value.replace(/,/g, ""))
                    : undefined;
                break;

            case DataType.Number:
                rawValue = numReg.test(value)
                    ? parseFloat(value.replace(/,/g, ""))
                    : undefined;
                break;

            case DataType.Percent:
                // Remove '%' if it's a last character
                if (value[value.length - 1] === "%") {
                    value = removeCharacter(value, value.length - 1);
                }
                rawValue = numReg.test(value)
                    ? parseFloat(value.replace(/,/g, "")) / 100
                    : undefined;
                break;

            case DataType.TimeSpan:
                if (split.length === 2) {
                    if (!numReg.test(split[0]) || !numReg.test(split[1])) {
                        rawValue = null;
                    } else {
                        rawValue =
                            parseFloat(split[0].replace(/,/g, "")) * 60 +
                            parseFloat(split[1].replace(/,/g, ""));
                    }
                } else if (split.length === 1) {
                    if (!numReg.test(split[0])) {
                        rawValue = undefined;
                    } else {
                        rawValue = parseFloat(split[0].replace(/,/g, "")) * 60;
                    }
                } else {
                    rawValue = undefined;
                }
                break;
            case DataType.PrettyTimeSpan:
                return PrettyTimeSpan.parse(value)?.toSeconds();
                break;
            default:
                rawValue = numReg.test(value)
                    ? parseFloat(value.replace(/,/g, ""))
                    : undefined;
                break;
        }

        return rawValue;
    },

    metricDataRawDataToDisplayValue(
        value: number | boolean | null | undefined,
        dataType: DataType | undefined
    ): string | undefined {
        if (value === null || value === undefined) {
            return undefined;
        }

        let displayValue: string | undefined = undefined;

        switch (dataType) {
            case DataType.Percent:
                if (typeof value === "number") {
                    displayValue = (value * 100).toString();
                }
                break;
            case DataType.TimeSpan:
                if (typeof value === "number") {
                    const minutes = Math.floor(value / 60);
                    const seconds = value % 60;
                    displayValue = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`; // Seconds needs a leading 0 for a time span like 11:05
                }
                break;
            case DataType.PrettyTimeSpan:
                if (typeof value === "number") {
                    displayValue = PrettyTimeSpan.fromSeconds(value).toString();
                }
                break;
            case DataType.Time:
            case DataType.TimeOfDay:
                if (typeof value === "number") {
                    const hours = Math.floor(value / 60);
                    const minutes = value % 60;

                    const period = hours >= 12 ? "PM" : "AM";
                    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

                    displayValue = `${timeDisplayInTwoDigits(
                        formattedHours
                    )}:${timeDisplayInTwoDigits(minutes)} ${period}`;
                }
                break;
            default:
                displayValue = value.toString();
                break;
        }
        return displayValue;
    },

    metricDataFormatForExcelExport(columnMetadata: IColumnMetadata): string {
        const {dataType, decimalPlaces} = columnMetadata;
        let format = "";
        switch (dataType) {
            case DataType.Number:
                if (!decimalPlaces) {
                    format = "0";
                } else {
                    format = padEnd("0.", decimalPlaces + 2, "0");
                }
                break;
            case DataType.Money:
                if (!decimalPlaces) {
                    format = "0";
                } else {
                    format = padEnd("$0.", decimalPlaces + 3, "0");
                }
                break;

            case DataType.TimeSpan:
                format = "mm:ss";
                break;
            case DataType.PrettyTimeSpan:
                format = "d\\d h\\h m\\m s\\s";
                break;
            case DataType.TimeOfDay:
                format = "h:mm AM/PM";
                break;

            case DataType.Percent:
                if (!decimalPlaces) {
                    format = "0%";
                } else {
                    format = padEnd("0.", decimalPlaces + 2, "0");
                    format += "%";
                }
                break;

            // These DataTypes should be left with default Excel formatting (will be displayed as text)
            case DataType.Boolean:
            case DataType.String:
            case DataType.MetricDefinitionId:
                format = "General"; // Default Excel formatting
                break;

            case DataType.Date:
                format = "Date";
                break;

            case DataType.DateTime:
                format = "m/d/yyyy h:mm:ss AM/PM";
                break;

            // Default formatting will be a number with two decimal places
            case DataType.None:
            default:
                format = "0.00";
                break;
        }

        return format;
    },

    truncateWithEllipses(text: string | null, max: number): string {
        if (text === null) {
            return "";
        }
        if (text.length <= max) {
            return text;
        }
        return text.substr(0, max - 1) + "\u2026";
    },

    formatMoney(
        value: string | number | boolean,
        decimalPlaces: number | undefined,
        unit?: string | undefined
    ): string {
        return (
            (unit || "$") +
            round(Number(value), decimalPlaces)
                .toFixed(decimalPlaces)
                .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        );
    },

    formatBusinessUnitAggregationDisplayText(
        businessUnitAggregation: BusinessUnitAggregation
    ): string {
        //Need to lowercase because back-end serializes as, for example, "Locations" vs. "locations"
        switch (businessUnitAggregation.toLowerCase()) {
            case BusinessUnitAggregation.Locations.toLowerCase():
                return "Restaurants";
            case BusinessUnitAggregation.Areas.toLowerCase():
                return "Areas";
            case BusinessUnitAggregation.NonAreaGroups.toLowerCase():
                return "Groups";
            default:
                return "";
        }
    },

    timeISOStringToTimeOfDayNumber(value: string | undefined): number | undefined {
        if (value === undefined) {
            return undefined;
        }

        const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;

        if (isoDatePattern.test(value)) {
            const timeDisplayValue = dataFormatUtil.dataTableDisplayValue(value, {
                dataType: DataType.Time,
                key: "",
                label: ""
            });

            const minutesAfterMidnight =
                dataFormatUtil.metricDataDisplayValueToRawData(
                    timeDisplayValue,
                    DataType.Time
                );
            return minutesAfterMidnight as number;
        }

        return undefined;
    },

    getSecondsFromMinutes(minutes: number) {
        return minutes * 60;
    },

    getSecondsFromHours(hours: number) {
        const minutes = hours * 60;

        return this.getSecondsFromMinutes(minutes);
    },

    getSecondsFromDays(days: number) {
        const hours = days * 24;

        return this.getSecondsFromHours(hours);
    }
};

// Helper function to remove a character from a string
function removeCharacter(str: string, index: number): string {
    const part1 = str.substring(0, index);
    const part2 = str.substring(index + 1, str.length);

    return part1 + part2;
}

/* time between 0 -59 */
function timeDisplayInTwoDigits(value: number): string {
    return value <= 9 ? "0" + value : value.toString();
}

export default dataFormatUtil;

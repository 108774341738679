import {FC} from "react";
import {Icon, Table} from "semantic-ui-react";
import {IClientConceptMetricDefinition} from "../../api/applicationModels";
import {Control} from "react-hook-form";
import SemanticHookFormInputField from "features/monitors/components/forms/fields/SemanticHookFormInput";
import SemanticHookFormDropdown from "features/monitors/components/forms/fields/SemanticHookFormDropdown";

interface IProps {
    clientConceptMetricDefinition: IClientConceptMetricDefinition;
    index: number;
    control: Control<any, any>;
    setValue: any;
    isSubmitting: boolean;
}

const MetricAliasFormRow: FC<IProps> = ({
    clientConceptMetricDefinition,
    index,
    control,
    setValue,
    isSubmitting
}) => {
    const dataTypeOptions = [
        {key: "TimeOfDay", text: "TimeOfDay", value: "TimeOfDay"},
        {key: "Money", text: "Money", value: "Money"},
        {
            key: "MetricDefinitionId",
            text: "MetricDefinitionId",
            value: "MetricDefinitionId"
        },
        {key: "TimeSpan", text: "TimeSpan", value: "TimeSpan"},
        {key: "String", text: "String", value: "String"},
        {key: "Number", text: "Number", value: "Number"},
        {key: "Percent", text: "Percent", value: "Percent"},
        {key: "Boolean", text: "Boolean", value: "Boolean"}
    ];
    return (
        <Table.Row>
            <Table.Cell>{clientConceptMetricDefinition.metricCategory}</Table.Cell>
            <Table.Cell>
                {clientConceptMetricDefinition.metricDefinitionId}
            </Table.Cell>
            <Table.Cell>{clientConceptMetricDefinition.metricCode}</Table.Cell>
            <Table.Cell
                key={
                    "metricAlias" + clientConceptMetricDefinition.metricDefinitionId
                }
                className="metricAlias"
                style={{
                    minWidth: "250px"
                }}
            >
                <SemanticHookFormInputField
                    control={control}
                    setValue={setValue}
                    name={`clientConceptMetricDefinitions.${index}.alias`}
                    type="text"
                    placeholder="Enter an Alias"
                    errorLabelPointingDirection="left"
                    disabled={isSubmitting}
                />
            </Table.Cell>
            <Table.Cell
                key={"dataType" + clientConceptMetricDefinition.metricDefinitionId}
                className="metricDecimalPlaces"
            >
                <SemanticHookFormDropdown
                    control={control}
                    name={`clientConceptMetricDefinitions.${index}.dataType`}
                    type="text"
                    options={dataTypeOptions}
                    errorLabelPointingDirection="left"
                    disabled={isSubmitting}
                    setValue={setValue}
                />
            </Table.Cell>
            <Table.Cell
                key={
                    "decimalPlaces" +
                    clientConceptMetricDefinition.metricDefinitionId
                }
                className="metricDecimalPlaces"
            >
                <SemanticHookFormInputField
                    control={control}
                    setValue={setValue}
                    name={`clientConceptMetricDefinitions.${index}.decimalPlaces`}
                    type="number"
                    errorLabelPointingDirection="left"
                    disabled={isSubmitting}
                />
            </Table.Cell>
            <Table.Cell>{clientConceptMetricDefinition.description}</Table.Cell>
            <Table.Cell>
                {clientConceptMetricDefinition.isMetricUsed ? (
                    <Icon name="check circle" color="green" size="large" />
                ) : (
                    ""
                )}
            </Table.Cell>
        </Table.Row>
    );
};

export default MetricAliasFormRow;

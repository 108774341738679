import {get} from "lodash";
import {Component} from "react";
import applicationApi from "../features/application/api/applicationAPI";
import {
    IColumnMetadata,
    IDataTableData
} from "../features/application/api/applicationModels";
import {IBusinessUnitMetricData} from "../features/dashboard/api/dashboardModels";
import dataFormatUtil, {DataType} from "../util/dataFormatUtil";
import {IInventoryItemMetricData} from "../features/metricDetails/api/InventoryDataModels";
import store from "state/store";
import {find} from "lodash";
import {Icon} from "semantic-ui-react";
import {dateToString, dateWithTimeToString, getDateFromString} from "util/dateUtil";
import {IDeliveryOperationsMetricData} from "features/deliveryOperations/api/deliveryOperationsModels";

interface IProps {
    columns: IColumnMetadata[];
    data:
        | IBusinessUnitMetricData[]
        | IInventoryItemMetricData[]
        | IDataTableData[]
        | IDeliveryOperationsMetricData[];
    fileName: string;
}

interface IExportData {
    columns: string[];
    data: ICell[][];
}

interface ICell {
    value: number | string | null;
    style: unknown;
}

class ExportExcel extends Component<IProps> {
    exportFile = (<></>);

    handleExportClick = (): void => {
        applicationApi.trackPageViewOrAction(
            window.location.pathname,
            "feature=ExportExcel"
        );
        //use a dynamic import on click because this import is a 1 mb file.
        import("@delaget/react-data-export").then((component) => {
            /* Additional setup to ensure that the download content is only create when Export is actually clicked and not before (like on page load) */
            this.exportFile = (
                <component.default.ExcelFile
                    key={"file" + Date.now()} //needed to make React think this is a new element and it should be rendered again.  This is needed to trigger another download after the first download
                    filename={this.props.fileName}
                    hideElement={true}
                >
                    <component.default.ExcelFile.ExcelSheet
                        name={this.props.fileName}
                        dataSet={this.constructExport()}
                    />
                </component.default.ExcelFile>
            );

            this.forceUpdate();
        });
    };

    constructExport(): IExportData[] {
        const {data, columns} = this.props;
        // This library only accepts the input data in a certain format, so we create it here.
        const exportData: IExportData[] = [];
        exportData.push({
            columns: [],
            data: []
        });

        // Add column headers
        columns.forEach((column) => {
            exportData[0].columns.push(column.label);
        });
        // Add values
        for (let i = 0; i < data.length; i++) {
            exportData[0].data.push([]);

            columns.forEach((column) => {
                let value;
                //special logic for getting "LocNbr-LocDescription" as the Restaurant column in the output
                if (column.key === "businessUnitName") {
                    const businessUnitName = get(data[i], column.key);
                    const businessUnitDescription = get(
                        data[i],
                        "businessUnitDescription"
                    );
                    if (
                        businessUnitName &&
                        businessUnitDescription &&
                        businessUnitName !== businessUnitDescription
                    ) {
                        value = businessUnitName + "-" + businessUnitDescription;
                    } else {
                        value = businessUnitName;
                    }
                }
                //Same idea but for the Ticket level grids the columns are a bit different
                else if (column.key === "locDesc") {
                    const locDesc = get(data[i], column.key);
                    const locNbr = get(data[i], "locNbr");
                    if (locDesc && locNbr && locDesc !== locNbr) {
                        value = locNbr + "-" + locDesc;
                    } else {
                        value = locDesc;
                    }
                } else {
                    value = get(data[i], column.key);
                }

                // For some reason this library cannot handle null or undefined values, so they need to be replaced with an empty strings
                let cellData: ICell = {
                    value: value === null || value === undefined ? "" : value,
                    style: {}
                };

                //Metric of type time should be in seconds. In order for
                //this to work in excel, we need to divide the metric number
                //by number of seconds in a day (24*60*60=84600)
                if (
                    (column.dataType === DataType.TimeSpan ||
                        column.dataType === DataType.PrettyTimeSpan) &&
                    value
                ) {
                    value = value / 86400;
                }

                // When the DataType is TimeOfDay then the metric comes in in minutes
                // In order for this to work in excel, we need to divide the metric number
                // by number of seconds in a day (24*60=1440)
                if (column.dataType === DataType.TimeOfDay && value) {
                    value = value / 1440;
                }

                if (column.dataType === DataType.Date && value) {
                    value = dateToString(getDateFromString(value.toString()));
                }

                if (column.dataType === DataType.DateTime && value) {
                    const dateValue = getDateFromString(value.toString());
                    value = dateWithTimeToString(dateValue);
                }

                if (column.dataType === DataType.MetricDefinitionId && value) {
                    // The metric values comes in as MetricDefinitionId (number). Replace it with client concept metric definition's alias
                    // which is in the redux store
                    const appState = store.getState().application;
                    const selectedClientConcept = appState.selectedClientConcept;
                    const metricDefinition = find(
                        appState.clientConceptMetricDefinitions,
                        (m) =>
                            m.clientConcept_PK ===
                                selectedClientConcept.clientConcept_PK &&
                            m.metricDefinitionId === value
                    );

                    value = metricDefinition ? metricDefinition.alias : "";
                }

                cellData = {
                    value: value === null || value === undefined ? "" : value,
                    style: {
                        numFmt: dataFormatUtil.metricDataFormatForExcelExport(column)
                    }
                };
                exportData[0].data[i].push(cellData);
            });
        }
        return exportData;
    }

    render(): JSX.Element {
        return (
            <div className={"export-container"} onClick={this.handleExportClick}>
                <p className={"clickable-value"}>
                    {" "}
                    <Icon name="file outline"></Icon>Export
                </p>
                {this.exportFile}
            </div>
        );
    }
}

export default ExportExcel;

import {ErrorMessage, LoadingMessage} from "components/SystemMessages";
import uiConfigurationApi from "features/configuration/api/uiConfigurationAPI";
import {FC, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Header, Segment} from "semantic-ui-react";
import {AppState} from "../../../../state";
import {
    DataStatus,
    IClientConceptMetricDefinition,
    IProblemDetails
} from "../../api/applicationModels";
import MetricAliasForm from "./MetricAliasForm";

const MetricAliasConfiguration: FC<IReduxProps> = ({
    currentSelectedClientConcept
}) => {
    const [dataStatus, setDataStatus] = useState<DataStatus>(DataStatus.Undefined);

    const [dataStatusErrorMessage, setDataStatusErrorMessage] = useState<string>("");

    const [clientConceptMetricDefinitions, setClientConceptMetricDefinitions] =
        useState<IClientConceptMetricDefinition[]>([]);

    useEffect(() => {
        setDataStatus(DataStatus.Loading);
        uiConfigurationApi
            .getClientConceptMetricDefinitions(
                currentSelectedClientConcept.clientConcept_PK
            )
            .then((data) => {
                setClientConceptMetricDefinitions(data);
                setDataStatus(DataStatus.Loaded);
            })
            .catch((errorResponse: IProblemDetails) => {
                setDataStatus(DataStatus.Failed);
                setDataStatusErrorMessage(errorResponse.detail);
            });
    }, [currentSelectedClientConcept]);
    return (
        <>
            <Header>Metric Aliases</Header>
            {dataStatus === DataStatus.Loaded && (
                <>
                    <Segment clearing={true}>
                        <MetricAliasForm
                            clientConceptMetricDefinitions={
                                clientConceptMetricDefinitions
                            }
                            clientConcept_PK={
                                currentSelectedClientConcept.clientConcept_PK
                            }
                        />
                    </Segment>
                </>
            )}
            {dataStatus === DataStatus.Loading && <LoadingMessage />}
            {dataStatus === DataStatus.Failed && (
                <ErrorMessage text={dataStatusErrorMessage} />
            )}
        </>
    );
};

const mapStateToProps = (state: AppState) => ({
    currentSelectedClientConcept: state.application.selectedClientConcept
});

const connector = connect(mapStateToProps);

type IReduxProps = ConnectedProps<typeof connector>;
export default connector(MetricAliasConfiguration);

import {map} from "lodash";
import * as React from "react";
import {Header, Icon, Segment, Table} from "semantic-ui-react";
import dataFormatUtil from "../../../util/dataFormatUtil";
import {dateToString} from "../../../util/dateUtil";
import {
    IBusinessUnitMetricData,
    IModuleMetricDefinition,
    Xtd
} from "../api/dashboardModels";

interface IProps {
    locationPk: number;
    locationNumber: string;
    metricDefinitions: IModuleMetricDefinition[];
    dailyMetricData: IBusinessUnitMetricData[];
}

class LocationDailyMetricData extends React.Component<IProps> {
    public renderColumnHeaders(
        metricDefinitions: IModuleMetricDefinition[]
    ): JSX.Element {
        const metricHeaders = map(metricDefinitions, (metrics) => {
            return (
                <Table.HeaderCell key={metrics.metricCode}>
                    {metrics.metricName}
                </Table.HeaderCell>
            );
        });

        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Restaurant</Table.HeaderCell>
                    {metricHeaders}
                </Table.Row>
            </Table.Header>
        );
    }

    public renderColumnCells(
        metricDefinitions: IModuleMetricDefinition[],
        dailyMetricData: IBusinessUnitMetricData[]
    ): JSX.Element {
        return (
            <Table.Body>
                {dailyMetricData.map((data) => {
                    return (
                        <Table.Row key={dateToString(data.date)}>
                            <Table.Cell>{dateToString(data.date)}</Table.Cell>
                            <Table.Cell>{this.props.locationNumber}</Table.Cell>
                            {map(metricDefinitions, (metricDefiniton) => {
                                const metricCode = metricDefiniton.metricCode;
                                // using an interface here with the following mapping: metricCode -> XTD -> value
                                const metricNumber =
                                    data.metricData[metricCode][Xtd.DAILY];
                                const isMetricViolateThreshold =
                                    data.metricDataThresholdViolation &&
                                    data.metricDataThresholdViolation[metricCode]
                                        ? data.metricDataThresholdViolation[
                                              metricCode
                                          ][Xtd.DAILY]
                                        : false;
                                return (
                                    <Table.Cell
                                        key={metricDefiniton.metricCode + data.date}
                                        className={
                                            isMetricViolateThreshold
                                                ? "metric-violating-threshold"
                                                : ""
                                        }
                                    >
                                        {dataFormatUtil.dataTableDisplayValue(
                                            metricNumber,
                                            metricDefiniton
                                        )}
                                    </Table.Cell>
                                );
                            })}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        );
    }

    public render(): JSX.Element {
        const {metricDefinitions, dailyMetricData, locationNumber} = this.props;
        if (dailyMetricData.length !== 0 && metricDefinitions.length !== 0) {
            return (
                <Table basic unstackable structured textAlign="center">
                    {this.renderColumnHeaders(metricDefinitions)}
                    {this.renderColumnCells(metricDefinitions, dailyMetricData)}
                </Table>
            );
        } else {
            return (
                <Segment placeholder textAlign="center" padded="very">
                    <Header icon>
                        <Icon name="search" />
                        We could not find any daily metric data for location{" "}
                        {locationNumber}.
                    </Header>
                </Segment>
            );
        }
    }
}

export default LocationDailyMetricData;

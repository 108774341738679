import {FC, useState} from "react";
import {Button, Table, Icon, Grid} from "semantic-ui-react";
import {
    DataStatus,
    IClientConceptMetricDefinition
} from "../../api/applicationModels";
import {difference, forEach, union} from "lodash";
import uiConfigurationApi from "features/configuration/api/uiConfigurationAPI";
import {IModuleMetricDefinition} from "features/dashboard/api/dashboardModels";
import ModuleMetricFormRow from "./ModuleMetricFormRow";
import {
    ModuleType,
    PageCode
} from "features/configuration/api/uiConfigurationModels";
import {connect, ConnectedProps} from "react-redux";
import {actionCreators} from "features/application/state/actions";
import {DataType} from "util/dataFormatUtil";
import {moduleTypeOptionsForPageCode} from "./MetricConfigurationModels";
import {AppState} from "state";
import {SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
import {DrilldownType} from "features/metricDetails/api/metricDetailsModels";

//this interface exists just to provide type safety as we pass around the form values
//and map them to other objects
export interface IClientConceptPageModuleMetricForm {
    clientConceptPageModuleMetrics: IModuleMetricDefinition[];
}

interface IProps extends IReduxProps {
    clientConcept_PK: number;
    clientConceptPageModuleMetrics: IModuleMetricDefinition[];
    availableClientConceptMetrics: IClientConceptMetricDefinition[];
    selectedPage_PK: number;
}

const ModuleMetricForm: FC<IProps> = ({
    clientConcept_PK,
    clientConceptPageModuleMetrics,
    availableClientConceptMetrics,
    selectedPage_PK,
    handleMetricConfigurationSaveButton,
    clientConceptPages
}) => {
    const [saveConfigurationStatus, setSaveConfigurationStatus] =
        useState<DataStatus>(DataStatus.Undefined);

    const defaultValues = {clientConceptPageModuleMetrics};

    const saveClientConceptPageModuleMetrics = async (
        clientConcept_PK: number,
        moduleMetrics: IModuleMetricDefinition[]
    ) => {
        setSaveConfigurationStatus(DataStatus.Loading);
        await uiConfigurationApi
            .saveClientConceptPageModuleMetrics(clientConcept_PK, moduleMetrics)
            .then((clientConceptPageModuleMetrics) => {
                setSaveConfigurationStatus(DataStatus.Loaded);
                const sortedClientConceptPageModuleMetrics =
                    clientConceptPageModuleMetrics.sort(
                        (a, b) =>
                            a.pageCode.localeCompare(b.pageCode) ||
                            a.moduleType.localeCompare(b.moduleType) ||
                            a.sortOrder - b.sortOrder
                    );

                const configuration: IClientConceptPageModuleMetricForm = {
                    clientConceptPageModuleMetrics: []
                };

                configuration.clientConceptPageModuleMetrics =
                    sortedClientConceptPageModuleMetrics;
                //"Reset" the form to have the current configuration after a successful save
                //This will trigger a re-render of the form
                reset(configuration);

                // On succesful change, dispatch the save button click. This will force components to reload
                // and then change it back right away
                handleMetricConfigurationSaveButton(true);
                handleMetricConfigurationSaveButton(false);
            })
            .catch(async (error) => {
                setSaveConfigurationStatus(DataStatus.Failed);
            });
    };

    const deleteClientConceptPageModuleMetric = async (
        clientConcept_PK: number,
        clientConceptPageModuleMetricId: number
    ) => {
        await uiConfigurationApi
            .deleteClientConceptPageModuleMetric(
                clientConcept_PK,
                clientConceptPageModuleMetricId
            )
            .then((x) => {
                // On succesful change, dispatch the save button click. This will force components to reload
                // and then change it back right away
                handleMetricConfigurationSaveButton(true);
                handleMetricConfigurationSaveButton(false);
            });
    };

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: {isSubmitting}
    } = useForm<IClientConceptPageModuleMetricForm>({
        defaultValues
    });

    const {fields, prepend, remove} = useFieldArray({
        control,
        name: "clientConceptPageModuleMetrics",
        keyName: "renderingId"
    });

    const onSubmit: SubmitHandler<IClientConceptPageModuleMetricForm> = async (
        values: IClientConceptPageModuleMetricForm
    ) => {
        //Only use values that actually changed from the intialValues
        const changedValues = difference(
            values.clientConceptPageModuleMetrics,
            defaultValues.clientConceptPageModuleMetrics
        );

        // Empty string is not a valid drilldownPageCode or drilldownType
        // When the dropdown is cleared then it gets set to an empty string. Change the value to undefined
        forEach(changedValues, (v) => {
            if ((v.drilldownPageCode as string) === "") {
                v.drilldownPageCode = undefined;
            }
            if ((v.drilldownType as string) === "") {
                v.drilldownType = undefined;
            }

            //Old configurator only supports selecting drilldown pages through page codes.
            //We need to find the underlying page_PK for the drilldown to work correctly as we use drilldownPage_PK now instead of drilldownPageCode
            if (v.drilldownPageCode) {
                const page_PK = clientConceptPages.find(
                    (x) =>
                        x.pageCode === v.drilldownPageCode &&
                        x.clientConcept_PK === clientConcept_PK
                )?.page_PK;

                if (page_PK) {
                    v.drilldownPage_PK = page_PK;
                }
            }
        });

        if (changedValues.length) {
            await saveClientConceptPageModuleMetrics(
                clientConcept_PK,
                changedValues
            );
        } else {
            //no changes, but act as if a save happened
            setSaveConfigurationStatus(DataStatus.Loaded);
        }
    };

    const columnText = [
        "Remove",
        "Module Metric Id",
        "Page Code",
        "Module Type",
        "Sort Order",
        "Metric Code",
        "Default Sort",
        "Drilldown Page Code",
        "Metric Order",
        "Toggle Order",
        "Metric Order Category",
        "Module Type Sort Order",
        "Day Part Type",
        "Drilldown Type",
        "Has Filter"
    ];
    return (
        <form
            id="clientConceptPageModuleMetricForm"
            className="ui form"
            translate="no"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Button
                            className={"btn btn-blue"}
                            type="submit"
                            form="clientConceptPageModuleMetricForm"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            floated={"right"}
                        >
                            Save
                        </Button>
                        {saveConfigurationStatus === DataStatus.Loaded && (
                            <>
                                {" "}
                                <Button
                                    positive
                                    floated={"right"}
                                    type="button"
                                    style={{cursor: "default"}}
                                >
                                    <Icon name="checkmark" /> Saved
                                </Button>
                            </>
                        )}
                        {saveConfigurationStatus === DataStatus.Failed && (
                            <Button
                                negative
                                floated={"right"}
                                type="button"
                                style={{cursor: "default"}}
                            >
                                <Icon name="exclamation circle" /> Save Failed
                            </Button>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <div className="overflow-x-scroll">
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        {columnText.map((text) => {
                                            return (
                                                <Table.HeaderCell key={text}>
                                                    {text}
                                                </Table.HeaderCell>
                                            );
                                        })}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <>
                                        <Table.Row>
                                            <Table.Cell colSpan={7}>
                                                <Button
                                                    type="button"
                                                    positive
                                                    onClick={() => {
                                                        const pageCode =
                                                            clientConceptPages.find(
                                                                (p) =>
                                                                    p.page_PK ===
                                                                    selectedPage_PK
                                                            )?.pageCode;

                                                        if (pageCode) {
                                                            prepend(
                                                                {
                                                                    clientConcept_PK:
                                                                        clientConcept_PK,
                                                                    page_PK:
                                                                        selectedPage_PK,
                                                                    pageCode:
                                                                        pageCode,
                                                                    moduleType:
                                                                        ModuleType.Unknown,
                                                                    metricCode: "",
                                                                    metricName: "",
                                                                    dataType:
                                                                        DataType.None,
                                                                    sortOrder: 0,
                                                                    toggleOrder: 0,
                                                                    metricOrder: 0,
                                                                    drilldownType:
                                                                        DrilldownType.None
                                                                },
                                                                {
                                                                    focusName:
                                                                        "clientConceptPageModuleMetrics.0.sortOrder"
                                                                }
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Add a Module Metric
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                        {clientConceptPageModuleMetrics &&
                                        clientConceptPageModuleMetrics.length > 0 ? (
                                            fields.map(
                                                (
                                                    clientConceptPageModuleMetric,
                                                    index
                                                ) => {
                                                    return (
                                                        <ModuleMetricFormRow
                                                            clientConceptPageModuleMetric={
                                                                clientConceptPageModuleMetric
                                                            }
                                                            availableClientConceptMetrics={
                                                                availableClientConceptMetrics
                                                            }
                                                            selectedPage_PK={
                                                                selectedPage_PK
                                                            }
                                                            index={index}
                                                            remove={remove}
                                                            control={control}
                                                            isSubmitting={
                                                                isSubmitting
                                                            }
                                                            deleteClientConceptPageModuleMetric={
                                                                deleteClientConceptPageModuleMetric
                                                            }
                                                            moduleTypeOptions={determineModuleTypeOptions(
                                                                clientConceptPageModuleMetric.pageCode
                                                            )}
                                                            setValue={setValue}
                                                            key={
                                                                clientConceptPageModuleMetric.renderingId
                                                            }
                                                        />
                                                    );
                                                }
                                            )
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                </Table.Body>
                            </Table>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Button
                            className={"btn btn-blue"}
                            type="submit"
                            form="clientConceptPageModuleMetricForm"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            floated={"right"}
                        >
                            Save
                        </Button>
                        {saveConfigurationStatus === DataStatus.Loaded && (
                            <>
                                {" "}
                                <Button
                                    positive
                                    floated={"right"}
                                    type="button"
                                    style={{cursor: "default"}}
                                >
                                    <Icon name="checkmark" /> Saved
                                </Button>
                            </>
                        )}
                        {saveConfigurationStatus === DataStatus.Failed && (
                            <Button
                                negative
                                floated={"right"}
                                type="button"
                                style={{cursor: "default"}}
                            >
                                <Icon name="exclamation circle" /> Save Failed
                            </Button>
                        )}
                        <DevTool control={control} /> {/* set up the dev tool */}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </form>
    );
};

const mapStateToProps = (state: AppState) => ({
    clientConceptPages: state.application.clientConceptPages
});

const connector = connect(mapStateToProps, actionCreators);

type IReduxProps = ConnectedProps<typeof connector>;

export default connector(ModuleMetricForm);

function determineModuleTypeOptions(selectedPageCode: PageCode): {
    key: string;
    text: string;
    value: string;
}[] {
    let moduleTypeOptions: {key: string; text: string; value: string}[];

    switch (selectedPageCode) {
        case PageCode.MetricDetails:
            moduleTypeOptions = moduleTypeOptionsForPageCode.MetricDetails;
            break;
        case PageCode.Monitor:
            moduleTypeOptions = moduleTypeOptionsForPageCode.Monitor;
            break;
        case PageCode.Dashboard:
            moduleTypeOptions = moduleTypeOptionsForPageCode.Dashboard;
            break;
        case PageCode.FoodCost:
            moduleTypeOptions = moduleTypeOptionsForPageCode.FoodCost;
            break;
        case PageCode.NetSales:
        case PageCode.Labor:
        case PageCode.SpeedOfService:
        case PageCode.VoiceOfCustomer:
        case PageCode.ChannelSales:
        case PageCode.Loss:
        case PageCode.Tracking:
        case PageCode.Delivery:
        case PageCode.FlashPage:
            moduleTypeOptions = moduleTypeOptionsForPageCode.MetricDetail_Pages;
            break;
        case PageCode.EmployeeRetention:
            moduleTypeOptions = moduleTypeOptionsForPageCode.EmployeeRetention;
            break;
        default:
            moduleTypeOptions = union(
                moduleTypeOptionsForPageCode.MetricDetails,
                moduleTypeOptionsForPageCode.Monitor,
                moduleTypeOptionsForPageCode.Dashboard,
                moduleTypeOptionsForPageCode.FoodCost,
                moduleTypeOptionsForPageCode.MetricDetail_Pages
            );
    }

    return moduleTypeOptions;
}

import moment from "moment";

/**
 * Returns a string of a given date without a timestamp.
 * @param {Date | null | undefined} date
 */
export function dateToString(date: Date | null | undefined): string {
    // we need the string date format to be yyyy-mm-dd
    if (date) {
        return moment(date).format("YYYY-MM-DD");
    }

    return "";
}

/**
 * Returns a formatted string representing the time portion of a Date.
 * @param {Date | null | undefined} date
 */
export function timeToString(date: Date | null | undefined): string {
    if (date) {
        return moment(date).format("hh:mm A");
    }

    return "";
}

/**
 * Returns a string of a given date with a timestamp.
 * @param {Date | null} date
 */
export function dateWithTimeToString(date: Date | null): string {
    // we need the string date format to be yyyy-mm-dd
    if (date !== null) {
        return moment(date).format("YYYY-MM-DD h:mm:ss A");
    }

    return "";
}

/**
 * Converts a string to a date.
 * @param {string} date
 */
export function getDateFromString(date: string | undefined): Date {
    return moment(date).toDate();
}

/**
 * Returns a yesterday's date.
 */
export function getYesterday(): Date {
    return addDays(new Date(), -1);
}

/**
 * Returns the date X days ago.
 */
export function getXDaysAgo(daysAgo: number): Date {
    return addDays(new Date(), -1 * daysAgo);
}

/**
 * Returns a date that is {daysToAdd} beyond {date}.
 */
export function addDays(date: Date, daysToAdd: number): Date {
    const dateCopy = new Date(date.getTime());
    dateCopy.setDate(date.getDate() + daysToAdd);

    return dateCopy;
}

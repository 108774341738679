import * as auth from "./actions";

// src/store/chat/reducers.ts

export enum AuthenticationAuthorizationResult {
    AuthenticationFailure = "AuthenticationFailure",
    AuthorizationFailure = "AuthorizationFailure",
    UnknownFailure = "UnknownFailure",
    Success = "Success",
    Unauthenticated = "Unauthenticated"
}

export type AuthState = Readonly<{
    isAuthenticated: AuthenticationAuthorizationResult;
    error: string;
}>;

const initialState: AuthState = {
    isAuthenticated: AuthenticationAuthorizationResult.Unauthenticated,
    error: ""
};

// each item in this combineReducers corresponds to a subset of AuthState
// all the reducers get combined into a mega-reducer found in src/state/AppReducer
const authState = function (
    state: AuthState = initialState,
    action: auth.AuthAction
): AuthState {
    switch (action.type) {
        case auth.AUTH_SUCCESS:
            return {
                isAuthenticated: AuthenticationAuthorizationResult.Success,
                error: ""
            };

        case auth.AUTH_FAILURE:
            return {
                isAuthenticated:
                    AuthenticationAuthorizationResult.AuthenticationFailure,
                error: action.error
            };

        case auth.AUTHORIZATION_FAILURE:
            return {
                isAuthenticated:
                    AuthenticationAuthorizationResult.AuthorizationFailure,
                error: action.error
            };
        //This is the generic case when the failure is not an Authentication Failure or an Authorization Failure
        case auth.AUTH_ERROR:
            return {
                isAuthenticated: AuthenticationAuthorizationResult.UnknownFailure,
                error: action.error
            };

        default:
            return state;
    }
};

export default authState;

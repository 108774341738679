import apiUtil from "../../../util/apiUtil";
import {
    IAddCommentRequest,
    IClientUser,
    ICreateTaskRequest,
    IReassignTaskRequest,
    ITaskComment,
    ITaskData
} from "./tasksModels";

const tasksAPI = {
    async createTask(createTaskRequest: ICreateTaskRequest): Promise<ITaskData> {
        return await apiUtil.postData("/api/task/CreateTask", createTaskRequest);
    },

    async closeTask(id: number): Promise<boolean> {
        return await apiUtil.postData("/api/task/CloseTask", id);
    },

    async addComment(addCommentRequest: IAddCommentRequest): Promise<Response> {
        return await apiUtil.postData("/api/task/AddComment", addCommentRequest);
    },

    async reassignTask(
        reassignTaskRequest: IReassignTaskRequest
    ): Promise<ITaskData> {
        return await apiUtil.postData(
            "/api/task/reassignTask/",
            reassignTaskRequest
        );
    },

    async fetchUsers(): Promise<IClientUser[]> {
        return await apiUtil.fetchData("/api/client/UsersInMyClient/");
    },

    async fetchTaskDetails(taskId: number): Promise<ITaskData> {
        return await apiUtil.fetchData(`/api/task/TaskDetails/${taskId}`);
    },

    async fetchTaskComments(taskId: number): Promise<ITaskComment[]> {
        return await apiUtil.fetchData(`/api/task/TaskComments/${taskId}`);
    },

    async fetchClientTasks(clientId: string): Promise<ITaskData[]> {
        return await apiUtil.fetchData(`/api/task/ClientTasks/${clientId}`);
    },

    async fetchOpenTaskCount(): Promise<number> {
        return await apiUtil.fetchData("/api/task/OpenTaskCount");
    }
};

export default tasksAPI;

import {combineReducers} from "redux";
import {StateType} from "typesafe-actions";
import {ApplicationState} from "../features/application/state/reducer";
import {applicationActions, applicationReducer} from "../features/application/state";
import {authActions, authReducer} from "../features/authentication";
import {dashboardActions, dashboardReducer} from "../features/dashboard";
import {metricDetailsActions, metricDetailsReducer} from "../features/metricDetails";
import {monitorActions, monitorReducer} from "../features/monitors/state";
//import {AppStore} from "./store";

const appReducer = combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer, // equivalent (ignoring TypeScript) to the following:  dashboard: dashboard(state.dashboard, action)
    metricDetails: metricDetailsReducer,
    application: applicationReducer,
    monitor: monitorReducer
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = () => (state: AppState | undefined, action: AppAction) => {
    if (action.type === authActions.AUTH_SIGNOUT) {
        //when sign out wipe out all the state data but auth - we want to preserve the auth data to know if auth failed or not (that's used for redirection in ProtectedRoute)
        // also keep the legacy site base url which is used to redirect
        //when first sign in, wipe out the previous state as well
        //this is specifically for the users who can sign to different clients, but didn't sign out first
        if (state) {
            const app = {
                legacySiteBaseUrl: state.application.legacySiteBaseUrl
            } as any as ApplicationState;
            const {auth} = state;
            state = {application: app, auth: auth} as any as AppState;
        }
    }
    return appReducer(state, action);
};

// The the comprehensive state of the application is defined by the reducers
export type AppState = Readonly<{
    auth: StateType<typeof authReducer>;
    dashboard: StateType<typeof dashboardReducer>;
    metricDetails: StateType<typeof metricDetailsReducer>;
    application: StateType<typeof applicationReducer>;
    monitor: StateType<typeof monitorReducer>;
}>;

export type AppAction =
    | dashboardActions.DashboardAction
    | authActions.AuthAction
    | metricDetailsActions.MetricDetailsAction
    | applicationActions.ApplicationAction
    | monitorActions.MonitorAction;

export default rootReducer;

import {AppState} from ".";

// this file is basically a handful of utilities that are used for making actions/action creators

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface IAction<Type = string> {
    type: Type;
}

export type ActionPayload<TAction extends IAction> = Omit<TAction, "type">;

export function actionCreator<TAction extends IAction>(type: string) {
    return (payload: ActionPayload<TAction>): TAction => {
        return {
            ...(payload as Record<string, unknown>),
            type
        } as any as TAction;
    };
}

// This type can be used as a hint on action creators so that its "dispatch" and "getState" params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = (
    dispatch: (action: TAction) => void,
    getState: () => AppState
) => void;

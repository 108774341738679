import {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "state";
import {
    UrlModel,
    UrlParams,
    getNewClientConceptFromSelectedBusinessUnitId,
    IAppRouteProps,
    IUrlParam,
    RouteType
} from "util/appParamsUtil";
import {PageCode} from "features/configuration/api/uiConfigurationModels";
import {find} from "lodash";
import {actionCreators as MetricDetailsActionCreators} from "../../metricDetails/state/actions";
import {
    IClientConcept,
    IBusinessUnit,
    BusinessUnitType,
    IClientConceptPage
} from "../api/applicationModels";
import {actionCreators as ApplicationActionCreators} from "../state/actions";
import DropdownTreeSelector from "./DropdownTreeSelector";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {getCurrentSelectedBusinessUnitId} from "util/stateUtil";
import {BusinessUnitAggregation} from "features/metricDetails/api/metricDetailsModels";
import {isAreaWithLocations, isLocation} from "util/businessUnitAggregationUtil";

interface IReduxProps {
    availableClientConcepts: IClientConcept[];
    selectedClientConcept: IClientConcept;
    rootGroupId: string;
    userDefaultGroupId: string;
    clientTreeHierarchy: IBusinessUnit[];
    businessUnitId: string;
    businessUnitAggregation: BusinessUnitAggregation;
    clientConceptPages: IClientConceptPage[];
    routeType: RouteType; // we need the routeType to set correct page_PK for the Dashboard page
}

interface IProps {
    urlModel: UrlModel;
}

const actionCreators = {
    ...ApplicationActionCreators,
    ...MetricDetailsActionCreators
};
type ClientTreeHierarchyContainerProps = IReduxProps &
    IProps &
    RouteComponentProps<IAppRouteProps> &
    typeof actionCreators;

class ClientTreeHierarchyContainer extends Component<ClientTreeHierarchyContainerProps> {
    render() {
        const {clientTreeHierarchy} = this.props;
        const page_PK = +this.props.match.params.page_PK;

        let groupTreeData: {
            id: string;
            pId: string;
            value: string;
            title: JSX.Element;
        }[] = [];
        if (clientTreeHierarchy && clientTreeHierarchy.length > 0) {
            groupTreeData = clientTreeHierarchy.map((bu) => {
                const newUrlParams: IUrlParam[] = [];
                newUrlParams.push({
                    code: UrlParams.BusinessUnitId,
                    value: bu.businessUnitId
                });

                // Update the client concept if the selected bussines unit belongs to a different client concept
                const newClientConcept =
                    getNewClientConceptFromSelectedBusinessUnitId(
                        this.props.selectedClientConcept.clientConcept_PK,
                        this.props.availableClientConcepts,
                        this.props.clientTreeHierarchy,
                        bu.businessUnitId
                    );

                // Update page_PK if the selected bussines unit belongs to a different client concept
                if (newClientConcept) {
                    newUrlParams.push({
                        code: UrlParams.conceptUrlName,
                        value: newClientConcept.conceptUrlName
                    });

                    if (this.props.routeType === RouteType.DASHBOARD) {
                        // page_PK might not be in the Dashboard page URL
                        const newPage = find(
                            this.props.clientConceptPages,
                            (p) =>
                                p.clientConcept_PK ===
                                    newClientConcept.clientConcept_PK &&
                                p.pageCode === PageCode.Dashboard
                        );

                        if (newPage) {
                            newUrlParams.push({
                                code: UrlParams.Page_PK,
                                value: newPage.page_PK.toString()
                            });
                        }
                    } else if (page_PK) {
                        // if there is a page_PK in the URL, then find the current page and use the pageCode to update the page_PK for the new ClientConcept
                        const currentPage = find(
                            this.props.clientConceptPages,
                            (p) => p.page_PK === page_PK
                        );

                        // For custom pages, don't try to find the new page_PK because PageCode.MetricDetail_Custom is not unique
                        if (
                            currentPage &&
                            currentPage.pageCode !== PageCode.MetricDetail_Custom
                        ) {
                            const newPage = find(
                                this.props.clientConceptPages,
                                (p) =>
                                    p.clientConcept_PK ===
                                        newClientConcept.clientConcept_PK &&
                                    p.pageCode === currentPage.pageCode
                            );

                            if (newPage) {
                                newUrlParams.push({
                                    code: UrlParams.Page_PK,
                                    value: newPage.page_PK.toString()
                                });
                            }
                        }
                    }
                }

                // If the currently selected business aggregation is no longer available,
                // the business aggregation will default to Restaurants
                if (
                    (isAreaWithLocations(bu.businessUnitId) &&
                        this.props.businessUnitAggregation !==
                            BusinessUnitAggregation.NonAreaGroups) ||
                    (isLocation(bu.businessUnitId) &&
                        this.props.businessUnitAggregation !==
                            BusinessUnitAggregation.Locations)
                ) {
                    newUrlParams.push({
                        code: UrlParams.BusinessUnitAggregation,
                        value: BusinessUnitAggregation.Locations
                    });
                }

                const newUrl =
                    this.props.urlModel.generateUrlWithParameters(newUrlParams);

                const displayName =
                    bu.businessUnitType === BusinessUnitType.Location &&
                    bu.businessUnitDescription // businessUnitDescription can sometimes be null
                        ? bu.businessUnitName + " - " + bu.businessUnitDescription
                        : bu.businessUnitName;

                return {
                    id: bu.businessUnitId,
                    pId: bu.parentBusinessUnitId || "",
                    value: bu.businessUnitId,
                    name: displayName, // this props is used for the tree filtering
                    title: <Link to={newUrl}>{displayName}</Link> // this is what is displayed
                };
            });
        }

        return (
            <>
                <DropdownTreeSelector
                    groupTreeData={groupTreeData}
                    selectedValue={this.props.businessUnitId}
                />
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        availableClientConcepts: state.application.availableClientConcepts,
        selectedClientConcept: state.application.selectedClientConcept,
        rootGroupId: state.application.selectedClientConcept.rootGroupId,
        userDefaultGroupId: state.application.userSettings.defaultBusinessUnitId,
        clientTreeHierarchy: state.application.clientTreeHierarchy,
        businessUnitId: getCurrentSelectedBusinessUnitId(state),
        businessUnitAggregation: state.application.businessUnitAggregation,
        clientConceptPages: state.application.clientConceptPages
    };
};

export default withRouter(
    connect(mapStateToProps, actionCreators, (state, actions, props) => ({
        ...state,
        ...actions,
        ...props
    }))(ClientTreeHierarchyContainer)
);

import {find, round} from "lodash";
import {DataType} from "./dataFormatUtil";
import {IModuleMetricDefinition} from "features/dashboard/api/dashboardModels";
import {
    IColumnMetadata,
    ICustomColumnsTransformation
} from "features/application/api/applicationModels";
import moment from "moment";

// Enum for sorting directions. It uses "ascending" and "descending" to
// match SUIR types. So, it should not be changed.
export enum sortingDirections {
    Ascending = "ascending",
    Descending = "descending"
}
/**
 * Unboxes an object and returns a property's value based on a key provided.
 *
 * @param {*} obj - Object to unbox.
 * @param {string} key - Dot separated string to access the property's value.
 * @returns {string | number | null} Value of the given property. We expect it to be a string,
 * number or null.
 */
export function getPropertyValue(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    obj: any,
    key: string,
    metricDefinition?: IColumnMetadata | IModuleMetricDefinition | undefined,
    customColumnsTransformations?: ICustomColumnsTransformation
): string | number | null {
    const subKeys: string[] = key.split(".");
    let objectLayer: any = obj;
    for (let i = 0; i < subKeys.length; i++) {
        if (objectLayer && subKeys[i] in objectLayer) {
            objectLayer = objectLayer[subKeys[i]];
        } else if (
            // If a column is a custom column, then get the sort value from the custom column function
            objectLayer &&
            customColumnsTransformations &&
            subKeys[i] in customColumnsTransformations &&
            metricDefinition &&
            "key" in metricDefinition
        ) {
            const customColumnSortValue = customColumnsTransformations[
                key
            ].getCustomColumnMetaData(obj, metricDefinition, false).sortValue as
                | string
                | number
                | null
                | undefined;
            if (customColumnSortValue === undefined) {
                return null;
            } else {
                return customColumnSortValue;
            }
        } else {
            return null;
        }
    }

    return objectLayer;
}

/**
 * Custom object comparer to always put null values at the end and also to allow sorting on multiple properties.
 *
 * @param {string[] | string} key - Dot separated key or arrays of keys.
 * @param {sortingDirections} sortDirection - Sorting direction.
 * @param {IModuleMetricDefinition[] | IColumnMetadata[]} metricDefinitions - Optional parameter to round numbers according to their metric definitions.
 * @returns {number} Result of the comparison (1, -1, or 0).
 */
export function objectComparer(
    key: string[] | string,
    sortDirection: sortingDirections,
    metricDefinitions?: IModuleMetricDefinition[] | IColumnMetadata[],
    customColumnsTransformations?: ICustomColumnsTransformation
) {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    return function (a: any, b: any): number {
        // check if we are trying to sort on one or multiple values
        let keyArray: string[];
        if (typeof key === "string") {
            keyArray = [key];
        } else {
            keyArray = key;
        }

        const metricDefinition = getMetricDefinitionByColumnKey(
            metricDefinitions,
            keyArray[0]
        );

        // arrays of value(s) to compare
        const valuesA: (string | number | null)[] = [];
        const valuesB: (string | number | null)[] = [];
        for (let i = 0; i < keyArray.length; i++) {
            const currentKey = keyArray[i];

            const propertyValueA = getPropertyValue(
                a,
                currentKey,
                metricDefinition,
                customColumnsTransformations
            );
            const propertyValueB = getPropertyValue(
                b,
                currentKey,
                metricDefinition,
                customColumnsTransformations
            );

            // !isNaN() checks if string to be sorted only contains numbers
            if (
                !isNaN(propertyValueA as any) &&
                propertyValueA &&
                !isNaN(propertyValueB as any) &&
                propertyValueB
            ) {
                valuesA.push(Number(propertyValueA));
                valuesB.push(Number(propertyValueB));
            } else {
                valuesA.push(propertyValueA);
                valuesB.push(propertyValueB);
            }
        }

        // go through all the values and return the result of their comparison
        return resolveSort(
            valuesA,
            valuesB,
            keyArray,
            0,
            sortDirection,
            metricDefinition,
            metricDefinitions
        );
    };
}

/**
 * Recursive helper function to resolve a comparison of the given values.
 *
 * @param {(string | number | null)[]} valuesA - First array of values to compare.
 * @param {(string | number | null)[]} valuesB - Second array of values to compare.
 * @param {number} index - Index of the current values from `valuesA` and `valuesB` we would like to compare.
 * @param {sortingDirections} sortDirection - Sorting direction.
 * @param {IModuleMetricDefinition[] | IColumnMetadata[]} metricDefinitions - Optional parameter to round numbers according to their metric definitions.
 * @returns {number} Result of the comparison (1, -1, or 0).
 */
function resolveSort(
    valuesA: (string | number | null)[],
    valuesB: (string | number | null)[],
    keyArray: string[],
    index: number,
    sortDirection: sortingDirections,
    metricDefinition?: IModuleMetricDefinition | IColumnMetadata,
    metricDefinitions?: IModuleMetricDefinition[] | IColumnMetadata[]
): number {
    // to avoid strictNullChecks error you need to assign the values to a variable first
    let valA = valuesA[index];
    let valB = valuesB[index];

    // Some metrics require to be rounded before sorting, i.e. VOC where we display rounded values and perform subsort on total surveys received
    if (metricDefinition) {
        // Only format the value if it is not null or undefined
        if (valA === null || valA === undefined) {
            valA = null;
        } else {
            valA = formatValueForSorting(valA, metricDefinition);
        }
        // Only format the value if it is not null or undefined
        if (valB === null || valB === undefined) {
            valB = null;
        } else {
            valB = formatValueForSorting(valB, metricDefinition);
        }
    }

    if (valA === null) {
        return 1;
    } else if (valB === null) {
        return -1;
    } else if (valA === valB) {
        if (index === valuesA.length) {
            return 0;
        }

        const newMetricDefinition = getMetricDefinitionByColumnKey(
            metricDefinitions,
            keyArray[index + 1]
        );

        return resolveSort(
            valuesA,
            valuesB,
            keyArray,
            index + 1,
            sortDirection,
            newMetricDefinition,
            metricDefinitions
        );
    } else if (sortDirection === sortingDirections.Ascending) {
        return valA < valB ? -1 : 1;
    } else {
        return valA < valB ? 1 : -1;
    }
}

function formatValueForSorting(
    value: string | number | null,
    metricDefinition: IModuleMetricDefinition | IColumnMetadata
) {
    if (
        metricDefinition.dataType === DataType.Number ||
        metricDefinition.dataType === DataType.Money ||
        metricDefinition.dataType === DataType.Percent
    ) {
        return round(
            metricDefinition.dataType === DataType.Percent
                ? Number(value) * 100
                : Number(value),
            metricDefinition.decimalPlaces || 0
        );
    } else if (metricDefinition.dataType === DataType.Date) {
        return moment(value as string)
            .toDate()
            .setHours(0, 0, 0, 0)
            .valueOf();
    } else if (metricDefinition.dataType === DataType.DateTime) {
        return moment(value as string)
            .toDate()
            .valueOf();
    } else {
        return value;
    }
}

function getMetricDefinitionByColumnKey(
    metricDefinitions: IColumnMetadata[] | IModuleMetricDefinition[] | undefined,
    columnKey: string
) {
    return find<IModuleMetricDefinition | IColumnMetadata>(
        metricDefinitions,
        (m) => {
            if ("key" in m) {
                return m.key === columnKey;
            } else {
                return m.metricCode === columnKey;
            }
        }
    );
}

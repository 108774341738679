import React, {FC, useRef} from "react";
import {Icon} from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";

interface IProps {
    defaultCardHeaderName: string;
    currentAlternateCardHeaderName: string;
    onChange: (value: string) => void;
    valueIsModified: boolean;
}

export const EditableCardHeader: FC<IProps> = ({
    defaultCardHeaderName,
    currentAlternateCardHeaderName,
    onChange,
    valueIsModified
}) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleClear = () => {
        onChange("");
        textAreaRef.current?.focus(); // Focus on input after clearing
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter") {
            e.preventDefault(); // prevent newlines
            textAreaRef.current?.blur(); // unfocus textbox
        }
    };

    return (
        <div className="editable-card-header-container">
            <TextareaAutosize
                ref={textAreaRef}
                placeholder={defaultCardHeaderName}
                onChange={(e) => onChange(e.target.value)}
                value={currentAlternateCardHeaderName}
                onKeyDown={handleKeyDown}
                maxLength={50}
                autoComplete="new-password" // hints browser to prevent autocompleting/autofilling
                className={`editable-card-header ${
                    valueIsModified ? "card-header-modified" : ""
                }
                    ${
                        currentAlternateCardHeaderName ? "card-header-clearable" : ""
                    }`}
            />
            {currentAlternateCardHeaderName && ( // Only render clear button if there's a value in the text box
                <button onClick={handleClear} className="card-header-clear-button">
                    <Icon name="close" style={{margin: 0}} />
                </button>
            )}
        </div>
    );
};

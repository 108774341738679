import * as monitor from "./actions";
import {combineReducers} from "redux";
import {MonitorInteraction} from "../api/monitorsModels";

export type MonitorState = Readonly<{
    monitorActionTaken: MonitorInteraction;
}>;

type Actions = monitor.MonitorAction;

export default combineReducers<MonitorState, Actions>({
    monitorActionTaken: (state = "NONE", action) => {
        switch (action.type) {
            case monitor.HANDLE_MONITOR_ACTION:
                return action.monitorActionTaken;
            default:
                return state;
        }
    }
});

// These should match the module types defined in the Dashboard database
export enum ModuleType {
    Unknown = "Unknown",
    MetricTile = "MetricTile",
    MetricStanding = "MetricStanding",
    MetricDetailGrid = "MetricDetailGrid",
    ColumnChart = "ColumnChart",
    LineChart = "LineChart",
    InventoryDetails = "InventoryDetails",
    TransactionDetails = "TransactionDetails",
    LocationDetails = "LocationDetails"
}

export interface IPageModuleConfiguration {
    moduleType: ModuleType;
    moduleTypeSortOrder: number;
    moduleName?: string;
}

// These should match the page codes defined in the Dashboard database
export enum PageCode {
    Unknown = "Unknown",
    Dashboard = "Dashboard",
    NetSales = "MetricDetail_NetSales",
    FoodCost = "MetricDetail_FoodCost",
    Labor = "MetricDetail_Labor",
    SpeedOfService = "MetricDetail_SOS",
    SpeedOfServiceExtended = "MetricDetail_DriveThru",
    VoiceOfCustomer = "MetricDetail_VOC",
    ChannelSales = "MetricDetail_ChannelSales",
    Loss = "MetricDetail_Loss",
    Tracking = "MetricDetail_Tracking",
    Delivery = "MetricDetail_Delivery",
    FlashPage = "MetricDetail_FlashPage",
    Recovery = "MetricDetail_Recovery",
    Monitor = "Monitor",
    MetricDetails = "MetricDetails",
    EmployeeWages = "MetricDetail_EmployeeWages",
    DeliveryProviderPaymentData = "DeliveryProviderPaymentData",
    EmployeeRetention = "EmployeeRetention",
    DeliveryLoss = "MetricDetail_DeliveryLoss",
    MetricDetail_Custom = "MetricDetail_Custom",
    DeliveryOperations = "DeliveryOperations",
    DowntimeDetails = "DowntimeDetails"
}

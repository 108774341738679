import {combineReducers} from "redux";
import {
    IBusinessUnitMetricData,
    IModuleMetricDefinition
} from "../api/dashboardModels";
import * as dashboard from "./actions";
import {DataStatus} from "features/application/api/applicationModels";

// not sure why this needs to be Readonly
export type DashboardState = Readonly<{
    isGridLoading: boolean;
    fetchMetricsDataStatus: DataStatus;
    locationMetricData: IBusinessUnitMetricData[];
    metricDefinitions: IModuleMetricDefinition[];
}>;

// each item in this combineReducers corresponds to a subset of DashboardState
// all the reducers get combined into a mega-reducer found in src/state/AppReducer
export default combineReducers<DashboardState, dashboard.DashboardAction>({
    // by default, isLoading is false
    isGridLoading: (state = false, action) => {
        switch (action.type) {
            case dashboard.REQUEST_METRICS:
                return true;

            case dashboard.RECEIVE_METRICS:
                return false;

            default:
                return state;
        }
    },

    locationMetricData: (state = [], action) => {
        switch (action.type) {
            case dashboard.RECEIVE_METRICS:
                return action.locationMetricDataResult.metricData || [];

            default:
                return state;
        }
    },

    metricDefinitions: (state = [], action) => {
        switch (action.type) {
            case dashboard.RECEIVE_METRICS:
                return action.locationMetricDataResult.metricDefinitions || [];

            default:
                return state;
        }
    },

    fetchMetricsDataStatus: (state = DataStatus.Undefined, action) => {
        switch (action.type) {
            case dashboard.REQUEST_METRICS:
                return DataStatus.Undefined;

            case dashboard.RECEIVE_METRICS:
                return DataStatus.Loaded;

            case dashboard.FAILURE_METRICS: {
                if (action.error.status === 403) {
                    return DataStatus.Forbidden;
                } else if (action.error.name === "AbortError") {
                    return DataStatus.Undefined; // Ignore abort errors
                } else {
                    return DataStatus.Failed;
                }
            }

            default:
                return state;
        }
    }
});

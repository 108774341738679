import {IDeliveryProviderPayoutDate} from "features/deliveryProvider/api/deliveryProviderModels";
import {Action} from "redux";
import {actionCreator, AppThunkAction} from "../../../state/actions";
import {Xtd} from "../../dashboard/api/dashboardModels";
import {BusinessUnitAggregation} from "../../metricDetails/api/metricDetailsModels";
import tasksAPI from "../../tasks/api/tasksAPI";
import applicationAPI from "../api/applicationAPI";
import {
    IDomainSettings,
    IUserSettings,
    IClientConcept,
    IClientConceptPage
} from "../api/applicationModels";

export const CHANGE_APP_PARAMETERS = "change/APP_PARAMETERS";
export const CHANGE_FROM_DATE = "change/FROM_DATE";
export const CHANGE_DATE = "change/DATE";
export const CHANGE_XTD = "change/XTD";
export const CHANGE_BUSINESS_UNIT_AGGREGATION = "change/BUSINESS_UNIT_AGGREGATION";
export const CHANGE_CLIENT_CONCEPT = "change/CLIENT_CONCEPT";
export const GET_CLIENT_CONCEPT_PAGES = "get/CLIENT_CONCEPT_PAGES";

export const USER_SETTINGS_SAVE = "save/USER_SETTINGS";

export const USER_SETTINGS_CONFIRM = "confirm/USER_SETTINGS";
export const USER_SETTINGS_ERROR = "error/USER_SETTINGS";
export const GET_OPEN_TASK_COUNT = "get/OPEN_TASK_COUNT";

export const SWITCH_CURRENT_SELECTED_BUSINESS_UNIT = "switch/CURRENT_BUSINESS_UNIT";

export const RECEIVE_DOMAIN_SETTINGS = "receive/DOMAIN_SETTINGS";
export const ERROR_DOMAIN_SETTINGS = "error/DOMAIN_SETTINGS";

export const HANDLE_FREEMIUM_MARKETING_MODAL = "handle/FREEMIUM_MARKETING_MODAL";
export const HANDLE_METRIC_CONFIGURATION_SAVE_BUTTON =
    "handle/METRIC_CONFIGURATION_SAVE_BUTTON";

export const CHANGE_DELIVERY_PROVIDER_PAYOUT_DATES =
    "change/DELIVERY_PROVIDER_PAYOUT_DATES";

export const HANDLE_SCREEN_RESIZE = "handle/SCREEN_RESIZE";

interface IChangeAppParameters extends Action<typeof CHANGE_APP_PARAMETERS> {
    clientConcept: IClientConcept;
    clientConceptsForDetect: IClientConcept[];
    clientConceptsForDeliveryPage: IClientConcept[];
    clientConceptsForRecovery: IClientConcept[];
    clientConceptsForDeliveryOperations: IClientConcept[];
    businessUnitId: string;
    fromDate: Date | null;
    date: Date;
    xtd: Xtd;
    businessUnitAggregation: BusinessUnitAggregation;
}

interface IChangeDate extends Action<typeof CHANGE_DATE> {
    date: Date;
}

interface IChangeFromDate extends Action<typeof CHANGE_FROM_DATE> {
    fromDate: Date | null;
}

interface IChangeXtd extends Action<typeof CHANGE_XTD> {
    xtd: Xtd;
}

interface IChangeBusinessUnitAggregation
    extends Action<typeof CHANGE_BUSINESS_UNIT_AGGREGATION> {
    businessUnitAggregation: BusinessUnitAggregation;
}

type ISaveUserSettings = Action<typeof USER_SETTINGS_SAVE>;

interface IConfirmUserSettings extends Action<typeof USER_SETTINGS_CONFIRM> {
    userSettings: IUserSettings;
}

interface IUserSettingsError extends Action<typeof USER_SETTINGS_ERROR> {
    errorMessage: string;
}

interface ISwtichCurrentSelectedBusinessUnit
    extends Action<typeof SWITCH_CURRENT_SELECTED_BUSINESS_UNIT> {
    businessUnitId: string;
}

interface IGetOpenTaskCount extends Action<typeof GET_OPEN_TASK_COUNT> {
    openTaskCount: number;
}

interface IReceiveDomainSettings extends Action<typeof RECEIVE_DOMAIN_SETTINGS> {
    domainSettings: IDomainSettings;
}

interface IErrorDomainSettings extends Action<typeof ERROR_DOMAIN_SETTINGS> {
    errorMessage: string;
}

interface IHandleFreemiumMarketingModal
    extends Action<typeof HANDLE_FREEMIUM_MARKETING_MODAL> {
    freemiumMarketingModalOpen: boolean;
}

interface IHandleMetricConfigurationSaveButton
    extends Action<typeof HANDLE_METRIC_CONFIGURATION_SAVE_BUTTON> {
    saveButtonClicked: boolean;
}

interface IChangeClientConcept extends Action<typeof CHANGE_CLIENT_CONCEPT> {
    clientConcept: IClientConcept;
    clientConceptsForDetect: IClientConcept[];
    clientConceptsForDeliveryPage: IClientConcept[];
    clientConceptsForRecovery: IClientConcept[];
    clientConceptsForDeliveryOperations: IClientConcept[];
}

interface IHandleScreenResize extends Action<typeof HANDLE_SCREEN_RESIZE> {
    screenWidth: number;
}

interface IGetClientConceptPages extends Action<typeof GET_CLIENT_CONCEPT_PAGES> {
    clientConceptPages: IClientConceptPage[];
}

interface IChangeDeliveryProviderPayoutDatesForBusinessUnit
    extends Action<typeof CHANGE_DELIVERY_PROVIDER_PAYOUT_DATES> {
    deliveryProviderPayoutDatesForBusinessUnit: IDeliveryProviderPayoutDate[];
}

const changeAppParameters =
    actionCreator<IChangeAppParameters>(CHANGE_APP_PARAMETERS);
const changeFromDate = actionCreator<IChangeFromDate>(CHANGE_FROM_DATE);
const changeDate = actionCreator<IChangeDate>(CHANGE_DATE);
const changeXtd = actionCreator<IChangeXtd>(CHANGE_XTD);
const changeBusinessUnitAggregation = actionCreator<IChangeBusinessUnitAggregation>(
    CHANGE_BUSINESS_UNIT_AGGREGATION
);
const changeClientConcept =
    actionCreator<IChangeClientConcept>(CHANGE_CLIENT_CONCEPT);
const getClientConceptPages = actionCreator<IGetClientConceptPages>(
    GET_CLIENT_CONCEPT_PAGES
);

const saveUserSettings = actionCreator<ISaveUserSettings>(USER_SETTINGS_SAVE);

const confirmUserSettings =
    actionCreator<IConfirmUserSettings>(USER_SETTINGS_CONFIRM);
const userSettingsError = actionCreator<IUserSettingsError>(USER_SETTINGS_ERROR);
const getOpenTaskCount = actionCreator<IGetOpenTaskCount>(GET_OPEN_TASK_COUNT);

const switchCurrentSelectedBusinessUnit =
    actionCreator<ISwtichCurrentSelectedBusinessUnit>(
        SWITCH_CURRENT_SELECTED_BUSINESS_UNIT
    );

const receiveDomainSettings = actionCreator<IReceiveDomainSettings>(
    RECEIVE_DOMAIN_SETTINGS
);
const errorDomainSettings =
    actionCreator<IErrorDomainSettings>(ERROR_DOMAIN_SETTINGS);

export const handleScreenResize =
    actionCreator<IHandleScreenResize>(HANDLE_SCREEN_RESIZE);

export const handleFreemiumMarketingModal =
    actionCreator<IHandleFreemiumMarketingModal>(HANDLE_FREEMIUM_MARKETING_MODAL);

export const handleMetricConfigurationSaveButton =
    actionCreator<IHandleMetricConfigurationSaveButton>(
        HANDLE_METRIC_CONFIGURATION_SAVE_BUTTON
    );

export const changeDeliveryProviderPayoutDatesForBusinessUnit =
    actionCreator<IChangeDeliveryProviderPayoutDatesForBusinessUnit>(
        CHANGE_DELIVERY_PROVIDER_PAYOUT_DATES
    );

export type ApplicationAction =
    | IChangeAppParameters
    | IChangeFromDate
    | IChangeDate
    | IChangeXtd
    | IChangeClientConcept
    | IGetClientConceptPages
    | ISaveUserSettings
    | IConfirmUserSettings
    | IUserSettingsError
    | ISwtichCurrentSelectedBusinessUnit
    | IGetOpenTaskCount
    | IReceiveDomainSettings
    | IErrorDomainSettings
    | IChangeBusinessUnitAggregation
    | IHandleFreemiumMarketingModal
    | IHandleScreenResize
    | IHandleMetricConfigurationSaveButton
    | IChangeDeliveryProviderPayoutDatesForBusinessUnit;

// ----------------------------------------------------------------------------

export const actionCreators = {
    changeAppParameters(
        clientConcept: IClientConcept,
        businessUnitId: string,
        fromDate: Date | null,
        date: Date,
        xtd: Xtd,
        businessUnitAggregation: BusinessUnitAggregation
    ): AppThunkAction<ApplicationAction> {
        return (dispatch: (action: ApplicationAction) => void, getState) => {
            const clientConceptsForDetect =
                getState().application.availableClientConceptsForCoachDetect;
            const clientConceptsForDeliveryPage =
                getState().application.availableClientConceptsForCoachDeliveryPage;
            const clientConceptsForRecovery =
                getState().application.availableClientConceptsForCoachRecovery;
            const clientConceptsForDeliveryOperations =
                getState().application.availableClientConceptsForDeliveryOperations;
            dispatch(
                changeAppParameters({
                    clientConcept,
                    clientConceptsForDetect,
                    clientConceptsForDeliveryPage,
                    clientConceptsForRecovery,
                    clientConceptsForDeliveryOperations,
                    businessUnitId,
                    fromDate,
                    date,
                    xtd,
                    businessUnitAggregation
                })
            );
        };
    },

    changeFromDate(fromDate: Date | null): AppThunkAction<ApplicationAction> {
        return (dispatch: (action: ApplicationAction) => void) => {
            dispatch(changeFromDate({fromDate}));
        };
    },

    changeDate(date: Date): AppThunkAction<ApplicationAction> {
        return (dispatch: (action: ApplicationAction) => void) => {
            dispatch(changeDate({date}));
        };
    },

    changeXtd(xtd: Xtd): AppThunkAction<ApplicationAction> {
        return (dispatch: (action: ApplicationAction) => void) => {
            dispatch(changeXtd({xtd}));
        };
    },

    changeBusinessUnitAggregation(
        businessUnitAggregation: BusinessUnitAggregation
    ): AppThunkAction<ApplicationAction> {
        return (dispatch: (action: ApplicationAction) => void) => {
            dispatch(changeBusinessUnitAggregation({businessUnitAggregation}));
        };
    },

    changeClientConcept(
        clientConcept: IClientConcept
    ): AppThunkAction<ApplicationAction> {
        return (dispatch: (action: ApplicationAction) => void, getState) => {
            const clientConceptsForDetect =
                getState().application.availableClientConceptsForCoachDetect;
            const clientConceptsForDeliveryPage =
                getState().application.availableClientConceptsForCoachDeliveryPage;
            const clientConceptsForRecovery =
                getState().application.availableClientConceptsForCoachRecovery;
            const clientConceptsForDeliveryOperations =
                getState().application.availableClientConceptsForDeliveryOperations;
            dispatch(
                changeClientConcept({
                    clientConcept,
                    clientConceptsForDetect,
                    clientConceptsForDeliveryPage,
                    clientConceptsForRecovery,
                    clientConceptsForDeliveryOperations
                })
            );
        };
    },

    saveUserSettings(
        userSettings: IUserSettings,
        callback?: () => void
    ): AppThunkAction<ApplicationAction> {
        return (dispatch, getState) => {
            dispatch(saveUserSettings({}));

            applicationAPI
                .saveUserSettings(userSettings)
                .then((data) => {
                    dispatch(confirmUserSettings({userSettings: data}));
                    if (callback) {
                        callback();
                    }
                })
                .catch((err) => {
                    dispatch(userSettingsError({errorMessage: err.message}));
                });
        };
    },

    switchCurrentSelectedBusinessUnit(
        businessUnitId: string
    ): AppThunkAction<ApplicationAction> {
        return (dispatch: (action: ApplicationAction) => void) => {
            dispatch(switchCurrentSelectedBusinessUnit({businessUnitId}));
        };
    },

    getOpenTaskCount(): AppThunkAction<ApplicationAction> {
        return (dispatch: (action: ApplicationAction) => void) => {
            tasksAPI.fetchOpenTaskCount().then((openTaskCount) => {
                dispatch(getOpenTaskCount({openTaskCount}));
            });
        };
    },

    requestDomainSettings(): AppThunkAction<ApplicationAction> {
        return (dispatch) => {
            applicationAPI
                .getDomainSettings()
                .then((data) => {
                    dispatch(receiveDomainSettings({domainSettings: data}));
                })
                .catch((err) => {
                    dispatch(errorDomainSettings({errorMessage: err.message}));
                });
        };
    },

    handleFreemiumMarketingModal(freemiumMarketingModalOpen: boolean) {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        return (dispatch: (action: ApplicationAction) => void) => {
            dispatch(
                handleFreemiumMarketingModal({
                    freemiumMarketingModalOpen: freemiumMarketingModalOpen
                })
            );
        };
    },

    handleScreenResize(screenWidth: number): AppThunkAction<ApplicationAction> {
        return (dispatch: (action: ApplicationAction) => void) => {
            dispatch(
                handleScreenResize({
                    screenWidth: screenWidth
                })
            );
        };
    },

    handleMetricConfigurationSaveButton(saveButtonClicked: boolean) {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        return (dispatch: (action: ApplicationAction) => void) => {
            dispatch(
                handleMetricConfigurationSaveButton({
                    saveButtonClicked: saveButtonClicked
                })
            );
        };
    },

    getClientConceptPages(
        callback?: () => void,
        callbackWhenFailed?: () => void
    ): AppThunkAction<ApplicationAction> {
        return (dispatch: (action: ApplicationAction) => void) => {
            applicationAPI
                .getClientConceptPages()
                .then((pages) => {
                    dispatch(getClientConceptPages({clientConceptPages: pages}));
                    if (callback) {
                        callback();
                    }
                })
                .catch(() => {
                    if (callbackWhenFailed) {
                        callbackWhenFailed();
                    }
                });
        };
    },

    changeDeliveryProviderPayoutDatesForBusinessUnit(
        deliveryProviderPayoutDatesForBusinessUnit: IDeliveryProviderPayoutDate[]
    ) {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        return (dispatch: (action: ApplicationAction) => void) => {
            dispatch(
                changeDeliveryProviderPayoutDatesForBusinessUnit({
                    deliveryProviderPayoutDatesForBusinessUnit:
                        deliveryProviderPayoutDatesForBusinessUnit
                })
            );
        };
    }
};

import {FC, useState} from "react";
import {Button, Table, Icon} from "semantic-ui-react";
import {
    DataStatus,
    IClientConceptMetricDefinition
} from "../../api/applicationModels";
import {difference} from "lodash";
import uiConfigurationApi from "features/configuration/api/uiConfigurationAPI";
import MetricAliasFormRow from "./MetricAliasFormRow";
import {connect, ConnectedProps} from "react-redux";
import {actionCreators} from "features/application/state/actions";
import {SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
//this interface exists just to provide type safety as we pass around the form values
//and map them to other objects
export interface IClientConceptMetricDefinitionForm {
    clientConceptMetricDefinitions: IClientConceptMetricDefinition[];
}

//import MetricDefinitionContainer from "./components/MetricDefinitionContainer";
interface IProps extends IReduxProps {
    clientConcept_PK: number;
    clientConceptMetricDefinitions: IClientConceptMetricDefinition[];
}

const MetricAliasForm: FC<IProps> = ({
    clientConcept_PK,
    clientConceptMetricDefinitions,
    handleMetricConfigurationSaveButton
}) => {
    const [saveConfigurationStatus, setSaveConfigurationStatus] =
        useState<DataStatus>(DataStatus.Undefined);

    const defaultValues = {clientConceptMetricDefinitions};
    const saveClientConceptMetricDefinitions = async (
        clientConcept_PK: number,
        metricDefinitions: IClientConceptMetricDefinition[]
    ) => {
        setSaveConfigurationStatus(DataStatus.Loading);
        await uiConfigurationApi
            .saveClientConceptMetricDefinitions(clientConcept_PK, metricDefinitions)
            .then((clientConceptMetricDefinitions) => {
                setSaveConfigurationStatus(DataStatus.Loaded);

                //"Reset" the form to have the current configuration after a successful save
                //This will trigger a re-render of the form
                reset({clientConceptMetricDefinitions});

                // On succesful change, dispatch the save button click. This will force components to reload
                // and then change it back right away
                handleMetricConfigurationSaveButton(true);
                handleMetricConfigurationSaveButton(false);
            })
            .catch(async (error) => {
                setSaveConfigurationStatus(DataStatus.Failed);
            });
    };

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: {isSubmitting}
    } = useForm<IClientConceptMetricDefinitionForm>({
        defaultValues
    });

    const onSubmit: SubmitHandler<IClientConceptMetricDefinitionForm> = async (
        values
    ) => {
        //Only use values that actually changed from the intialValues
        const changedValues = difference(
            values.clientConceptMetricDefinitions,
            defaultValues.clientConceptMetricDefinitions
        );

        if (changedValues.length) {
            await saveClientConceptMetricDefinitions(
                clientConcept_PK,
                changedValues
            );
        } else {
            //no changes, but act as if a save happened
            setSaveConfigurationStatus(DataStatus.Loaded);
        }
    };
    const {fields} = useFieldArray({
        control,
        name: "clientConceptMetricDefinitions"
    });

    const columnText = [
        "Metric Category",
        "Id",
        "Metric Code",
        "Alias",
        "Data Type",
        "Decimal Places",
        "Description",
        "Used"
    ];

    return (
        <form
            id="clientConceptMetricDefinitionForm"
            className="ui form"
            translate="no"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="overflow-x-scroll" style={{height: "700px"}}>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {columnText.map((text) => {
                                return (
                                    <Table.HeaderCell key={text}>
                                        {text}
                                    </Table.HeaderCell>
                                );
                            })}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <>
                            {clientConceptMetricDefinitions &&
                            clientConceptMetricDefinitions.length > 0 ? (
                                fields.map(
                                    (
                                        clientConceptMetricDefinition,

                                        index
                                    ) => (
                                        <MetricAliasFormRow
                                            clientConceptMetricDefinition={
                                                clientConceptMetricDefinition
                                            }
                                            index={index}
                                            key={clientConceptMetricDefinition.id}
                                            control={control}
                                            isSubmitting={isSubmitting}
                                            setValue={setValue}
                                        />
                                    )
                                )
                            ) : (
                                <></>
                            )}
                        </>
                    </Table.Body>
                </Table>
            </div>
            <br />
            <Button
                className={"btn btn-blue"}
                type="submit"
                form="clientConceptMetricDefinitionForm"
                disabled={isSubmitting}
                loading={isSubmitting}
                floated={"right"}
            >
                Save
            </Button>
            {saveConfigurationStatus === DataStatus.Loaded && (
                <>
                    {" "}
                    <Button
                        positive
                        floated={"right"}
                        type="button"
                        style={{cursor: "default"}}
                    >
                        <Icon name="checkmark" /> Saved
                    </Button>
                </>
            )}
            {saveConfigurationStatus === DataStatus.Failed && (
                <Button
                    negative
                    floated={"right"}
                    type="button"
                    style={{cursor: "default"}}
                >
                    <Icon name="exclamation circle" /> Save Failed
                </Button>
            )}
            <DevTool control={control} /> {/* set up the dev tool */}
        </form>
    );
};

const connector = connect(() => ({}), actionCreators);

type IReduxProps = ConnectedProps<typeof connector>;

export default connector(MetricAliasForm);

import {Form} from "semantic-ui-react";
import {Controller} from "react-hook-form";
import FormErrorMessage from "../FormErrorMessage";
import {ISemanticReactHookFieldProps} from "../formsModels";

const SemanticHookFormInputField = (
    props: ISemanticReactHookFieldProps
): JSX.Element => {
    const {
        control,
        name,
        label,
        type,
        placeholder,
        disabled,
        setValue,
        style,
        errors,
        errorLabelPointingDirection = "above"
    } = props;

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {ref, ...field}}) => (
                <>
                    <Form.Field>
                        <label htmlFor={name}>{label}</label>
                        <input
                            type={type}
                            placeholder={placeholder}
                            disabled={disabled}
                            style={style}
                            {...field}
                            value={field.value || ""}
                            onChange={(e) => {
                                if (setValue) {
                                    setValue(name, e.target.value, {
                                        shouldDirty: true,
                                        shouldTouch: true,
                                        shouldValidate: true
                                    });
                                }
                            }}
                            onBlur={(e) => {
                                if (setValue) {
                                    setValue(name, e.target.value, {
                                        shouldDirty: true,
                                        shouldTouch: true,
                                        shouldValidate: true
                                    });
                                }
                            }}
                        />
                    </Form.Field>
                    {errors && (
                        <FormErrorMessage
                            name={name}
                            errors={errors}
                            pointing={errorLabelPointingDirection}
                        />
                    )}
                </>
            )}
        />
    );
};
export default SemanticHookFormInputField;

import {FC} from "react";
import {Label} from "semantic-ui-react";
import {ErrorMessage} from "@hookform/error-message";

interface IProps {
    pointing?: boolean | "above" | "below" | "left" | "right" | undefined;
    name: string;
    errors: any;
}
//todo: push the null check on errors into this component.  Currently all the calling code
//needs to do a null check on errors before calling this component, which if left-out causes a pretty obscured error
//which is hard to interpret.
const FormErrorMessage: FC<IProps> = ({pointing, name, errors}) => {
    return (
        <ErrorMessage
            name={name}
            errors={errors}
            render={({message}) => (
                <Label
                    basic
                    color="red"
                    pointing={pointing}
                    className="modal-margin-adjustment"
                >
                    {message}
                </Label>
            )}
        />
    );
};

export default FormErrorMessage;

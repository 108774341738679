import React from "react";
import {Icon, Popup} from "semantic-ui-react";
import dataFormatUtil from "../../../../util/dataFormatUtil";
import {
    IModuleMetricDefinition,
    IMetricThreshold,
    ThresholdType,
    Xtd
} from "../../api/dashboardModels";
import {BusinessUnitAggregation} from "../../../metricDetails/api/metricDetailsModels";
import {IThresholdValue} from "../../../application/api/applicationModels";

const CardThresholdTooltip = (
    thresholdData: IMetricThreshold[],
    metricDefinition: IModuleMetricDefinition[],
    xtds: Xtd[],
    businessUnitAggregation?: BusinessUnitAggregation,
    stylingClass?: string
): JSX.Element => {
    // Gets threshold values and their corresponding metric definitions and returns a div with formatted xtd values
    // Note that there could be one or more values depending if a card has mutliple metrics on it
    const getThresholdXtdValues = (
        thresholdData: IMetricThreshold[],
        metricDefinition: IModuleMetricDefinition[],
        xtd: Xtd
    ) => {
        return (
            <div key={xtd}>
                {thresholdData.map((t, index) => {
                    return (
                        <React.Fragment key={index}>
                            {formatThresholdValue(
                                t.thresholdValues[xtd],
                                metricDefinition[index]
                            )}
                            {index !== thresholdData.length - 1 && "\xA0\x2F\xA0"}
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };

    // Gets a Xtd threshold value and formats it appropriately based on the threshold type
    const formatThresholdValue = (
        thresholdValue: IThresholdValue,
        metricDefinition: IModuleMetricDefinition
    ) => {
        const thresholdMinValueFormatted = dataFormatUtil.dataTableDisplayValue(
            thresholdValue.minValue,
            metricDefinition
        );
        const thresholdMaxValueFormatted = dataFormatUtil.dataTableDisplayValue(
            thresholdValue.maxValue,
            metricDefinition
        );

        switch (thresholdValue.thresholdType) {
            case ThresholdType.WithinRange:
                // WithinRange
                return (
                    <span>
                        {thresholdMinValueFormatted}{" "}
                        <Icon
                            style={{margin: "0px"}}
                            name="arrows alternate horizontal"
                        />{" "}
                        {thresholdMaxValueFormatted}
                    </span>
                );

            case ThresholdType.HigherIsBetter:
                // HigherIsBetter
                return (
                    <span>
                        {thresholdMinValueFormatted}
                        <Icon
                            style={{margin: "0px"}}
                            name="long arrow alternate up"
                        />
                    </span>
                );

            case ThresholdType.LowerIsBetter:
                // LowerIsBetter
                return (
                    <span>
                        {thresholdMaxValueFormatted}
                        <Icon
                            style={{margin: "0px"}}
                            name="long arrow alternate down"
                        />
                    </span>
                );

            default:
                // default
                return thresholdMinValueFormatted;
        }
    };

    // Renders all Xtds and their threshold values for one or more metrics (a card can have multiple metrics on it)
    const renderThresholdContent = (
        thresholdData: IMetricThreshold[],
        metricDefinitions: IModuleMetricDefinition[],
        xtdsOnCards: Xtd[]
    ) => {
        const thresholdDescription =
            thresholdData[0] && thresholdData[0].description ? (
                <p>{thresholdData[0].description}</p>
            ) : null;

        return (
            <div>
                <h5>
                    {metricDefinitions[0].metricOrderCategory ||
                        metricDefinitions.map((m) => m.metricName).join(" / ")}{" "}
                    Threshold
                </h5>
                {thresholdDescription}
                <div className="content-box">
                    {xtdsOnCards.map((xtd) => {
                        return <div key={xtd}>{xtd.toString() + ":  "}</div>;
                    })}
                </div>
                <div className="content-box padding-left-10px">
                    {xtdsOnCards.map((xtd) => {
                        return getThresholdXtdValues(
                            thresholdData,
                            metricDefinitions,
                            xtd
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <Popup
            trigger={
                <Icon
                    name="help circle"
                    size="small"
                    color="grey"
                    corner
                    className={stylingClass ? stylingClass : "help-icon-position"}
                />
            }
            content={
                <>
                    {renderThresholdContent(thresholdData, metricDefinition, xtds)}{" "}
                    {businessUnitAggregation ===
                    BusinessUnitAggregation.Locations ? (
                        <div style={{marginTop: "10px", fontSize: "small"}}>
                            Note: This threshold may be different for individual
                            locations.
                        </div>
                    ) : null}{" "}
                </>
            }
            position="bottom center"
        />
    );
};

export default CardThresholdTooltip;

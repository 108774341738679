import {Xtd} from "../features/dashboard/api/dashboardModels";

export function getXtdDisplayText(xtd: Xtd): string {
    if (xtd === Xtd.DAILY) {
        return "Daily";
    } else if (xtd === Xtd.CUSTOM) {
        return "Custom";
    } else {
        return xtd.toString();
    }
}

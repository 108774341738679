import {IClientConceptMetricDefinition} from "features/application/api/applicationModels";
import {
    IMetricDefinition,
    IModuleMetricDefinition
} from "features/dashboard/api/dashboardModels";
import apiUtil from "../../../util/apiUtil";
import {IPageModuleConfiguration} from "./uiConfigurationModels";

const uiConfigurationApi = {
    async getPageModules(
        clientConcept_PK: number,
        page_PK: number,
        abortSignal?: AbortSignal
    ): Promise<IPageModuleConfiguration[]> {
        return await apiUtil.fetchData(
            `/api/uiconfiguration/ClientConceptPageModules/${clientConcept_PK}/${page_PK}`,
            abortSignal
        );
    },

    async getClientConceptMetricDefinitions(
        clientConcept_PK: number
    ): Promise<IClientConceptMetricDefinition[]> {
        return await apiUtil.fetchData(
            `/api/metricConfiguration/ClientConceptMetricDefinitions/${clientConcept_PK}`
        );
    },

    async saveClientConceptMetricDefinitions(
        clientConcept_PK: number,
        definitions: IClientConceptMetricDefinition[]
    ): Promise<IClientConceptMetricDefinition[]> {
        return await apiUtil.postData(
            `/api/metricConfiguration/ClientConceptMetricDefinitions/${clientConcept_PK}`,
            definitions
        );
    },

    async deleteMonitor(monitorId: number): Promise<Response> {
        return await apiUtil.deleteData<Response>(`/api/monitors/${monitorId}`);
    },

    async getClientConceptPageModuleMetricConfiguration(
        clientConcept_PK: number
    ): Promise<IModuleMetricDefinition[]> {
        return await apiUtil.fetchData(
            `/api/metricConfiguration/ClientConceptPageModuleMetrics/${clientConcept_PK}`
        );
    },

    async getMetricDefinitions(): Promise<IMetricDefinition[]> {
        return await apiUtil.fetchData("/api/metricConfiguration/MetricDefinitions");
    },

    async saveClientConceptPageModuleMetrics(
        clientConcept_PK: number,
        moduleMetrics: IModuleMetricDefinition[]
    ): Promise<IModuleMetricDefinition[]> {
        return await apiUtil.postData(
            `/api/metricConfiguration/ClientConceptPageModuleMetrics/${clientConcept_PK}`,
            moduleMetrics
        );
    },

    async deleteClientConceptPageModuleMetric(
        clientConcept_PK: number,
        clientConceptPageModuleMetricId: number
    ): Promise<any> {
        return await apiUtil.deleteData<Response>(
            `/api/metricConfiguration/ClientConceptPageModuleMetrics/${clientConcept_PK}/${clientConceptPageModuleMetricId}`
        );
    }
};

export default uiConfigurationApi;

import {Image, Segment} from "semantic-ui-react";
import logo from "../img/logo-delaget.svg";

export default function DelageFooter(): JSX.Element {
    return (
        <Segment inverted attached className="footer">
            <Image
                src={logo}
                size="tiny"
                style={{marginLeft: "1.5em", height: 60}}
            />
        </Segment>
    );
}

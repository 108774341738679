import {applyMiddleware, compose, createStore, Store} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./";
import {AppState} from "./";
import * as Sentry from "@sentry/react";

function configureStore(): Store<AppState, any> {
    // We need this so we can use the Chrome Redux dev tools
    const composeEnhancers =
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

    // I"m not sure if we are actually using thunk, but I think we are in the async action creator
    const enhancer = composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer);

    return createStore(rootReducer(), enhancer);
}
const store = configureStore();
export default store;

import moment from "moment";
import {
    IEmployeeSummaryData,
    ILocationDetailData,
    ILocationSummaryData,
    ITransactionDetailData
} from "features/monitors/api/monitorDetails/monitordetailsModels";
import {keys} from "lodash";
import {
    Concept,
    IBusinessUnit,
    IClientConceptMetricDefinition,
    IColumnMetadata,
    IDataTableData
} from "../../application/api/applicationModels";
import {
    IBusinessUnitMetricData,
    IModuleMetricDefinition,
    StaticColumnKey,
    Xtd,
    IMetricThreshold
} from "../../dashboard/api/dashboardModels";
import {defaultLegacySiteBaseUrl} from "util/apiEndPointUtil";
import {PageCode} from "features/configuration/api/uiConfigurationModels";

// Sets the maximum number of days for custom date range (applicable to metric details pages)
export const maximumCustomDateRange = 84;

export interface IMetricDetailsRequest {
    clientConcept_PK: number;
    businessUnit: IBusinessUnit;
    page_PK: number;
    pageCode: PageCode;
    fromDate: Date | null;
    date: Date;
    calendarId: number;
    businessUnitAggregation: BusinessUnitAggregation;
    xtd: Xtd;
    concept: Concept;
    abortSignal?: AbortSignal;
}
export interface IMetricDetailsForMetricCodesRequest {
    clientConcept_PK: number;
    businessUnitId: string;
    fromDate: Date | null;
    date: Date;
    calendarId: number;
    businessUnitAggregation: BusinessUnitAggregation;
    xtd: Xtd;
    concept: Concept;
    metricCodes: string[];
    abortSignal?: AbortSignal;
}

export interface IMetricDetailsDataResult {
    metricDefinitions: IModuleMetricDefinition[];
    metricData: IBusinessUnitMetricData[];
    metricThresholds: IMetricThreshold[];
}

// we map data as BusinessUnit Aggregation --> XTD Aggregation --> MetricData
export interface IXtdAggregationMetricData {
    [Xtd.DAILY]: IMetricDetailsDataResult;
    [Xtd.WTD]: IMetricDetailsDataResult;
    [Xtd.PTD]: IMetricDetailsDataResult;
    [Xtd.QTD]: IMetricDetailsDataResult;
    [Xtd.YTD]: IMetricDetailsDataResult;
    [Xtd.CUSTOM]: IMetricDetailsDataResult;
}

export interface IBusinessUnitXtdAggregationMetricData {
    [BusinessUnitAggregation.Locations]: IXtdAggregationMetricData;
    [BusinessUnitAggregation.Areas]: IXtdAggregationMetricData;
    [BusinessUnitAggregation.NonAreaGroups]: IXtdAggregationMetricData;
}

export enum DrilldownType {
    None = "None",
    VOCDrilldown = "VOCDrilldown",
    TransactionDrilldown = "TransactionDrilldown",
    InventoryDetailsDrilldown = "InventoryDetailsDrilldown",
    DeliveryTransactionDrilldown = "DeliveryTransactionDrilldown"
}

export interface IDrilldownDataRequest {
    businessUnit: IBusinessUnit;
    metricCode: string;
    metricName: string;
    drilldownType: DrilldownType;
}

export interface IVocSurvey {
    locationId: string;
    locationPk: number;
    locationName: string;
    locationNumber: string;
    date: Date;
    ticketNumber: number;
    surveyId: number;
    orderDate: Date;
    surveyDate: Date;
    channel: string;
    osatAnswer: string;
    osatCategory: OsatCategory;
    surveyQuestions: ISurveyQuestion[];
}

export interface ISurveyQuestion {
    question: string;
    answer: string;
    sortOrder: number;
}

// Used to support tab functionality on drill-down pages
// It is convenient to use a string enum because the strings are used to access object properties
export enum BusinessUnitAggregation {
    Unknown = "",
    Locations = "locations",
    Areas = "areas",
    NonAreaGroups = "nonAreaGroups"
}

// These should match what is defined in MetricCode.cs
export enum MetricCode {
    Unknown = "Unknown",
    NetSales = "NetSalesAmount",
    SurveysReceived = "SurveysReceived",
    SatisfiedRatio = "SurveySatisfiedRatio",
    SatisfiedCount = "SurveySatisfiedCount",
    SatisfiedRatioAfter5pm = "SurveySatisfiedRatioAfter5PM",
    SatisfiedCountAfter5pm = "SurveySatisfiedCountAfter5PM",
    DissatisfiedRatio = "SurveyDissatisfiedRatio",
    DissatisfiedCount = "SurveyDissatisfiedCount",
    DissatisfiedRatioAfter5pm = "SurveyDissatisfiedRatioAfter5PM",
    DissatisfiedCountAfter5pm = "SurveyDissatisfiedCountAfter5PM",
    DissatisfiedRatioBefore5pm = "SurveyDissatisfiedRatioBefore5PM",
    DissatisfiedCountBefore5pm = "SurveyDissatisfiedCountBefore5PM",
    DriveThruSOS = "DriveThruSOS",
    GrubhubDeliveryTransactionCount = "GrubhubDeliveryTransactionCount",
    DoorDashDeliveryTransactionCount = "DoorDashDeliveryTransactionCount"
}

export enum OsatCategory {
    HighlyPositive = "Highly Positive",
    Neutral = "Neutral",
    Negative = "Negative"
}

export enum CheckboxStatus {
    Unchecked = "Unchecked",
    Indeterminate = "Indeterminate",
    Checked = "Checked"
}

export interface IThresholdFilters {
    [metricCode: string]: ThresholdContext[];
}

export enum ThresholdContext {
    Unknown = "Unknown",
    Good = "Good",
    Bad = "Bad",
    NotReporting = "NotReporting"
}

export interface IDrilldownTransactionDetailsViewModel {
    staticColumnsMetaData: IColumnMetadata[];
    metricDefinitions: IClientConceptMetricDefinition[];
    transactionDetailData: ITransactionDetailData[];
}

export function combineStaticAndConfiurableDataForDataTable(
    resultData:
        | ITransactionDetailData[]
        | ILocationDetailData[]
        | ILocationSummaryData[]
        | IEmployeeSummaryData[],
    metricDefinitions: IColumnMetadata[]
): IDataTableData[] {
    const combinedResultsData: IDataTableData[] = [];

    for (let i = 0; i < resultData.length; i++) {
        combinedResultsData.push({});

        // Get data for all non-configurable metric properties
        // Note that there is more data needed than what is rendered in the results table
        // Other data is needed for custom columns, etc.
        const propertyKeys = keys(resultData[i]);
        for (let j = 0; j < propertyKeys.length; j++) {
            if (propertyKeys[j] === "metricData") {
                continue;
            }

            const staticMetric = propertyKeys[j];
            const value = resultData[i][staticMetric];

            combinedResultsData[i][staticMetric] = value;
        }

        for (let k = 0; k < metricDefinitions.length; k++) {
            const configurableMetric = metricDefinitions[k];
            const value = resultData[i].metricData[configurableMetric.key];

            combinedResultsData[i][configurableMetric.key] = value;
        }
    }

    return combinedResultsData;
}

export function openTicket(
    orderDate: string,
    locationPk: string,
    ticketNumber: string,
    legacySiteBaseUrl: string
): void {
    let url = legacySiteBaseUrl || defaultLegacySiteBaseUrl;

    //Convert date time to date format needed by legacy url
    const businessDate = moment(orderDate).format("MM/DD/YYYY");
    url +=
        "/CashMgmt/DrillDown_Ticket.aspx?LID=" +
        locationPk +
        "&B=" +
        businessDate +
        "&TN=" +
        ticketNumber;
    window.open(
        url,
        "_blank",
        "location=no,toolbar=no,menubar=yes,status=no,resizable=yes,scrollbars=yes,height=600,width=700,top=30,left=50"
    );
}

const metricData = {
    metricData: [],
    metricDefinitions: [],
    metricThresholds: []
};
const xtdMetricData = {
    [Xtd.DAILY]: metricData,
    [Xtd.WTD]: metricData,
    [Xtd.PTD]: metricData,
    [Xtd.QTD]: metricData,
    [Xtd.YTD]: metricData,
    [Xtd.CUSTOM]: metricData
};
export const BusinessUnitXtdAggregationMetricDataInit: IBusinessUnitXtdAggregationMetricData =
    {
        [BusinessUnitAggregation.Locations]: xtdMetricData,
        [BusinessUnitAggregation.Areas]: xtdMetricData,
        [BusinessUnitAggregation.NonAreaGroups]: xtdMetricData
    };

export function getStaticColumnHeaders(
    businessUnitAggregationLevel: BusinessUnitAggregation
): IColumnMetadata[] {
    const staticHeaders: IColumnMetadata[] = [];

    switch (businessUnitAggregationLevel) {
        case BusinessUnitAggregation.Locations:
            staticHeaders.push({
                label: "Restaurant",
                key: StaticColumnKey.BusinessUnitKey
            });
            staticHeaders.push({
                label: "Area",
                key: StaticColumnKey.ParentBusinessUnitKey
            });
            break;
        case BusinessUnitAggregation.Areas:
            staticHeaders.push({
                label: "Area",
                key: StaticColumnKey.BusinessUnitKey
            });
            break;
        case BusinessUnitAggregation.NonAreaGroups:
            staticHeaders.push({
                label: "Group",
                key: StaticColumnKey.BusinessUnitKey
            });
            break;
    }
    return staticHeaders;
}

export const numberOfTransactionsLimit = 5000;

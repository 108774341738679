import apiUtil from "../../../util/apiUtil";
import {IRootGroupInvalidLocations} from "./configurationModels";

// functions here should use fetch to request data from the api
// and return objects that are defined in configurationModels.ts

const configurationApi = {
    // returns a list of locations that have an invalid configuration.
    async checkForValidGroupTree(
        groupId: string
    ): Promise<IRootGroupInvalidLocations> {
        return await apiUtil.fetchData(
            `/api/configuration/ValidateGroupIdSetup/${groupId}`
        );
    }
};

export default configurationApi;

import {round, sum} from "lodash";
import {PrettyTimeSpanSegment} from "./prettyTimeSpanSegment";

export class PrettyTimeSpan {
    constructor(
        days: number | undefined,
        hours: number | undefined,
        minutes: number | undefined,
        seconds: number | undefined
    ) {
        this.segments = [];

        if (days !== undefined && days > 0) {
            this.segments.push(new PrettyTimeSpanSegment(days, "d"));
        }

        if (hours !== undefined && hours > 0) {
            this.segments.push(new PrettyTimeSpanSegment(hours, "h"));
        }

        if (minutes !== undefined && minutes > 0) {
            this.segments.push(new PrettyTimeSpanSegment(minutes, "m"));
        }

        if (seconds !== undefined && seconds > 0) {
            this.segments.push(new PrettyTimeSpanSegment(seconds, "s"));
        }

        if (this.segments.length === 0) {
            this.segments.push(new PrettyTimeSpanSegment(0, "s"));
        }
    }

    public segments: PrettyTimeSpanSegment[];

    public toSeconds(): number {
        return sum(this.segments.map((s) => s.toSeconds()));
    }

    public toString(maxSegments = 3): string {
        let displayValue = "";
        let appendedSegmentCount = 0;
        //Take the first 3 displayable segments.
        for (let i = 0; i < this.segments.length; i++) {
            const segment = this.segments[i];

            //If the segment is 0 length, don't add it to the display value
            if (appendedSegmentCount < maxSegments && segment.value !== 0) {
                displayValue = `${displayValue} ${segment}`;
                appendedSegmentCount++;
            }
        }

        if (displayValue.length === 0) {
            return new PrettyTimeSpanSegment(0, "s").toString();
        }

        return displayValue.trim();
    }

    static fromSeconds(value: number): PrettyTimeSpan {
        const totalSeconds = round(value, 0);
        if (totalSeconds === 0) {
            return new PrettyTimeSpan(undefined, undefined, undefined, 0);
        }

        let seconds = totalSeconds;
        let minutes = 0;
        let hours = 0;
        let days = 0;
        if (seconds >= 60) {
            minutes = Math.floor(seconds / 60);
            seconds = seconds % 60;
            if (minutes >= 60) {
                hours = Math.floor(minutes / 60);
                minutes = minutes % 60;
                if (hours >= 24) {
                    days = Math.floor(hours / 24);
                    hours = hours % 24;
                }
            }
        }

        return new PrettyTimeSpan(days, hours, minutes, seconds);
    }

    static parse(value: string): PrettyTimeSpan | undefined {
        const stringSegments = value.split(" ");
        const parsedSegments = stringSegments
            .map((string) => PrettyTimeSpanSegment.parse(string))
            .filter((segment) => segment !== undefined);

        const days = parsedSegments.find((segment) => segment?.unit === "d")?.value;
        const hours = parsedSegments.find((segment) => segment?.unit === "h")?.value;
        const minutes = parsedSegments.find(
            (segment) => segment?.unit === "m"
        )?.value;
        const seconds = parsedSegments.find(
            (segment) => segment?.unit === "s"
        )?.value;

        return new PrettyTimeSpan(days, hours, minutes, seconds);
    }
}

import {authenticationFailure} from "features/authentication/state/actions";
import ReactGA from "react-ga";
import store from "state/store";
import {ErrorMessageContent} from "./errorUtil";
import * as Sentry from "@sentry/react";
import * as QueryString from "query-string";
import {UrlParams} from "./appParamsUtil";
import {dateToString} from "./dateUtil";
import {IProblemDetails} from "features/application/api/applicationModels";

const apiUtil = {
    // helper function for posting JSON data
    async postData<T>(url = "", data: any = {}, signal?: AbortSignal): Promise<T> {
        const startTimestamp = Date.now();
        const res = fetch(url, {
            credentials: "same-origin",
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            signal
        });

        logApiRequestTiming(url, startTimestamp);
        return validateResponse(res);
    },
    async putData<T>(url = "", data: any = {}): Promise<T> {
        const startTimestamp = Date.now();
        const res = fetch(url, {
            credentials: "same-origin",
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        });

        logApiRequestTiming(url, startTimestamp);
        return validateResponse(res);
    },

    async fetchData<T>(url: string, signal?: AbortSignal): Promise<T> {
        const startTimestamp = Date.now();
        const res = fetch(url, {
            credentials: "same-origin",
            method: "GET",
            // Added this because IE is caching API GET calls if the request is the same.
            // This can cause behavior like if you log in as client X and then client Y, if you make an API call which is
            // the same as a call you made for client X, then you will get results for X instead
            headers: {
                Pragma: "no-cache"
            },
            signal: signal
        });

        logApiRequestTiming(url, startTimestamp);
        return validateResponse(res);
    },

    async deleteData<T>(url: string): Promise<T> {
        const startTimestamp = Date.now();
        const res = fetch(url, {
            credentials: "same-origin",
            method: "DELETE",
            // Added this because IE is caching API GET calls if the request is the same.
            // This can cause behavior like if you log in as client X and then client Y, if you make an API call which is
            // the same as a call you made for client X, then you will get results for X instead
            headers: {
                Pragma: "no-cache"
            }
        });

        logApiRequestTiming(url, startTimestamp);
        return validateResponse(res);
    },

    async fetchDataWithCredentials<T>(url: string): Promise<T> {
        const startTimestamp = Date.now();
        const res = fetch(url, {
            credentials: "include",
            //the option "Pragma": "no-cache", will break the CORS requests
            //which is needed for the dev-env sideBarMenu API call.
            cache: "no-cache"
        });

        logApiRequestTiming(url, startTimestamp);
        return validateResponse(res);
    }
};

// fetch function is not designed to handle errors automatically, but it has a flag 'ok' which can be used to check for errors.
// Also, it has flag status which should hold the error type, i.e. 401, etc.
export async function validateResponse<T>(response: Promise<Response>): Promise<T> {
    return response.then(async (res) => {
        if (res.ok) {
            //Not all response from the back-end API comeback as json, for example something like "return Ok()" from the
            //backend will have a null content-type and calling .json() on it throws an error
            const contentType = res.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return res.json();
            } else if (contentType && contentType.indexOf("text/html") !== -1) {
                return res.text();
            } else {
                return res.ok;
            }
        }
        // If we get 401 back, then dispatch authentication failure. This is needed in ProtectedRoute component.
        else if (res.status === 401) {
            const errorResponse: IProblemDetails = await res.json();
            store.dispatch(
                authenticationFailure({
                    error:
                        errorResponse.title || ErrorMessageContent.NotAuthenticated
                })
            );

            throw errorResponse;
        } else {
            //sending or constructing an object that Sentry will consume and not treat as [object Promise] proved difficult, therefore
            //we decided to construct a basic string and send that.
            const errorResponse: IProblemDetails = await res.json();
            Sentry.captureException(
                `Status Code: ${res.status}` +
                    `\nReponse URL: ${res.url}` +
                    `\nErrorMessageText: ${JSON.stringify(errorResponse)}`
            );
            throw errorResponse;
        }
    });
}

export function logApiRequestTiming(url: string, startTimestamp: number): void {
    // URL has the pattern as /api/{controllerName}/{actionName}/{...}
    // We will log the {controllerName}/{actionName} as the 'label' to GA
    // so we can easily segregate the API performance data by each controller/action method.
    const urlParsedArray = url.split("/");
    ReactGA.timing({
        category: "API Request",
        variable: url,
        value: Date.now() - startTimestamp,
        label: `${urlParsedArray[2]}/${urlParsedArray[3]}`
    });
}

export function getFromDateQueryStringParameter(
    fromDate: Date | null,
    queryStringCharacter: string
): string {
    let fromDateParam = "";
    if (fromDate) {
        fromDateParam =
            queryStringCharacter +
            QueryString.stringify({
                [UrlParams.FromDate]: dateToString(fromDate)
            });
    }

    return fromDateParam;
}

export default apiUtil;

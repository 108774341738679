import {Action} from "redux";
import {actionCreator, AppThunkAction} from "../../../state/actions";
import {MonitorInteraction} from "../api/monitorsModels";
export const HANDLE_MONITOR_ACTION = "handle/HANDLE_MONITOR_ACTION";

interface IHandleMonitorAction extends Action<typeof HANDLE_MONITOR_ACTION> {
    monitorActionTaken: MonitorInteraction;
}

export const handleMonitorAction =
    actionCreator<IHandleMonitorAction>(HANDLE_MONITOR_ACTION);

export type MonitorAction = IHandleMonitorAction;

export const actionCreators = {
    handleMonitorAction(
        monitorActionTaken: MonitorInteraction
    ): AppThunkAction<MonitorAction> {
        return (dispatch: (action: MonitorAction) => void) => {
            dispatch(
                handleMonitorAction({
                    monitorActionTaken: monitorActionTaken
                })
            );
        };
    }
};

import * as React from "react";
import {Icon, Placeholder, Segment, SemanticCOLORS} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {
    IThresholdFilters,
    ThresholdContext
} from "../../../metricDetails/api/metricDetailsModels";
import {MetricDetailsUrlModel, UrlParams} from "util/appParamsUtil";
import {IModuleMetricDefinition} from "features/dashboard/api/dashboardModels";

interface IHouseContent {
    metricDetailsUrlModel: MetricDetailsUrlModel;
    metricDefinition: IModuleMetricDefinition;
    thresholdContext: ThresholdContext;
    hasDrilldown: boolean;
    numberOfLocationsForThreshold: number | null | undefined;
    hasAccessToFullVersionPage: boolean;
    handleFreemiumMarketingModal: (freemiumMarketingModalOpen: boolean) => void;
}

export default function HouseContent(props: IHouseContent): JSX.Element {
    const [hover, handleHover] = React.useState(false);

    const isHousesDataReady =
        props.numberOfLocationsForThreshold === null ||
        props.numberOfLocationsForThreshold === undefined
            ? false
            : true;

    let thresholdWord: string;
    let houseColor: SemanticCOLORS;
    let houseColorHover: string;

    if (props.thresholdContext === ThresholdContext.Good) {
        houseColor = "green";
        houseColorHover = "#026854";
        thresholdWord = "within threshold";
    } else if (props.thresholdContext === ThresholdContext.Bad) {
        houseColor = "red";
        houseColorHover = "#8E0314";
        thresholdWord = "violating threshold";
    } else {
        houseColor = "grey";
        houseColorHover = "#303030";
        thresholdWord = "not reporting";
    }

    const labelStyle = {
        color: "#112459",
        display: "inline-block",
        width: "80%"
    };
    const labelStyleHover = {
        color: "#112459",
        display: "inline-block",
        width: "80%",
        fontWeight: "bold"
    };

    const iconStyle = {
        verticalAlign: "bottom"
    };
    const iconStyleHover = {
        verticalAlign: "bottom",
        color: houseColorHover
    };

    const thresholdFilters: IThresholdFilters = {
        [props.metricDefinition.metricCode]: [props.thresholdContext]
    };

    const drilldownURL = props.metricDetailsUrlModel.generateUrlWithParameters([
        {
            code: UrlParams.ThresholdFilters,
            value: JSON.stringify(thresholdFilters)
        }
    ]);

    return (
        <Segment
            style={{padding: "0px", margin: "0px"}}
            basic
            as={
                props.hasDrilldown && props.hasAccessToFullVersionPage
                    ? Link
                    : undefined
            }
            to={
                !props.hasDrilldown || !props.hasAccessToFullVersionPage
                    ? undefined
                    : drilldownURL
            }
            onClick={
                props.hasDrilldown && !props.hasAccessToFullVersionPage
                    ? (e: React.MouseEvent<HTMLElement>) => {
                          e.preventDefault();
                          props.handleFreemiumMarketingModal(true);
                      }
                    : undefined
            }
            onMouseEnter={() => {
                handleHover(!hover);
            }}
            onMouseLeave={() => {
                handleHover(!hover);
            }}
        >
            {!isHousesDataReady ? (
                <Placeholder style={labelStyle}>
                    <Placeholder.Line />
                </Placeholder>
            ) : (
                <div style={hover ? labelStyleHover : labelStyle}>
                    <Icon
                        name="home"
                        style={hover ? iconStyleHover : iconStyle}
                        color={hover ? undefined : houseColor}
                        size="large"
                    />{" "}
                    {props.numberOfLocationsForThreshold} {thresholdWord}
                </div>
            )}
        </Segment>
    );
}

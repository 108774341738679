export class PrettyTimeSpanSegment {
    constructor(public value: number, public unit: string) {}

    public toSeconds(): number | undefined {
        const formattedUnit = this.unit.toLowerCase();
        switch (formattedUnit) {
            case "s":
                return this.value;
            case "m":
                return this.getMinuteSeconds(this.value);
            case "h":
                return this.getHourSeconds(this.value);
            case "d":
                return this.getDaySeconds(this.value);
            default:
                return undefined;
        }
    }

    public toString(): string {
        return `${this.value}${this.unit}`;
    }

    static parse(value: string): PrettyTimeSpanSegment | undefined {
        const segmentStringRegex = new RegExp("[0-9]+[smhd]");
        const matches = value.match(segmentStringRegex);
        if (!matches || matches.length > 1) {
            return undefined;
        }

        const unitAbbreviationRegex = new RegExp("[smhd]");
        const unitAbbreviation = value.match(unitAbbreviationRegex);
        if (!unitAbbreviation) {
            return undefined;
        }

        const numberRegex = new RegExp("[0-9]+");
        const rawNumberValue = value.match(numberRegex);
        if (!rawNumberValue) {
            return undefined;
        }

        return new PrettyTimeSpanSegment(
            parseInt(rawNumberValue[0]),
            unitAbbreviation[0]
        );
    }

    getDaySeconds(days: number): number {
        const hours = days * 24;

        return this.getHourSeconds(hours);
    }

    getHourSeconds(hours: number): number {
        const minutes = hours * 60;

        return this.getMinuteSeconds(minutes);
    }

    getMinuteSeconds(minutes: number): number {
        const seconds = minutes * 60;

        return seconds;
    }
}

import {FC} from "react";
import {Button, Modal, SemanticFLOATS, Tab} from "semantic-ui-react";
import MetricAliasConfiguration from "./MetricAliasConfiguration";
import ModuleMetricConfiguration from "./ModuleMetricConfiguration";

interface IProps {
    page_PK: number;
    style?: {[key: string]: string};
    floated?: SemanticFLOATS; // determines position of the trigger button
}

// Coach Metric Configuration Modal
const MetricConfigurationModal: FC<IProps> = ({page_PK, style, floated}) => {
    return (
        <Modal
            className="modal-box"
            closeIcon
            size={"fullscreen"}
            closeOnDimmerClick={true}
            closeOnEscape={true}
            trigger={
                <Button
                    style={style}
                    className="btn btn-blue-solid"
                    content="Configuration"
                    primary
                    floated={floated}
                    type="button" //type needs to be button otherwise it defaults to submit behavior
                />
            }
        >
            <Modal.Header>Configuration</Modal.Header>
            <Modal.Content>
                {
                    <Tab
                        renderActiveOnly={false}
                        menu={{attached: false}}
                        panes={[
                            {
                                menuItem: "Metric Configurator",
                                pane: (
                                    <Tab.Pane
                                        key={"metricConfigurator"}
                                        attached={false}
                                    >
                                        <ModuleMetricConfiguration
                                            page_PK={page_PK}
                                        />
                                    </Tab.Pane>
                                )
                            },
                            {
                                menuItem: "Metric Aliases",
                                pane: (
                                    <Tab.Pane key={"metricAliases"} attached={false}>
                                        <MetricAliasConfiguration />
                                    </Tab.Pane>
                                )
                            }
                        ]}
                    />
                }
            </Modal.Content>
        </Modal>
    );
};

export default MetricConfigurationModal;

import * as React from "react";
import {
    Container,
    Dimmer,
    Header,
    Loader,
    Icon,
    Segment,
    Message,
    SemanticCOLORS,
    SemanticICONS
} from "semantic-ui-react";
import {ErrorMessageContent as ErrorText} from "../util/errorUtil";

interface IProps {
    inline?: boolean;
    text?: string;
    marginTop?: number;
    size?: "mini" | "tiny" | "small" | "large" | "big" | "huge" | "massive";
    fluid?: boolean;
    color?: SemanticCOLORS;
    iconName?: SemanticICONS;
}

const ErrorContent = (props: IProps) =>
    props.inline ? (
        <>
            <Icon name="frown outline" />
            {props.text ? props.text : ErrorText.SomethingWentWrong}
        </>
    ) : (
        <Header as="h3" icon>
            <Icon name="frown outline" />
            {props.text ? props.text : ErrorText.SomethingWentWrong}
        </Header>
    );

const InfoContent = (props: IProps) => <>{props.text}</>;

const InfoMessageContent = (props: IProps): JSX.Element => (
    <Message
        icon
        color={props.color || "blue"}
        size={props.size ? props.size : "small"}
    >
        <Icon name={props.iconName || "info circle"} />
        <Message.Content>
            <Message.Header>{props.text ? props.text : ""}</Message.Header>
        </Message.Content>
    </Message>
);

const LoadingContent = (props: IProps) => (
    <Dimmer active inverted>
        <Loader content={props && props.text ? props.text : ""} />
    </Dimmer>
);

const NoAccessContent = (props: IProps) => (
    <Header as="h3" icon>
        <Icon name="lock" />
        {props && props.text ? props.text : ""}
    </Header>
);

const NoDataContent = (props: IProps) => (
    <Header as="h3" icon>
        <Icon name="search" />
        {props.text ? props.text : ErrorText.SomethingWentWrong}
    </Header>
);

const NoResultsContent = (props: IProps) => <>No {props.text} match the criteria</>;

const WarningContent = (props: IProps): JSX.Element => (
    <Message icon color="orange" size={props.size ? props.size : "small"}>
        <Icon name="warning sign" />
        <Message.Content>
            <Message.Header>{props.text ? props.text : ""}</Message.Header>
        </Message.Content>
    </Message>
);

const displayFormat = (WrappedComponent: React.JSXElementConstructor<IProps>) => {
    const Display = (props: IProps) =>
        props.inline ? (
            <Container fluid={props.fluid ? true : false}>
                <WrappedComponent {...props} />
            </Container>
        ) : (
            //This div is here to support the various contexts in which this message is shown.
            //Most scenarios the message is 100% width already on smaller viewports, but for the grids this message
            //is very narrow because of the responsive styling specific to the grids, this div ensures the width will be 100%
            <div
                style={{
                    width: "100%"
                }}
            >
                <Container fluid>
                    <Segment
                        placeholder
                        textAlign="center"
                        size="massive"
                        padded="very"
                        style={{
                            marginTop:
                                props.marginTop !== undefined
                                    ? `${props.marginTop}px`
                                    : "80px"
                        }}
                    >
                        <WrappedComponent {...props} />
                    </Segment>
                </Container>
            </div>
        );

    return Display;
};
export const ErrorMessage = displayFormat(ErrorContent);
export const InfoText = displayFormat(InfoContent);
export const InfoMessage = displayFormat(InfoMessageContent);
export const LoadingMessage = displayFormat(LoadingContent);
export const NoAccessMessage = displayFormat(NoAccessContent);
export const NoDataMessage = displayFormat(NoDataContent);
export const NoResultsMessage = displayFormat(NoResultsContent);
export const WarningMessage = WarningContent;

import * as React from "react";
import {Button, Divider, Segment, Table} from "semantic-ui-react";
import {ErrorMessage, LoadingMessage} from "components/SystemMessages";
import dataFormatUtil, {nullValueDisplayString} from "util/dataFormatUtil";
import exportUtil from "util/exportUtil";
import {objectComparer, sortingDirections} from "util/sortUtil";
import {
    Concept,
    DataStatus,
    IColumnMetadata
} from "../../application/api/applicationModels";
import dashboardApi from "../api/dashboardAPI";
import {
    IBusinessUnitMetricData,
    IDailyMetricDataMapping,
    IModuleMetricDefinition,
    StaticColumnKey,
    Xtd
} from "../api/dashboardModels";
import LocationDailyMetricData from "./LocationDailyMetricData";
import {ModuleType} from "../../configuration/api/uiConfigurationModels";
import metricDataUtil from "util/metricDataUtil";
import ExportExcel from "components/ExportExcel";
import {IsMobileScreen} from "util/appParamsUtil";
import DataTableMetricValueDisplayElement from "components/DataTable/DataTableMetricValueDisplayElement";

interface IProps {
    date: Date;
    calendarId: number;
    concept: Concept;
    metricData: IBusinessUnitMetricData[];
    metricDefinitions: IModuleMetricDefinition[];
    groupID: string;
    startIndex: number;
    itemsPerPage: number;
    clientConcept_PK: number;
    handleSortPageChange: () => void;
    screenWidth: number;
}

interface IState {
    sortColumnKey: string;
    sortDirection: sortingDirections;
    expandedRows: {[locationPk: number]: boolean};
    metricDailyDataMapping: IDailyMetricDataMapping;
    dataStatus: DataStatus;
}

class MetricTable extends React.Component<IProps, IState> {
    date: Date;
    groupID: string;
    calendarId: number;
    concept: Concept;

    constructor(props: IProps) {
        super(props);

        this.date = this.props.date;
        this.groupID = this.props.groupID;
        this.calendarId = this.props.calendarId;
        this.concept = this.props.concept;
        this.state = {
            sortColumnKey: StaticColumnKey.RankPTDKey,
            sortDirection: sortingDirections.Ascending,
            expandedRows: {},
            metricDailyDataMapping: {},
            dataStatus: DataStatus.Undefined
        };
    }

    abortController = {
        current: new AbortController()
    };

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.date !== this.props.date) {
            this.abortController.current.abort();
            this.abortController.current = new AbortController();
        }
    }
    componentWillUnmount() {
        this.abortController.current.abort();
    }

    public handleSort = (clickedColumn: string): void => {
        const {sortColumnKey, sortDirection} = this.state;

        if (sortColumnKey !== clickedColumn) {
            this.setState({
                sortColumnKey: clickedColumn,
                sortDirection: sortDirection
            });
        } else {
            this.setState({
                sortColumnKey: clickedColumn,
                sortDirection:
                    sortDirection === sortingDirections.Ascending
                        ? sortingDirections.Descending
                        : sortingDirections.Ascending
            });
        }

        this.props.handleSortPageChange();

        return;
    };

    public handleRowClick = (locationPk: number): void => {
        const expandedRows = {...this.state.expandedRows}; // in this case we don't need to create a new object but it's a good practice to do so
        const metricDailyDataMapping = {...this.state.metricDailyDataMapping};

        if (!expandedRows[locationPk]) {
            expandedRows[locationPk] = true;
        } else {
            expandedRows[locationPk] = false;
        }

        if (!metricDailyDataMapping[locationPk]) {
            dashboardApi
                .fetchDailyMetricData(
                    this.props.clientConcept_PK,
                    locationPk,
                    this.date,
                    this.calendarId,
                    this.concept,
                    this.abortController.current.signal
                )
                .then((data) => {
                    metricDailyDataMapping[locationPk] = data;
                    this.setState({
                        metricDailyDataMapping: metricDailyDataMapping,
                        dataStatus: DataStatus.Loaded
                    });
                })
                .catch((err) => {
                    this.setState({
                        dataStatus: DataStatus.Failed
                    });
                });

            this.setState({dataStatus: DataStatus.Loading});
        }

        this.setState({
            expandedRows: expandedRows
        });
    };

    public renderColumnHeaders(
        metrics: IModuleMetricDefinition[],
        staticColumnHeaders: IColumnMetadata[],
        isMobileScreen: boolean
    ): JSX.Element {
        const {sortColumnKey, sortDirection} = this.state;

        const staticHeaders = staticColumnHeaders.map((h) => {
            return (
                <Table.HeaderCell
                    key={h.key}
                    rowSpan="2"
                    sorted={sortColumnKey === h.key ? sortDirection : undefined}
                    onClick={this.handleSort.bind(this, h.key, "Alphabetical")}
                >
                    {h.label}
                </Table.HeaderCell>
            );
        });

        let metricHeaders: JSX.Element[] = [];
        if (isMobileScreen) {
            metrics.forEach((m) => {
                [Xtd.WTD, Xtd.PTD].forEach((x) => {
                    const {sortColumnKey, sortDirection} = this.state;
                    const headerKey = m.metricCode + "-" + x;
                    const metricColumnKey = metricDataUtil.getMetricSortColumnKey(
                        m.metricCode,
                        x
                    );

                    metricHeaders.push(
                        <Table.HeaderCell
                            key={headerKey}
                            sorted={
                                sortColumnKey === metricColumnKey
                                    ? sortDirection
                                    : undefined
                            }
                            onClick={this.handleSort.bind(this, metricColumnKey)}
                        >
                            {`${m.metricName} - ${x}`}
                        </Table.HeaderCell>
                    );
                });
            });

            return (
                <Table.Header>
                    <Table.Row>
                        {staticHeaders}
                        {metricHeaders}
                    </Table.Row>
                </Table.Header>
            );
        } else {
            metricHeaders = metrics.map((m) => {
                const code = m.metricCode + m.sortOrder;
                return (
                    <Table.HeaderCell
                        className={"non-clickable-row-text"}
                        key={code}
                        colSpan="2"
                    >
                        {m.metricName}
                    </Table.HeaderCell>
                );
            });

            const metricHeaderWithXtd = metrics.map((m) => {
                const code = m.metricCode;

                return (
                    <React.Fragment key={code + m.sortOrder + "-xtd"}>
                        {this.renderMetricHeader(code, Xtd.WTD)}
                        {this.renderMetricHeader(code, Xtd.PTD)}
                    </React.Fragment>
                );
            });

            return (
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            key={"empty-cell"}
                            className={"non-clickable-row-arrow"}
                            rowSpan="2"
                        ></Table.HeaderCell>
                        {staticHeaders}
                        {metricHeaders}
                    </Table.Row>
                    <Table.Row>{metricHeaderWithXtd}</Table.Row>
                </Table.Header>
            );
        }
    }

    public renderTableRow(
        data: IBusinessUnitMetricData,
        metrics: IModuleMetricDefinition[],
        columnCount: number,
        isMobileScreen: boolean
    ): JSX.Element {
        let metricExpandTable: any;
        let button: any;
        const locationPk = data.businessUnitPk;
        const locationNumber = data.businessUnitName;
        if (this.state.expandedRows[locationPk]) {
            button = <Button secondary size="mini" icon="angle up" />;
            if (this.state.metricDailyDataMapping[locationPk]) {
                const {metricDefinitions, metricData} =
                    this.state.metricDailyDataMapping[locationPk];
                metricExpandTable = (
                    <Table.Row>
                        <td colSpan={columnCount}>
                            <Segment basic style={{width: "100%"}}>
                                <LocationDailyMetricData
                                    locationPk={locationPk}
                                    locationNumber={locationNumber}
                                    metricDefinitions={metricDefinitions}
                                    dailyMetricData={metricData}
                                />
                            </Segment>
                        </td>
                    </Table.Row>
                );
            } else if (this.state.dataStatus === DataStatus.Loading) {
                metricExpandTable = (
                    <Table.Row>
                        <td colSpan={columnCount}>
                            <LoadingMessage />
                        </td>
                    </Table.Row>
                );
            } else if (this.state.dataStatus === DataStatus.Failed) {
                metricExpandTable = (
                    <Table.Row>
                        <td colSpan={columnCount}>
                            <ErrorMessage />
                        </td>
                    </Table.Row>
                );
            }
        } else {
            button = <Button basic size="mini" icon="angle down" />;
        }

        return (
            <React.Fragment key={data.businessUnitId}>
                <Table.Row
                    key={data.businessUnitId}
                    className={"clickable-row"}
                    onClick={this.handleRowClick.bind(this, locationPk)}
                >
                    <Table.Cell
                        textAlign={isMobileScreen ? "left" : undefined}
                        collapsing
                    >
                        {button}
                    </Table.Cell>
                    <Table.Cell
                        collapsing
                        className="padding-left-30px"
                        textAlign="left"
                    >
                        {data.businessUnitName}
                        <br />
                        {data.businessUnitDescription === data.businessUnitName
                            ? null
                            : dataFormatUtil.truncateWithEllipses(
                                  data.businessUnitDescription,
                                  20
                              )}
                    </Table.Cell>
                    <Table.Cell
                        collapsing
                        className="padding-left-30px"
                        textAlign="left"
                    >
                        {data.parentBusinessUnitName}
                    </Table.Cell>
                    <Table.Cell collapsing>
                        <DataTableMetricValueDisplayElement
                            isMobileScreen={isMobileScreen}
                            metricName={"Rank (PTD)"}
                            metricDisplayValue1={
                                data.rankPTD || nullValueDisplayString
                            }
                        />
                    </Table.Cell>
                    {metrics.map((m) => {
                        const metricCode = m.metricCode;
                        const metricDataWTD = metricDataUtil.getMetricData(
                            data.metricData,
                            metricCode,
                            Xtd.WTD
                        );
                        const metricDataPTD = metricDataUtil.getMetricData(
                            data.metricData,
                            metricCode,
                            Xtd.PTD
                        );
                        const isMetricDataWTDViolatingThreshold =
                            metricDataUtil.getMetricDataThresholdViolation(
                                data.metricDataThresholdViolation,
                                metricCode,
                                Xtd.WTD
                            );
                        const isMetricDataPTDViolatingThreshold =
                            metricDataUtil.getMetricDataThresholdViolation(
                                data.metricDataThresholdViolation,
                                metricCode,
                                Xtd.PTD
                            );

                        return (
                            <React.Fragment
                                key={metricCode + locationPk + m.sortOrder}
                            >
                                {this.renderMetricCell(
                                    m,
                                    metricDataWTD,
                                    isMetricDataWTDViolatingThreshold,
                                    Xtd.WTD,
                                    isMobileScreen
                                )}
                                {this.renderMetricCell(
                                    m,
                                    metricDataPTD,
                                    isMetricDataPTDViolatingThreshold,
                                    Xtd.PTD,
                                    isMobileScreen
                                )}
                            </React.Fragment>
                        );
                    })}
                </Table.Row>
                {metricExpandTable}
            </React.Fragment>
        );
    }

    public renderColumnCells(
        metricData: IBusinessUnitMetricData[],
        metrics: IModuleMetricDefinition[],
        columnCount: number,
        isMobileScreen: boolean
    ): JSX.Element {
        if (metricData) {
            return (
                <Table.Body>
                    {metricData.map((data) => {
                        return this.renderTableRow(
                            data,
                            metrics,
                            columnCount,
                            isMobileScreen
                        );
                    })}
                </Table.Body>
            );
        } else {
            return <Table.Body></Table.Body>;
        }
    }

    public renderMetricCell(
        metricDefinition: IModuleMetricDefinition,
        data: number | null | undefined,
        isMetricDataViolatingThreshold: boolean,
        xtd: Xtd,
        isMobileScreen: boolean
    ): JSX.Element {
        const metricValueStyle = isMetricDataViolatingThreshold
            ? "metric-violating-threshold"
            : "";

        return (
            <Table.Cell collapsing>
                <DataTableMetricValueDisplayElement
                    isMobileScreen={isMobileScreen}
                    metricName={metricDefinition.metricName + "-" + xtd.toString()}
                    metricDisplayValue1={dataFormatUtil.dataTableDisplayValue(
                        data,
                        metricDefinition
                    )}
                    metricValueStyle={metricValueStyle}
                />
            </Table.Cell>
        );
    }

    public renderMetricHeader(metricCode: string, xtd: string): JSX.Element {
        const {sortColumnKey, sortDirection} = this.state;
        const headerKey = metricCode + "-" + xtd;
        const metricColumnKey = metricDataUtil.getMetricSortColumnKey(
            metricCode,
            xtd
        );

        return (
            <Table.HeaderCell
                key={headerKey}
                sorted={
                    sortColumnKey === metricColumnKey ? sortDirection : undefined
                }
                onClick={this.handleSort.bind(this, metricColumnKey)}
                className="table-header-subtitle-font"
            >
                {xtd}
            </Table.HeaderCell>
        );
    }

    public render(): JSX.Element {
        let {metricData} = this.props;
        const {metricDefinitions, startIndex, itemsPerPage, screenWidth} =
            this.props;
        const {sortColumnKey, sortDirection} = this.state;

        const isMobileScreen = IsMobileScreen(screenWidth);

        const staticColumnHeaders = [
            {
                key: StaticColumnKey.BusinessUnitKey.toString(),
                label: "Restaurant"
            },
            {
                key: StaticColumnKey.ParentBusinessUnitKey.toString(),
                label: "Area"
            },
            {key: StaticColumnKey.RankPTDKey.toString(), label: "Rank (PTD)"}
        ];

        // This is needed for the accordian (daily data) to span across all columns
        const columnCount =
            1 + staticColumnHeaders.length + 2 * metricDefinitions.length;

        if (sortDirection === sortingDirections.Descending) {
            metricData = this.props.metricData.sort(
                objectComparer(sortColumnKey, sortDirection)
            );
        } else if (sortDirection === sortingDirections.Ascending) {
            metricData = this.props.metricData.sort(
                objectComparer(sortColumnKey, sortDirection)
            );
        }

        const exportMetricHeader = exportUtil.getExportMetricHeaderData(
            metricDefinitions,
            [Xtd.WTD, Xtd.PTD],
            ModuleType.MetricStanding
        );

        return (
            <div className="table-wrapper">
                <ExportExcel
                    data={metricData}
                    columns={staticColumnHeaders.concat(exportMetricHeader)}
                    fileName={"Metric_Standings"}
                />
                <Divider hidden />
                {/* clear: "both" needed to clear floated content above */}
                <div className="overflow-x-scroll" style={{clear: "both"}}>
                    <Table
                        sortable
                        basic
                        striped
                        stackable={isMobileScreen}
                        structured
                        textAlign="center"
                    >
                        {this.renderColumnHeaders(
                            metricDefinitions,
                            staticColumnHeaders,
                            isMobileScreen
                        )}
                        {this.renderColumnCells(
                            metricData.slice(startIndex, startIndex + itemsPerPage),
                            metricDefinitions,
                            columnCount,
                            isMobileScreen
                        )}
                    </Table>
                </div>
            </div>
        );
    }
}

export default MetricTable;

import {find, forEach, keys} from "lodash";
import moment from "moment";
import * as QueryString from "query-string";
import {PageCode} from "features/configuration/api/uiConfigurationModels";
import {Xtd} from "features/dashboard/api/dashboardModels";
import {
    BusinessUnitAggregation,
    IThresholdFilters
} from "features/metricDetails/api/metricDetailsModels";
import {dateToString} from "./dateUtil";
import {
    IClientConcept,
    IBusinessUnit,
    IClientConceptPage
} from "features/application/api/applicationModels";
import {IMonitorConfiguration} from "features/monitors/api/monitorsModels";
import store from "state/store";

export function IsMobileScreen(screenWidth: number): boolean {
    return screenWidth < 768;
}

export enum RouteType {
    DASHBOARD = "Dashboard",
    METRIC_DETAILS = "MetricDetails",
    USER_SETTINGS = "UserSettings",
    MONITORS = "Monitors",
    MONITORS_SEARCH = "MonitorsSearch",
    MONITOR_DETAILS = "MonitorDetails",
    CREATE_MONITOR = "CreateMonitor",
    TASKS = "Tasks",
    HOME_OFFICE = "HomeOffice",
    EMPLOYEE_RETENTION = "EmployeeRetention",
    DIFFERENTIAL_PAY = "DifferentialPay",
    PAGE_CONFIGURATION = "PageConfiguration",
    REDIRECT = "Redirect",
    DELIVERY_OPERATIONS = "DeliveryOperations"
}

export interface IRouterState {
    monitorConfiguration: IMonitorConfiguration;
}

// Note that these url params need to be typed as string because that's what
//    RouteComponentProps from react-router is expecting
// app url params
export interface IAppRouteProps {
    conceptUrlName: string;
    businessUnitId: string;
    conceptName: string;
    clientConcepts: "";
    metricDefinitionId: string;
    fromDate: string;
    date: string;
    page_PK: string;
    xtd: Xtd;
    businessUnitAggregation: BusinessUnitAggregation;
    pastDays: string;
    monitorId: string;
    taskId: string;
    daysToLookBack: string;
}

// feature specific url classes
export interface IUrlParam {
    value: string | undefined;
    code: string;
    isQueryStringParameter?: boolean;
}

// Url parameter types
export enum UrlParams {
    Dashboard = "dashboard",
    Monitors = "monitors",
    Search = "search",
    Create = "create",
    conceptUrlName = "conceptName",
    BusinessUnitId = "businessUnitId",
    FromDate = "fromDate", // query string parameter
    Date = "date",
    Xtd = "xtd",
    BusinessUnitAggregation = "businessUnitAggregation",
    Page_PK = "page_PK",
    ThresholdFilters = "thresholdFilters", // query string parameter
    MetricDetails = "metricDetails",
    MonitorId = "monitorId",
    Tasks = "tasks",
    TaskId = "taskId",
    Details = "details",
    DaysToLookBack = "daysToLookBack",
    HomeOffice = "HomeOffice",
    EmployeeRetention = "EmployeeRetention",
    PageConfiguration = "pageConfiguration",
    DeliveryProviderPaymentData = "DeliveryProviderPaymentData",
    DifferentialPay = "DifferentialPay",
    DeliveryOperations = "DeliveryOperations",
    DowntimeDetails = "DowntimeDetails"
}

class BaseUrlModel {
    url = "";
    params!: IUrlParam[];

    /**
     * Takes a dictionary {[UrlParam]: value} with parameters to update and returns the new url.
     * @param newParams
     */
    generateUrlWithParameters(updatedParams: IUrlParam[]) {
        let url = "";
        const query: QueryString.StringifiableRecord = {};

        forEach(this.params, (p) => {
            if (p.isQueryStringParameter) {
                const updatedParam = find(updatedParams, (x) => x.code === p.code);

                const updatedValue = updatedParam ? updatedParam.value : p.value;

                // Note the if value is undefined, null or "" it will be skipped
                // NOTE! This code preserves the query strig parameters.
                // Make sure when creating a new url model or updating query string parameters that you set
                // the value to undefined when switching between contexts where the query string parameters don't make sense.
                // For example, switching between metric details pages should set the query string parameters to undefined
                query[p.code] = updatedValue;
            } else {
                const updatedParam = find(updatedParams, (x) => x.code === p.code);

                const updatedValue =
                    updatedParam !== undefined ? updatedParam.value : p.value;

                url += "/" + updatedValue;
            }
        });

        return QueryString.stringifyUrl(
            {
                url: url,
                query: query
            },
            {
                skipNull: true,
                skipEmptyString: true
            }
        );
    }

    getPage_PK(clientConcept_PK: number, pageCode: PageCode) {
        const page_PK = getPage_PKForClientConceptAndPageCode(
            store.getState().application.clientConceptPages,
            clientConcept_PK,
            pageCode
        );

        return page_PK;
    }
}

export class DashboardUrlModel extends BaseUrlModel {
    constructor(
        clientConcept: IClientConcept,
        businessUnitId: string,
        date: Date | null
    ) {
        super();

        const page_PK = this.getPage_PK(
            clientConcept.clientConcept_PK,
            PageCode.Dashboard
        );

        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: clientConcept.conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: page_PK.toString(),
                code: UrlParams.Page_PK
            },
            {
                value: businessUnitId,
                code: UrlParams.BusinessUnitId
            },
            {
                value: dateToString(date),
                code: UrlParams.Date
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class MetricDetailsUrlModel extends BaseUrlModel {
    constructor(
        clientConcept: IClientConcept,
        page_PK: number,
        businessUnitId: string,
        fromDate: Date | null, // query string parameter
        date: Date,
        xtd: Xtd,
        businessUnitAggregation: BusinessUnitAggregation,
        thresholdFilters: IThresholdFilters | undefined // query string parameter
    ) {
        super();

        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: clientConcept.conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: businessUnitId,
                code: UrlParams.BusinessUnitId
            },
            {
                value: UrlParams.MetricDetails,
                code: UrlParams.MetricDetails
            },
            {
                value: page_PK.toString(),
                code: UrlParams.Page_PK
            },
            {
                value: dateToString(date),
                code: UrlParams.Date
            },
            {
                value: xtd,
                code: UrlParams.Xtd
            },
            {
                value: businessUnitAggregation,
                code: UrlParams.BusinessUnitAggregation
            },
            {
                value: fromDate ? dateToString(fromDate) : undefined,
                code: UrlParams.FromDate,
                isQueryStringParameter: true
            },
            {
                value:
                    thresholdFilters && keys(thresholdFilters).length > 0
                        ? JSON.stringify(thresholdFilters)
                        : undefined,
                code: UrlParams.ThresholdFilters,
                isQueryStringParameter: true
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class MonitorUrlModel extends BaseUrlModel {
    constructor(conceptUrlName: string, businessUnitId: string) {
        super();
        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: UrlParams.Monitors,
                code: UrlParams.Monitors
            },
            {
                value: conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: businessUnitId,
                code: UrlParams.BusinessUnitId
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class MonitorSearchUrlModel extends BaseUrlModel {
    constructor(conceptUrlName: string) {
        super();
        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: UrlParams.Monitors,
                code: UrlParams.Monitors
            },
            {
                value: conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: UrlParams.Search,
                code: UrlParams.Search
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class MonitorDetailsUrlModel extends BaseUrlModel {
    constructor(
        conceptUrlName: string,
        businessUnitId: string,
        monitorId: number,
        pastDays: number
    ) {
        super();
        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: UrlParams.Monitors,
                code: UrlParams.Monitors
            },
            {
                value: conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: businessUnitId,
                code: UrlParams.BusinessUnitId
            },
            {
                value: monitorId.toString(),
                code: UrlParams.MonitorId
            },
            {
                value: UrlParams.Details,
                code: UrlParams.Details
            },
            {
                value: pastDays.toString(),
                code: UrlParams.DaysToLookBack
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class CreateMonitorUrlModel extends BaseUrlModel {
    constructor(conceptUrlName: string, monitorId?: number) {
        super();
        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: UrlParams.Monitors,
                code: UrlParams.Monitors
            },
            {
                value: conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: UrlParams.Create,
                code: UrlParams.Create
            }
        ];

        if (monitorId) {
            this.params.push({
                value: monitorId.toString(),
                code: UrlParams.MonitorId
            });
        }

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class TaskUrlModel extends BaseUrlModel {
    constructor(taskId?: number) {
        super();
        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: UrlParams.Tasks,
                code: UrlParams.Tasks
            }
        ];

        if (taskId) {
            this.params.push({
                value: taskId.toString(),
                code: UrlParams.TaskId
            });
        }

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class DeliveryProviderPaymentDataUrlModel extends BaseUrlModel {
    constructor(clientConcept: IClientConcept, date: Date, fromDate: Date | null) {
        super();

        const page_PK = this.getPage_PK(
            clientConcept.clientConcept_PK,
            PageCode.DeliveryProviderPaymentData
        );

        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: UrlParams.HomeOffice,
                code: UrlParams.HomeOffice
            },
            {
                value: page_PK.toString(),
                code: UrlParams.Page_PK
            },
            {
                value: UrlParams.DeliveryProviderPaymentData,
                code: UrlParams.DeliveryProviderPaymentData
            },
            {
                value: clientConcept.conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: dateToString(date),
                code: UrlParams.Date
            },
            {
                value: dateToString(fromDate || moment(date).add(-6, "d").toDate()),
                code: UrlParams.FromDate,
                isQueryStringParameter: true
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class EmployeeRetentionUrlModel extends BaseUrlModel {
    constructor(
        clientConcept: IClientConcept,
        businessUnitId: string,
        fromDate: Date | null,
        date: Date,
        xtd: Xtd,
        businessUnitAggregation: BusinessUnitAggregation
    ) {
        super();

        const page_PK = this.getPage_PK(
            clientConcept.clientConcept_PK,
            PageCode.EmployeeRetention
        );

        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: clientConcept.conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: businessUnitId,
                code: UrlParams.BusinessUnitId
            },
            {
                value: UrlParams.HomeOffice,
                code: UrlParams.HomeOffice
            },
            {
                value: page_PK.toString(),
                code: UrlParams.Page_PK
            },
            {
                value: UrlParams.EmployeeRetention,
                code: UrlParams.EmployeeRetention
            },
            {
                value: dateToString(date),
                code: UrlParams.Date
            },
            {
                value: xtd,
                code: UrlParams.Xtd
            },
            {
                value: businessUnitAggregation,
                code: UrlParams.BusinessUnitAggregation
            },
            {
                value: fromDate ? dateToString(fromDate) : undefined,
                code: UrlParams.FromDate,
                isQueryStringParameter: true
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class DifferentialPayUrlModel extends BaseUrlModel {
    constructor(conceptUrlName: string, businessUnitId: string) {
        super();
        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: UrlParams.DifferentialPay,
                code: UrlParams.DifferentialPay
            },
            {
                value: conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: businessUnitId,
                code: UrlParams.BusinessUnitId
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class PageConfigurationUrlModel extends BaseUrlModel {
    constructor(pagePK?: number) {
        super();
        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: UrlParams.PageConfiguration,
                code: UrlParams.PageConfiguration
            },
            {
                value: UrlParams.Page_PK,
                code: UrlParams.Page_PK
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class DowntimeDetailsUrlModel extends BaseUrlModel {
    constructor(
        clientConcept: IClientConcept,
        businessUnitId: string,
        fromDate: Date,
        toDate: Date,
        businessUnitAggregation: BusinessUnitAggregation
    ) {
        super();

        const page_PK = this.getPage_PK(
            clientConcept.clientConcept_PK,
            PageCode.DowntimeDetails
        );

        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: UrlParams.DeliveryOperations,
                code: UrlParams.DeliveryOperations
            },
            {
                value: UrlParams.DowntimeDetails,
                code: UrlParams.DowntimeDetails
            },
            {
                value: page_PK.toString(),
                code: UrlParams.Page_PK
            },
            {
                value: clientConcept.conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: businessUnitId,
                code: UrlParams.BusinessUnitId
            },
            {
                value: dateToString(toDate),
                code: UrlParams.Date
            },
            {
                value: businessUnitAggregation,
                code: UrlParams.BusinessUnitAggregation
            },
            {
                value: dateToString(fromDate),
                code: UrlParams.FromDate,
                isQueryStringParameter: true
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export class DeliveryOperationsUrlModel extends BaseUrlModel {
    constructor(
        clientConcept: IClientConcept,
        businessUnitId: string,
        fromDate: Date,
        toDate: Date,
        businessUnitAggregation: BusinessUnitAggregation
    ) {
        super();

        const page_PK = this.getPage_PK(
            clientConcept.clientConcept_PK,
            PageCode.DeliveryOperations
        );

        this.params = [
            {
                value: UrlParams.Dashboard,
                code: UrlParams.Dashboard
            },
            {
                value: UrlParams.DeliveryOperations,
                code: UrlParams.DeliveryOperations
            },
            {
                value: page_PK.toString(),
                code: UrlParams.Page_PK
            },
            {
                value: clientConcept.conceptUrlName,
                code: UrlParams.conceptUrlName
            },
            {
                value: businessUnitId,
                code: UrlParams.BusinessUnitId
            },
            {
                value: dateToString(toDate),
                code: UrlParams.Date
            },
            {
                value: businessUnitAggregation,
                code: UrlParams.BusinessUnitAggregation
            },
            {
                value: dateToString(fromDate),
                code: UrlParams.FromDate,
                isQueryStringParameter: true
            }
        ];

        this.url = this.generateUrlWithParameters(this.params);
    }
}

export type UrlModel =
    | DashboardUrlModel
    | MetricDetailsUrlModel
    | MonitorUrlModel
    | MonitorSearchUrlModel
    | MonitorDetailsUrlModel
    | CreateMonitorUrlModel
    | TaskUrlModel
    | DeliveryProviderPaymentDataUrlModel
    | EmployeeRetentionUrlModel
    | PageConfigurationUrlModel
    | DowntimeDetailsUrlModel
    | DeliveryOperationsUrlModel;

export function isGuid(id: string | undefined | null): boolean {
    if (id === undefined || id === null) {
        return false;
    }

    const pattern =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    return pattern.test(id);
}

export function isDate(date: string | undefined): boolean {
    if (date === undefined) {
        return false;
    }

    return moment(date).isValid();
}

export function getNewClientConceptFromSelectedBusinessUnitId(
    currentClientConcept_PK: number,
    availableClientConcepts: IClientConcept[],
    clientTreeHierarchy: IBusinessUnit[],
    selectedBusinessUnitId: string
): IClientConcept | undefined {
    const selectedBusinessUnit = find(
        clientTreeHierarchy,
        (g) => g.businessUnitId === selectedBusinessUnitId
    );

    if (
        selectedBusinessUnit &&
        selectedBusinessUnit.clientConcept_PK !== currentClientConcept_PK
    ) {
        const newClientConcept = find(
            availableClientConcepts,
            (c) => c.clientConcept_PK === selectedBusinessUnit.clientConcept_PK
        );

        return newClientConcept || undefined;
    } else {
        return undefined;
    }
}

export function areBusinessUnitIdsTheSameClientConcept(
    businessUnitIdA: string,
    businessUnitIdB: string,
    clientTreeHierarchy: IBusinessUnit[]
): boolean {
    const groupA = find(
        clientTreeHierarchy,
        (g) => g.businessUnitId === businessUnitIdA
    );
    const groupB = find(
        clientTreeHierarchy,
        (g) => g.businessUnitId === businessUnitIdB
    );

    if (groupA && groupB && groupA.clientConcept_PK === groupB.clientConcept_PK) {
        return true;
    } else {
        return false;
    }
}

export function getPageForClientConceptAndPageCode(
    clientConceptPages: IClientConceptPage[],
    clientConcept_PK: number,
    pageCode: PageCode
): IClientConceptPage | undefined {
    // It should be guaranteed that there is one and only one page for a clientConcept_PK and pageCode (besides pageCode = metricDetail_Custom)
    // However, this code can be called before clientConceptPages or clientConcept_PK are set, in that case just return undefined.
    const page = find(
        clientConceptPages,
        (p) => p.clientConcept_PK === clientConcept_PK && p.pageCode === pageCode
    );

    return page;
}
export function getPageForPage_PK(
    clientConceptPages: IClientConceptPage[],
    page_PK: number | undefined
): IClientConceptPage | undefined {
    // It should be guaranteed that there is one and only one page for a clientConcept_PK and pageCode (besides pageCode = metricDetail_Custom)
    // However, this code can be called before clientConceptPages or clientConcept_PK are set, in that case just return undefined.
    const page = find(clientConceptPages, (p) => p.page_PK === page_PK);

    return page;
}

export function getPage_PKForClientConceptAndPageCode(
    clientConceptPages: IClientConceptPage[],
    clientConcept_PK: number,
    pageCode: PageCode
): number {
    // It should be guaranteed that there is one and only one page for a clientConcept_PK and pageCode (besides pageCode = metricDetail_Custom)
    // However, this code can be called before clientConceptPages or clientConcept_PK are set, in that case just return 0.
    const page = find(
        clientConceptPages,
        (p) => p.clientConcept_PK === clientConcept_PK && p.pageCode === pageCode
    );

    if (page) {
        return page.page_PK;
    } else {
        return 0;
    }
}

export function getPage_PKForClientConceptAndPageName(
    clientConceptPages: IClientConceptPage[],
    clientConcept_PK: number,
    pageCode: PageCode,
    pageName: string
): number {
    // This allows a page to be returned by name, in scenarios where multiple pages have the same pageCode.
    const page = find(
        clientConceptPages,
        (p) =>
            p.clientConcept_PK === clientConcept_PK &&
            p.pageCode === pageCode &&
            p.pageName === pageName
    );

    if (page) {
        return page.page_PK;
    } else {
        return 0;
    }
}

import * as React from "react";
import {Icon, Pagination, PaginationProps} from "semantic-ui-react";

interface IProps {
    changePage: (
        e: React.MouseEvent<HTMLAnchorElement>,
        data: PaginationProps
    ) => void;
    activePage: number;
    totalPages: number;
}

class PaginationComponent extends React.Component<IProps> {
    public render(): JSX.Element | null {
        const {activePage, totalPages, changePage} = this.props;
        return totalPages > 1 ? (
            <Pagination
                className="pager"
                size="small"
                activePage={activePage}
                onPageChange={changePage}
                ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: true
                }}
                firstItem={null}
                lastItem={null}
                prevItem={null}
                nextItem={null}
                totalPages={totalPages}
            />
        ) : null;
    }
}

export default PaginationComponent;

import {BusinessUnitType} from "features/application/api/applicationModels";
import store from "state/store";

export function isGroupWithAreas(businessUnitId: string | undefined) {
    const tree = store.getState().application.clientTreeHierarchy;
    return tree.find(
        (treeNode) =>
            treeNode.parentBusinessUnitId === businessUnitId &&
            treeNode.businessUnitType === BusinessUnitType.Group
    )
        ? true
        : false;
}
export function isAreaWithLocations(businessUnitId: string | undefined) {
    const tree = store.getState().application.clientTreeHierarchy;

    // return true if all children of the business unit are locations
    return (
        tree.filter((treeNode) => treeNode.parentBusinessUnitId === businessUnitId)
            .length > 0 &&
        tree
            .filter((treeNode) => treeNode.parentBusinessUnitId === businessUnitId)
            .every((child) => child.businessUnitType === BusinessUnitType.Location)
    );
}
export function isLocation(businessUnitId: string | undefined) {
    const tree = store.getState().application.clientTreeHierarchy;
    return tree.find(
        (treeNode) =>
            treeNode.businessUnitId === businessUnitId &&
            treeNode.businessUnitType === BusinessUnitType.Location
    )
        ? true
        : false;
}

import apiUtil from "../../../util/apiUtil";
import {
    IDomainSettings,
    IClientConceptMetricThreshold,
    ILocationMetricThreshold,
    IUserSettings,
    IIdealWeeklySalesPerEmployeeThresholdForHomeOffice,
    IClientConceptPage
} from "./applicationModels";

const applicationApi = {
    saveUserSettings(userSettings: IUserSettings): Promise<IUserSettings> {
        return apiUtil.postData("/api/client/saveUserSettings", userSettings);
    },

    trackPageViewOrAction(pathName: string, queryString?: string): void {
        let displayMode = "browser";
        const mqStandAlone = "(display-mode: standalone)";
        if (window.matchMedia(mqStandAlone).matches) {
            displayMode = "standalone";
        }

        apiUtil.postData("/api/usageTracking/TrackPageViewOrAction", {
            pathName,
            queryString,
            displayMode
        });
    },

    async getOpenTaskCount(clientUserId: string): Promise<number> {
        return apiUtil.fetchData(`/api/task/getOpenTaskCount/${clientUserId}`);
    },

    async getDomainSettings(): Promise<IDomainSettings> {
        return apiUtil.fetchData("/api/configuration/DomainSetting");
    },

    async getClientConceptMetricThresholds(
        clientConcept_PK: number
    ): Promise<IClientConceptMetricThreshold[]> {
        return apiUtil.fetchData(
            `/api/UIConfiguration/ClientConceptMetricThresholds/${clientConcept_PK}`
        );
    },

    async getIdealWeeklySalesPerEmployeeThreshold(
        clientConcept_PK: number
    ): Promise<IIdealWeeklySalesPerEmployeeThresholdForHomeOffice> {
        return apiUtil.fetchData(
            `/api/UIConfiguration/IdealWeeklySalesPerEmployeeThreshold/${clientConcept_PK}`
        );
    },

    async setIdealWeeklySalesPerEmployeeThreshold(
        idealWeeklySalesPerEmployeeThresholdForHomeOffice: IIdealWeeklySalesPerEmployeeThresholdForHomeOffice
    ): Promise<IIdealWeeklySalesPerEmployeeThresholdForHomeOffice> {
        return apiUtil.postData(
            "api/UIConfiguration/SetIdealWeeklySalesPerEmployeeThreshold",
            idealWeeklySalesPerEmployeeThresholdForHomeOffice
        );
    },

    updateClientConceptMetricThresholds(
        clientConceptMetricThreshold: IClientConceptMetricThreshold
    ): Promise<Response> {
        return apiUtil.postData(
            "/api/UIConfiguration/UpdateClientConceptMetricThresholds",
            clientConceptMetricThreshold
        );
    },

    deleteLocationMetricThreshold(
        clientConcept_PK: number,
        metricDefinitionId: number,
        locationPk: number
    ): Promise<Response> {
        return apiUtil.postData(
            `/api/UIConfiguration/DeleteLocationMetricThreshold/${clientConcept_PK}/${metricDefinitionId}/${locationPk}`
        );
    },

    async getLocationMetricThresholds(
        clientConcept_PK: number,
        businessUnitId: string,
        metricDefinitionId: string
    ): Promise<ILocationMetricThreshold> {
        return apiUtil.fetchData(
            `/api/UIConfiguration/LocationMetricThresholds/${clientConcept_PK}/${businessUnitId}/${metricDefinitionId}`
        );
    },

    updateLocationMetricThresholds(
        locationMetricThreshold: ILocationMetricThreshold,
        clientConcept_PK: number
    ): Promise<Response> {
        return apiUtil.postData(
            `/api/UIConfiguration/UpdateLocationMetricThresholds/${clientConcept_PK}`,
            locationMetricThreshold
        );
    },

    getClientConceptPages(): Promise<IClientConceptPage[]> {
        return apiUtil.fetchData("/api/UIConfiguration/ClientConceptPages");
    }
};

export default applicationApi;

import {Form, Dropdown} from "semantic-ui-react";
import {Controller} from "react-hook-form";
import FormErrorMessage from "../FormErrorMessage";
import {ISemanticReactHookFieldProps} from "../formsModels";

const SemanticHookFormDropdown = (
    props: ISemanticReactHookFieldProps
): JSX.Element => {
    const {
        control,
        name,
        label,
        options,
        setValue,
        disabled,
        multiple,
        compact,
        search,
        clearable,
        loading,
        style,
        handleOnChange,
        errors,
        errorLabelPointingDirection = "above"
    } = props;

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={multiple ? [] : ""}
            render={({field: {ref, ...field}}) => (
                <>
                    <Form.Field>
                        <label htmlFor={name}>{label}</label>
                        <Dropdown
                            selection
                            multiple={multiple}
                            compact={compact}
                            selectOnBlur={false}
                            options={options}
                            {...field}
                            onChange={(
                                e: React.SyntheticEvent<HTMLElement>,
                                data
                            ) => {
                                if (setValue) {
                                    setValue(name, data.value, {
                                        shouldDirty: true,
                                        shouldTouch: true,
                                        shouldValidate: true
                                    });
                                }
                                if (handleOnChange) {
                                    handleOnChange(data.value as string);
                                }
                            }}
                            value={field.value || ""}
                            style={style}
                            disabled={disabled}
                            loading={loading}
                            clearable={clearable}
                            search={search}
                        />
                    </Form.Field>
                    {errors && (
                        <FormErrorMessage
                            name={name}
                            pointing={errorLabelPointingDirection}
                            errors={errors}
                        />
                    )}
                </>
            )}
        />
    );
};

export default SemanticHookFormDropdown;

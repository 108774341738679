import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Image, Label, Menu, Dropdown, Button, Modal, Icon} from "semantic-ui-react";
import {actionCreators} from "../features/authentication/state/actions";
import {actionCreators as applicationActions} from "../features/application/state/actions";
import {AuthenticationAuthorizationResult} from "../features/authentication/state/reducer";
import logo from "../img/logo-delaget-coach-header.svg";
import {AppState} from "../state";
import {getCurrentSelectedBusinessUnitId} from "../util/stateUtil";
import HamburgerMenu from "./HamburgerMenu";
import {
    IClientConcept,
    IBusinessUnit,
    IClientConceptPage
} from "../features/application/api/applicationModels";
import {
    getNewClientConceptFromSelectedBusinessUnitId,
    DashboardUrlModel,
    MonitorUrlModel,
    DifferentialPayUrlModel
} from "../util/appParamsUtil";
import {FC, useState} from "react";

interface IReduxProps {
    isAdmin: boolean;
    isRecoveryAdmin: boolean;
    isDelagetUser: boolean;
    clientConcept: IClientConcept;
    availableClientConcepts: IClientConcept[];
    clientHierarchy: IBusinessUnit[];
    businessUnitId: string;
    date: Date;
    isUserAuthenticated: AuthenticationAuthorizationResult;
    openTaskCount: number;
    legacySiteBaseUrl: string;
    isFreemium: boolean;
    conceptsForCoachDetect: IClientConcept[];
    conceptsForCoachDifferentialPay: IClientConcept[];
    clientConceptPages: IClientConceptPage[];
    clientCode: string;
}

type DelagetHeaderProps = IReduxProps &
    typeof actionCreators &
    typeof applicationActions;

const DelagetHeader: FC<DelagetHeaderProps> = ({
    clientConcept,
    availableClientConcepts,
    clientHierarchy,
    businessUnitId,
    date,
    isUserAuthenticated,
    handleFreemiumMarketingModal,
    openTaskCount,
    conceptsForCoachDetect,
    conceptsForCoachDifferentialPay,
    isAdmin,
    isRecoveryAdmin,
    isDelagetUser,
    isFreemium,
    legacySiteBaseUrl,
    clientCode
}) => {
    const [visible, setVisible] = useState(false);

    // Get the correct concept from the business unit id, so the link is correct
    const newClientConcept = getNewClientConceptFromSelectedBusinessUnitId(
        clientConcept.clientConcept_PK,
        availableClientConcepts,
        clientHierarchy,
        businessUnitId
    );

    const dashboardUrlModel = new DashboardUrlModel(
        newClientConcept || clientConcept,
        businessUnitId,
        date
    );

    const dashboardBaseUrl = "/dashboard";
    const settingsUrl = "/dashboard/settings";
    const tasksUrl = "/dashboard/tasks";
    const adminUrl = "/dashboard/admin";
    const showA2HSButton = isMobileWithoutApp();
    const A2HSUrl = "/dashboard/addToHomeScreen";

    const freemiumUpgradeUrl = "https://www.delaget.com/freemium-upgrade/";

    const renderMenuOptions = (isForMobile: boolean) => {
        const classNameForItem = isForMobile
            ? "mobile-nav-option"
            : "desktop-nav-option";
        return (
            <>
                {isFreemium && (
                    <Menu.Item className={classNameForItem}>
                        <a href={freemiumUpgradeUrl}>
                            <Button
                                className={"btn-orange"}
                                style={{
                                    height: "30x",
                                    lineHeight: "5px"
                                }}
                            >
                                Upgrade Now
                            </Button>
                        </a>
                    </Menu.Item>
                )}
                <Menu.Item
                    as={Link}
                    to={dashboardUrlModel.url}
                    className={classNameForItem}
                >
                    <p>Dashboard</p>
                </Menu.Item>
                <Menu.Item
                    as={isFreemium ? null : Link}
                    to={isFreemium ? "" : tasksUrl}
                    onClick={
                        isFreemium
                            ? () => {
                                  handleFreemiumMarketingModal(true);
                              }
                            : undefined
                    }
                    className={classNameForItem}
                >
                    <p>Tasks</p>
                    {openTaskCount !== 0 ? (
                        <Label color="blue" floating>
                            {openTaskCount}
                        </Label>
                    ) : null}
                </Menu.Item>
                <Menu.Item as={Link} to={settingsUrl} className={classNameForItem}>
                    <p>Settings</p>
                </Menu.Item>
                {conceptsForCoachDetect.length > 0 &&
                    getLinkToCoachDetect(
                        conceptsForCoachDetect,
                        clientConcept,
                        businessUnitId,
                        classNameForItem,
                        isForMobile
                    )}
                {conceptsForCoachDifferentialPay.length > 0 &&
                    getLinkToCoachDifferentialPay(
                        conceptsForCoachDifferentialPay,
                        clientConcept,
                        businessUnitId,
                        classNameForItem,
                        isForMobile
                    )}
                {(isAdmin || isRecoveryAdmin) && (
                    <Menu.Item as={Link} to={adminUrl} className={classNameForItem}>
                        <p>Admin</p>
                    </Menu.Item>
                )}
                {showA2HSButton && (
                    <Menu.Item className={"btn-orange " + classNameForItem}>
                        <a href={A2HSUrl}>
                            <i
                                className="download icon"
                                style={{
                                    paddingRight: "24px"
                                }}
                            ></i>
                            Add to Home Screen
                        </a>
                    </Menu.Item>
                )}
            </>
        );
    };

    const getLinkToCoachDetect = (
        conceptsForCoachDetect: IClientConcept[],
        currentSelectedClientConcept: IClientConcept,
        currentSelectedBusinessUnitId: string,
        classNameForItem: string,
        isForMobile: boolean
    ) => {
        if (conceptsForCoachDetect.length === 1) {
            let businessUnitId = conceptsForCoachDetect[0].rootGroupId;
            // If the current selected concept is the same as the one we are linking to, then keep the selected business unit
            if (
                conceptsForCoachDetect[0].concept ===
                currentSelectedClientConcept.concept
            ) {
                businessUnitId = currentSelectedBusinessUnitId;
            }

            const monitorUrlModel = new MonitorUrlModel(
                conceptsForCoachDetect[0].conceptUrlName,
                businessUnitId
            );

            return (
                <Menu.Item
                    as={Link}
                    to={monitorUrlModel.url}
                    className={classNameForItem}
                >
                    <p>Detect</p>
                </Menu.Item>
            );
        } else {
            return (
                <Dropdown basic item text={"Detect"} className={classNameForItem}>
                    <Dropdown.Menu style={{zIndex: "101"}}>
                        {conceptsForCoachDetect.map((c) => {
                            let businessUnitId = c.rootGroupId;
                            // If the current selected concept is the same as the one we are linking to, then keep the selected business unit
                            if (c.concept === currentSelectedClientConcept.concept) {
                                businessUnitId = currentSelectedBusinessUnitId;
                            }

                            const monitorUrlModel = new MonitorUrlModel(
                                c.conceptUrlName,
                                businessUnitId
                            );

                            return (
                                <Dropdown.Item
                                    key={c.conceptName}
                                    as={Link}
                                    to={monitorUrlModel.url}
                                    text={c.conceptName}
                                    onClick={() => {
                                        if (isForMobile) {
                                            setVisible(!visible);
                                        }
                                    }}
                                />
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            );
        }
    };
    const getLinkToCoachDifferentialPay = (
        conceptsForCoachDifferentialPay: IClientConcept[],
        currentSelectedClientConcept: IClientConcept,
        currentSelectedBusinessUnitId: string,
        classNameForItem: string,
        isForMobile: boolean
    ) => {
        if (conceptsForCoachDifferentialPay.length === 1) {
            let businessUnitId = conceptsForCoachDifferentialPay[0].rootGroupId;
            // If the current selected concept is the same as the one we are linking to, then keep the selected business unit
            if (
                conceptsForCoachDifferentialPay[0].concept ===
                currentSelectedClientConcept.concept
            ) {
                businessUnitId = currentSelectedBusinessUnitId;
            }

            const monitorUrlModel = new DifferentialPayUrlModel(
                conceptsForCoachDifferentialPay[0].conceptUrlName,
                businessUnitId
            );

            return (
                <Menu.Item
                    as={Link}
                    to={monitorUrlModel.url}
                    className={classNameForItem}
                >
                    <p>Differential Pay</p>
                </Menu.Item>
            );
        } else {
            return (
                <Dropdown
                    basic
                    item
                    text={"Differential Pay"}
                    className={classNameForItem}
                >
                    <Dropdown.Menu style={{zIndex: "101"}}>
                        {conceptsForCoachDifferentialPay.map((c) => {
                            let businessUnitId = c.rootGroupId;
                            // If the current selected concept is the same as the one we are linking to, then keep the selected business unit
                            if (c.concept === currentSelectedClientConcept.concept) {
                                businessUnitId = currentSelectedBusinessUnitId;
                            }

                            const differentialPayUrlModel =
                                new DifferentialPayUrlModel(
                                    c.conceptUrlName,
                                    businessUnitId
                                );

                            return (
                                <Dropdown.Item
                                    key={c.conceptName}
                                    as={Link}
                                    to={differentialPayUrlModel.url}
                                    text={c.conceptName}
                                    onClick={() => {
                                        if (isForMobile) {
                                            setVisible(!visible);
                                        }
                                    }}
                                />
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            );
        }
    };

    return (
        <>
            <Menu
                className="delaget-header"
                style={{
                    width: "101%", // adding extra percent fixes rounding error, fills entire screen
                    background: "white",
                    borderBottom: "1px solid rgba(34, 36, 38, 0.15)",
                    position: "fixed",
                    top: 0,
                    height: "60px",
                    border: "none",
                    zIndex: 9999998
                }}
                secondary
                attached
            >
                <Image
                    as={Link}
                    to={dashboardBaseUrl}
                    src={logo}
                    className={"header-logo"}
                />
                {isUserAuthenticated ===
                    AuthenticationAuthorizationResult.Success && (
                    <Menu.Menu position="right">
                        {renderMenuOptions(false)}
                        <Menu.Item
                            id="mobile-nav-icon"
                            onClick={() => setVisible(!visible)}
                        >
                            <Icon name="angle double down" />
                        </Menu.Item>
                        <HamburgerMenu legacySiteBaseUrl={legacySiteBaseUrl} />
                    </Menu.Menu>
                )}
            </Menu>
            {isUserAuthenticated === AuthenticationAuthorizationResult.Success && (
                <Modal
                    open={visible}
                    onClose={() => setVisible(false)}
                    closeOnDocumentClick={true}
                    centered={false}
                    className="mobile-nav-options"
                    onClick={() => setVisible(false)}
                >
                    <Modal.Content>
                        <Menu
                            className="delaget-header"
                            style={{
                                marginTop: "32px",
                                borderBottomLeftRadius: "1rem",
                                borderBottomRightRadius: "1rem"
                            }}
                            inverted
                            secondary
                            attached
                            stackable
                        >
                            {renderMenuOptions(true)}
                        </Menu>
                    </Modal.Content>
                </Modal>
            )}
        </>
    );
};

function isMobileWithoutApp() {
    // regular expression for the most common types of mobile devices
    const isMobile = /iphone|ipad|android/gi.test(navigator.userAgent);
    const isUsingApp = window.matchMedia("(display-mode: standalone)").matches;

    if (isMobile && !isUsingApp) {
        return true;
    }
    return false;
}

const mapStateToProps = (state: AppState) => ({
    isAdmin: state.application.userInfo.isAdmin,
    isRecoveryAdmin: state.application.userInfo.isRecoveryAdmin,
    isDelagetUser: state.application.userInfo.isDelagetUser,
    clientConcept: state.application.selectedClientConcept,
    availableClientConcepts: state.application.availableClientConcepts,
    clientHierarchy: state.application.clientTreeHierarchy,
    businessUnitId: getCurrentSelectedBusinessUnitId(state),
    date: state.application.date,
    isUserAuthenticated: state.auth.isAuthenticated,
    openTaskCount: state.application.openTaskCount,
    legacySiteBaseUrl: state.application.legacySiteBaseUrl,
    isFreemium: state.application.userInfo.isFreemium,
    conceptsForCoachDifferentialPay:
        state.application.availableClientConceptsForCoachDifferentialPay,
    conceptsForCoachDetect: state.application.availableClientConceptsForCoachDetect,
    clientConceptPages: state.application.clientConceptPages,
    clientCode: state.application.userInfo.clientCode
});

export default connect(
    mapStateToProps,
    {...actionCreators, ...applicationActions},
    (state, actions, props) => ({...state, ...actions, ...props})
)(DelagetHeader);

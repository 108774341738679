import * as React from "react";
import ReactGA from "react-ga";
import {connect, ConnectedProps, ConnectedComponent} from "react-redux";
import {Route, RouteComponentProps, withRouter} from "react-router-dom";
import {Message} from "semantic-ui-react";
import {AppState} from "state";
import UserSettings from "features/application/UserSettings";
import {AuthenticationAuthorizationResult} from "features/authentication/state/reducer";
import {ErrorMessage, LoadingMessage} from "components/SystemMessages";
import {IAppRouteProps, RouteType} from "util/appParamsUtil";
import {getCookie} from "util/cookieUtil";
import {actionCreators} from "../state/actions";
import SessionTimeout from "./SessionTimeout";
import applicationApi from "features/application/api/applicationAPI";
import WithRouteParameters from "./WithRouteParameters";
import {NoAccessMessage} from "../../../components/SystemMessages";

interface IProps extends IReduxProps {
    exact: boolean;
    component:
        | React.LazyExoticComponent<ConnectedComponent<any, any>>
        | React.LazyExoticComponent<React.ComponentClass<any, any>>
        | React.LazyExoticComponent<React.FC>
        | React.ComponentClass<any, any>
        | ConnectedComponent<any, any>;
    path: string;
    isFullVersionOnly: boolean;
    isAdminOnly: boolean;
    isAdminOrRecoveryAdminOnly: boolean;
    isCoachDetect?: boolean;
    isDeliveryOperations?: boolean;
    routeType?: RouteType;
}

type ProtectedRouteProps = IProps & RouteComponentProps<IAppRouteProps>;

class ProtectedRoute extends React.Component<ProtectedRouteProps> {
    static defaultProps = {
        isAdminOrRecoveryAdminOnly: false
    };
    constructor(props: ProtectedRouteProps) {
        super(props);

        this.renderRoute = this.renderRoute.bind(this);
    }

    public componentDidMount() {
        const loginToken: string | undefined = getCookie("loginToken");

        if (!loginToken) {
            this.props.authenticateFailed("Cookie is expired.");
        } else if (
            this.props.isAuthenticated !== AuthenticationAuthorizationResult.Success
        ) {
            // if there is a cookie then try to authenticate
            this.props.authenticate(loginToken);
        }
    }

    componentDidUpdate(prevProps: ProtectedRouteProps) {
        if (
            this.props.isAuthenticated !== prevProps.isAuthenticated &&
            (this.props.isAuthenticated ===
                AuthenticationAuthorizationResult.AuthenticationFailure ||
                this.props.isAuthenticated ===
                    AuthenticationAuthorizationResult.Unauthenticated)
        ) {
            this.props.signOut();
        }

        const url = this.props.location.pathname + this.props.location.search;
        const prevUrl = prevProps.location.pathname + prevProps.location.search;

        if (
            this.props.isAuthenticated !== prevProps.isAuthenticated &&
            this.props.isAuthenticated === AuthenticationAuthorizationResult.Success
        ) {
            // Track the first page visited when an user gets authenticated
            ReactGA.pageview(url);
            applicationApi.trackPageViewOrAction(
                this.props.location.pathname,
                this.props.location.search
            );
        }

        if (url !== prevUrl) {
            // Track a page hit when url changes (this doesn't fire for the first page visited)
            ReactGA.pageview(url);
            applicationApi.trackPageViewOrAction(
                this.props.location.pathname,
                this.props.location.search
            );
        }
    }

    public render() {
        return this.renderRoute();
    }

    private renderRoute() {
        const {
            component,
            app,
            isAuthenticated,
            isFullVersionOnly,
            isAdminOnly,
            isAdminOrRecoveryAdminOnly,
            isCoachDetect,
            isDeliveryOperations,
            routeType
        } = this.props;

        if (isAuthenticated === AuthenticationAuthorizationResult.Unauthenticated) {
            return <LoadingMessage text="Authenticating..." />;
        }
        if (isAuthenticated === AuthenticationAuthorizationResult.Success) {
            const errorMessage =
                this.props.authError && this.props.authError !== "" ? (
                    <Message error floating>
                        {this.props.authError}
                    </Message>
                ) : null;
            if (!app.userSettings || !app.userSettings.defaultBusinessUnitId) {
                return (
                    <>
                        {errorMessage}
                        <UserSettings />
                    </>
                );
            } else {
                return (
                    <>
                        {errorMessage}
                        <Route
                            exact={this.props.exact}
                            path={this.props.path}
                            render={(props) => (
                                <WithRouteParameters
                                    {...props}
                                    wrappedComponent={component}
                                    isFullVersionOnly={isFullVersionOnly}
                                    isAdminOnly={isAdminOnly}
                                    isAdminOrRecoveryAdminOnly={
                                        isAdminOrRecoveryAdminOnly
                                    }
                                    isCoachDetect={isCoachDetect}
                                    isDeliveryOperations={isDeliveryOperations}
                                    routeType={routeType}
                                />
                            )}
                        />
                    </>
                );
            }
        } else if (
            isAuthenticated ===
            AuthenticationAuthorizationResult.AuthorizationFailure
        ) {
            const noAccessProps = {
                text: "You do not have access to this resource. Please contact Support if you believe this is incorrect."
            };
            return NoAccessMessage(noAccessProps);
        } else if (
            isAuthenticated === AuthenticationAuthorizationResult.UnknownFailure
        ) {
            return ErrorMessage({});
        } else {
            return (
                <SessionTimeout
                    legacySiteBaseUrl={this.props.app.legacySiteBaseUrl}
                />
            );
        }
    }
}

const mapStateToProps = (state: AppState) => ({
    isAuthenticated: state.auth.isAuthenticated,
    authError: state.auth.error,
    app: state.application
});

const connector = connect(mapStateToProps, actionCreators);

type IReduxProps = ConnectedProps<typeof connector>;
export default withRouter(connector(ProtectedRoute));

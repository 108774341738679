import "react-app-polyfill/stable"; // Polyfill for IE
import "@delaget/react-datez/dist/css/react-datez.css";
import {Provider} from "react-redux";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import App from "App";
import {history} from "dashboardhistory";
import * as registerServiceWorker from "registerServiceWorker";
import "semantic-ui-less/semantic.less"; // this needs to be loaded before our own style
import "scss/style.scss";
import store from "./state/store";
import {createRoot} from "react-dom/client";

//Configure Sentry error tracking
//To support environment transformation with Octopus, the config file must be outside of the
//webpack compiled files. Create React App does not allow imports or requires from outside of the
//source directory. config.json is in the public folder which will not be compiled and will be
//transformed by Octopus for the correct environment.
fetch(process.env.PUBLIC_URL + "/config.json")
    .then((response) => {
        return response.json();
    })
    .then((parsedConfig) => {
        // In production do not sample front end performance, in lower environments, trace them all.
        const tracesSampleRate =
            parsedConfig["environment"] === "Production" ? 0 : 1.0;

        Sentry.init({
            dsn: "https://0a9349320be54f0798eeef48e950981d@sentry.io/1867546",
            integrations: [
                new Integrations.BrowserTracing({
                    routingInstrumentation:
                        Sentry.reactRouterV5Instrumentation(history)
                })
            ],
            environment: parsedConfig["environment"],
            release: "delaget@" + parsedConfig["release"],
            tracesSampleRate: tracesSampleRate
        });
    })
    .catch(() => {
        Sentry.init({
            dsn: "https://0a9349320be54f0798eeef48e950981d@sentry.io/1867546"
        });
    });

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
        <App history={history} />
    </Provider>
);

//Changing this line to .register() turns on offline-first service worker behavior which has some interesting cache implications.
//We probably don't want to do that without a careful discussion of the tradeoffs
//See more info here: https://facebook.github.io/create-react-app/docs/making-a-progressive-web-app
registerServiceWorker.unregister();

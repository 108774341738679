import * as React from "react";
import {Icon, Input} from "semantic-ui-react";

// This component's state is handled from the parent component
// through props.filter and props.changeFilter
interface IProps {
    filter: string;
    changeFilter: (filter: string) => void;
    filterPlaceholder?: string;
    compact?: boolean;
}

class Filter extends React.Component<IProps> {
    public handleValueChange: React.ReactEventHandler<HTMLInputElement> = (e) => {
        const newFilterValue = e.currentTarget.value;
        this.props.changeFilter(newFilterValue);
    };

    public handleIconClick: React.ReactEventHandler<HTMLInputElement> = (e) => {
        this.props.changeFilter("");
    };

    public render(): JSX.Element {
        const {filterPlaceholder, compact} = this.props;
        const inputWidth = compact ? "150px" : "215px";

        return (
            <Input
                icon
                placeholder={
                    filterPlaceholder
                        ? filterPlaceholder
                        : "Filter Restaurant/Area..."
                }
                value={this.props.filter}
                onChange={this.handleValueChange.bind(this)}
            >
                {/*Needed to set the Icon as a child element*/}
                <input style={{width: inputWidth}} />
                <Icon
                    link
                    name={this.props.filter ? "close" : "search"}
                    onClick={this.handleIconClick}
                />
            </Input>
        );
    }
}

export default Filter;

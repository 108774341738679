import * as React from "react";
import {Modal, Header, Button, Grid, Image} from "semantic-ui-react";
import {actionCreators} from "../features/application/state/actions";
import {AppState} from "../state";
import {connect} from "react-redux";
import coachLogo from "../img/logo-delaget-coach.svg";
import freemiumModalImage from "../img/freemium-modal.png";

interface IReduxProps {
    freemiumMarketingModalOpen: boolean;
}

type FreemiumMarketingModalProps = IReduxProps & typeof actionCreators;

export const freemiumMarketingModalContent = (
    <div style={{textAlign: "justify"}}>
        <p
            style={{
                color: "#F47D46",
                fontWeight: "bold"
            }}
        >
            You&#39;ve discovered a paid feature of Delaget Coach
        </p>
        <p>
            When you upgrade to Delaget Coach subscription, you&#39;ll have access to
            the full suite of Delaget Coach features that give you deep insight into
            your business.
        </p>
        <ul className="dashed">
            <li className="dashed">
                Drill down into <u>all</u> metric tiles
            </li>
            <li className="dashed">Assign tasks and track workflows</li>
            <li className="dashed">
                Customize default or per-location performance thresholds
            </li>
            <li className="dashed">Access world-class training and support</li>
        </ul>
    </div>
);

export const freemiumMarketingUrl = "https://www.delaget.com/freemium-upgrade/";

class FreemiumMarketingModal extends React.Component<FreemiumMarketingModalProps> {
    constructor(props: FreemiumMarketingModalProps) {
        super(props);

        this.handleFreemiumMarketingModal =
            this.handleFreemiumMarketingModal.bind(this);
    }

    handleFreemiumMarketingModal() {
        this.props.handleFreemiumMarketingModal(
            !this.props.freemiumMarketingModalOpen
        );
    }

    public render() {
        return (
            <Modal
                className="modal-box"
                open={this.props.freemiumMarketingModalOpen}
                onClose={this.handleFreemiumMarketingModal}
                size="small"
                closeIcon
            >
                <Header style={{padding: "14px 21px 14px 21px"}}>
                    <Image wrapped src={coachLogo} style={{width: "40%"}}></Image>
                </Header>

                <Modal.Content>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column width="10">
                                {freemiumMarketingModalContent}
                            </Grid.Column>
                            <Grid.Column width="6">
                                <Image src={freemiumModalImage} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleFreemiumMarketingModal}>
                        Return to dashboard
                    </Button>
                    <a href={freemiumMarketingUrl}>
                        <Button className={"btn-orange"}>
                            I want to learn more
                        </Button>
                    </a>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    freemiumMarketingModalOpen: state.application.freemiumMarketingModalOpen
});

export default connect(mapStateToProps, actionCreators, (state, actions, props) => ({
    ...state,
    ...actions,
    ...props
}))(FreemiumMarketingModal);

import {Action} from "redux";
import {actionCreator, AppThunkAction} from "../../../state/actions";
import metricApi from "../api/dashboardAPI";
import {IBusinessUnitMetricDataResult, IMetricRequest} from "../api/dashboardModels";
import {IProblemDetails} from "features/application/api/applicationModels";

// this is one way to define actions/action creators --------------------------

export const REQUEST_METRICS = "metrics/REQUEST";
export const RECEIVE_METRICS = "metrics/RECEIVE";

export const FAILURE_METRICS = "metrics/FAILURE";

interface IRequestMetricsAction extends Action<typeof REQUEST_METRICS> {
    request: IMetricRequest;
}

interface IReceiveMetricsAction extends Action<typeof RECEIVE_METRICS> {
    request: IMetricRequest;
    locationMetricDataResult: IBusinessUnitMetricDataResult;
}

interface IFetchMetricsFailure extends Action<typeof FAILURE_METRICS> {
    error: IProblemDetails;
}

const requestMetrics = actionCreator<IRequestMetricsAction>(REQUEST_METRICS);
const receiveMetrics = actionCreator<IReceiveMetricsAction>(RECEIVE_METRICS);

const fetchMetricsFailure = actionCreator<IFetchMetricsFailure>(FAILURE_METRICS);

export type DashboardAction =
    | IRequestMetricsAction
    | IReceiveMetricsAction
    | IFetchMetricsFailure;

// ----------------------------------------------------------------------------

export const actionCreators = {
    fetchMetrics(request: IMetricRequest): AppThunkAction<DashboardAction> {
        return (dispatch, getState) => {
            dispatch(requestMetrics({request}));

            metricApi
                .fetchMetrics(request)
                .then((data) =>
                    dispatch(
                        receiveMetrics({
                            request,
                            locationMetricDataResult: data
                        })
                    )
                )
                .catch((err) => {
                    dispatch(fetchMetricsFailure({error: err}));
                });
        };
    }
};

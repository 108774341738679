import {Component} from "react";
import {Icon, Menu, Modal} from "semantic-ui-react";
import {defaultLegacySiteBaseUrl, sidebarMenuUrl} from "../util/apiEndPointUtil";
import apiUtil from "../util/apiUtil";
import {ErrorMessage, LoadingMessage} from "./SystemMessages";

interface IState {
    showMenuModal: boolean;
    menuModalMountNode: HTMLDivElement;
    menuModalView: {__html: string};
    menuModalFetchRequst: boolean;
    menuModalFetchSuccess: boolean;
    menuModalFetchError: string | undefined;
}

interface IProps {
    legacySiteBaseUrl: string;
}

class HamburgerMenu extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showMenuModal: false,
            menuModalMountNode: document.body as HTMLDivElement,
            menuModalView: {__html: ""},
            menuModalFetchRequst: false,
            menuModalFetchSuccess: false,
            menuModalFetchError: undefined
        };
    }

    componentDidUpdate(): void {
        this.handleMenuModalCloseButtonClickEvent();
    }

    handleMenuModalMountNode = (node: HTMLDivElement): void => {
        this.setState({
            menuModalMountNode: node
        });
    };

    handleMenuIconClick = (): void => {
        const prevShowMenuModal = this.state.showMenuModal;

        this.setState({
            showMenuModal: !prevShowMenuModal
        });

        if (!this.state.menuModalFetchSuccess && !prevShowMenuModal) {
            this.setState({
                menuModalFetchRequst: true
            });

            this.fetchHamburgerMenuApi()
                .then((data) => {
                    this.setState({
                        menuModalFetchError: "",
                        menuModalFetchRequst: false,
                        menuModalFetchSuccess: true,
                        menuModalView: {__html: data}
                    });
                })
                .catch((err) => {
                    this.setState({
                        menuModalFetchError: err.message,
                        menuModalFetchRequst: false,
                        menuModalFetchSuccess: false
                    });
                });
        }
    };

    handleModalOnClose = (): void => {
        this.setState({
            showMenuModal: false
        });
    };

    handleMenuModalCloseButtonClickEvent = (): void => {
        const closeBtn = document.querySelector(".nav-select.close");
        if (closeBtn) {
            closeBtn.addEventListener("click", function (e) {
                const modalDimmer = document.querySelector(".ui.modals.dimmer");
                if (modalDimmer) {
                    simulateMouseClick(modalDimmer);
                }
            });
        }
    };

    //this calls has a text/html content type being returned, hence "string" in Promise<string>
    fetchHamburgerMenuApi = (): Promise<string> => {
        const url =
            (this.props.legacySiteBaseUrl || defaultLegacySiteBaseUrl) +
            sidebarMenuUrl;
        const response = apiUtil.fetchDataWithCredentials<string>(url);

        return response;
    };

    render(): JSX.Element {
        const {
            showMenuModal,
            menuModalMountNode,
            menuModalView,
            menuModalFetchSuccess,
            menuModalFetchRequst
        } = this.state;
        const modalContent = menuModalFetchRequst ? (
            <LoadingMessage />
        ) : menuModalFetchSuccess ? (
            <div dangerouslySetInnerHTML={menuModalView} />
        ) : (
            <ErrorMessage />
        );

        return (
            <>
                <Menu.Item onClick={this.handleMenuIconClick}>
                    <Icon name="align justify" />
                    <div
                        ref={this.handleMenuModalMountNode}
                        className="hamburger-menu-wrapper"
                        id="header"
                    />
                </Menu.Item>

                <Modal
                    size="mini"
                    open={showMenuModal}
                    onClose={this.handleModalOnClose}
                    centered={false}
                    mountNode={menuModalMountNode}
                    className="hamburger-menu-container"
                >
                    <Modal.Content>{modalContent}</Modal.Content>
                </Modal>
            </>
        );
    }
}

export default HamburgerMenu;

//reference https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent
const simulateMouseClick = (elem: Element) => {
    try {
        const event = new MouseEvent("click", {
            bubbles: false,
            cancelable: true,
            view: window
        });
        elem.dispatchEvent(event);
    } catch {
        //polyfill for IE
        //reference: https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent#Polyfill
        const eventIE = document.createEvent("MouseEvents");
        eventIE.initMouseEvent(
            "click",
            false,
            true,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
        );
        elem.dispatchEvent(eventIE);
    }
};

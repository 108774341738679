import {Action} from "redux";
import {actionCreator, AppThunkAction} from "../../../state/actions";
import {IBusinessUnit} from "../../application/api/applicationModels";

// action for assigning the business unit selectin for the Tasks or Visual
export const BUSINESSUNIT_UNSELECT_ALL = "store/UNSELECT_ALL";
export const BUSINESSUNIT_SELECTION_UPDATE = "businessUnit/UPDATE";

export const SURVEY_TAB_ACTIVATE = "surveyTab/ACTIVATE";

// actions for the Metric Details Table checkbox selection
export const METRICDETAIL_TABLE_BUSINESSUNITS_SELECT =
    "metricDetailTable/businessUnitSelect";
export const METRICDETAIL_TABLE_BUSINESSUNIT_UNSELECT_ALL =
    "metricDetailTable/businessUnitUnSelectAll";
export const METRICDETAIL_TABLE_BUSINESSUNITS_UNSELECT =
    "metricDetailTable/businessUnitUnselect";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IBusinessUnitUnselectAllAction
    extends Action<typeof BUSINESSUNIT_UNSELECT_ALL> {}

interface IBusinessUnitSelectionUpdateAction
    extends Action<typeof BUSINESSUNIT_SELECTION_UPDATE> {
    selectedBusinessUnits: IBusinessUnit[];
}

interface ISurveyTabActivateAction extends Action<typeof SURVEY_TAB_ACTIVATE> {
    isActive: boolean;
}

interface IMetricDetailTableBusinessUnitSelect
    extends Action<typeof METRICDETAIL_TABLE_BUSINESSUNITS_SELECT> {
    businessUnits: IBusinessUnit[];
}

interface IMetricDetailsTableBusinessUnitUnselect
    extends Action<typeof METRICDETAIL_TABLE_BUSINESSUNITS_UNSELECT> {
    businessUnits: IBusinessUnit[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IMetricDetailTableBusinessUnitUnselectAll
    extends Action<typeof METRICDETAIL_TABLE_BUSINESSUNIT_UNSELECT_ALL> {}

const unselectAllStores = actionCreator<IBusinessUnitUnselectAllAction>(
    BUSINESSUNIT_UNSELECT_ALL
);
const updateBusinessUnitSelection =
    actionCreator<IBusinessUnitSelectionUpdateAction>(BUSINESSUNIT_SELECTION_UPDATE);

const selectMetricDetailTableBusinessUnit =
    actionCreator<IMetricDetailTableBusinessUnitSelect>(
        METRICDETAIL_TABLE_BUSINESSUNITS_SELECT
    );
const unselectAllMetricDetailTableBusinessUnit =
    actionCreator<IMetricDetailTableBusinessUnitUnselectAll>(
        METRICDETAIL_TABLE_BUSINESSUNIT_UNSELECT_ALL
    );
const unselectMetricDetailTableBusinessUnit =
    actionCreator<IMetricDetailsTableBusinessUnitUnselect>(
        METRICDETAIL_TABLE_BUSINESSUNITS_UNSELECT
    );

const surveyTabActivate =
    actionCreator<ISurveyTabActivateAction>(SURVEY_TAB_ACTIVATE);

export type MetricDetailsAction =
    | IBusinessUnitUnselectAllAction
    | IBusinessUnitSelectionUpdateAction
    | ISurveyTabActivateAction
    | IMetricDetailTableBusinessUnitSelect
    | IMetricDetailTableBusinessUnitUnselectAll
    | IMetricDetailsTableBusinessUnitUnselect;

// ----------------------------------------------------------------------------

export const actionCreators = {
    unselectAllBusinessUnits(): AppThunkAction<MetricDetailsAction> {
        return (dispatch: (action: IBusinessUnitUnselectAllAction) => void) => {
            dispatch(unselectAllStores({}));
        };
    },

    updateBusinessUnitSelection(
        selectedBusinessUnits: IBusinessUnit[]
    ): AppThunkAction<MetricDetailsAction> {
        return (dispatch: (action: IBusinessUnitSelectionUpdateAction) => void) => {
            dispatch(
                updateBusinessUnitSelection({
                    selectedBusinessUnits: selectedBusinessUnits
                })
            );
        };
    },

    surveyTabActivate(isActive: boolean): AppThunkAction<MetricDetailsAction> {
        return (dispatch: (action: ISurveyTabActivateAction) => void) => {
            dispatch(surveyTabActivate({isActive: isActive}));
        };
    },

    selectMetricDetailTableBusinessUnit(
        businessUnits: IBusinessUnit[]
    ): AppThunkAction<MetricDetailsAction> {
        return (
            dispatch: (action: IMetricDetailTableBusinessUnitSelect) => void
        ) => {
            dispatch(
                selectMetricDetailTableBusinessUnit({
                    businessUnits: businessUnits
                })
            );
        };
    },

    unselectAllMetricDetailTableBusinessUnits(): AppThunkAction<MetricDetailsAction> {
        return (
            dispatch: (action: IMetricDetailTableBusinessUnitUnselectAll) => void
        ) => {
            dispatch(unselectAllMetricDetailTableBusinessUnit({}));
        };
    },

    unselectMetricDetailTableBusinessUnit(
        businessUnits: IBusinessUnit[]
    ): AppThunkAction<MetricDetailsAction> {
        return (
            dispatch: (action: IMetricDetailsTableBusinessUnitUnselect) => void
        ) => {
            dispatch(
                unselectMetricDetailTableBusinessUnit({
                    businessUnits: businessUnits
                })
            );
        };
    }
};

import {FC, useEffect, useState} from "react";
// import * as QueryString from "query-string";
import {Header, Dropdown, DropdownItemProps} from "semantic-ui-react";
import {
    Concept,
    DataStatus,
    IClientConcept,
    PageRoleAccess,
    Product
} from "../api/applicationModels";
import {PageCode} from "../../configuration/api/uiConfigurationModels";
import {
    DashboardUrlModel,
    DeliveryProviderPaymentDataUrlModel,
    DowntimeDetailsUrlModel,
    DeliveryOperationsUrlModel,
    EmployeeRetentionUrlModel,
    MetricDetailsUrlModel,
    UrlParams
} from "../../../util/appParamsUtil";
import {Link} from "react-router-dom";
import {filter, orderBy} from "lodash";
import {Xtd} from "features/dashboard/api/dashboardModels";
import {
    BusinessUnitAggregation,
    IThresholdFilters
} from "features/metricDetails/api/metricDetailsModels";
import {connect, ConnectedProps} from "react-redux";
import {AppState} from "state";
import {addDays, dateToString, getXDaysAgo} from "util/dateUtil";
import {actionCreators} from "features/application/state/actions";

interface IProps extends IReduxProps {
    page_PK: number;
    clientConcept: IClientConcept;
    businessUnitId: string;
    date: Date;
    fromDate: Date | null;
    thresholdFilters?: IThresholdFilters;
}

const DrillDownPageSelector: FC<IProps> = ({
    page_PK,
    clientConcept,
    businessUnitId,
    date,
    fromDate,
    thresholdFilters,
    isHomeOfficeUser,
    isDeliveryPageUser,
    clientConceptPages,
    isAdmin,
    getClientConceptPages,
    isRecoveryUser,
    isDeliveryOperationsUser
}) => {
    const [clientConceptPagesDataStatus, setClientConceptPagesDataStatus] =
        useState<DataStatus>(DataStatus.Undefined);

    const dashboardUrlModel = new DashboardUrlModel(
        clientConcept,
        businessUnitId,
        date
    );

    const metricDetailUrlModel = new MetricDetailsUrlModel(
        clientConcept,
        0, // page_PK for specific MetricDetails page is set down below
        businessUnitId,
        fromDate,
        date,
        Xtd.Unknown, // Xtd is set to the DefaultXtd
        BusinessUnitAggregation.Locations, // BusinessUnitAggregation is always set to Locations
        thresholdFilters
    );

    const deliveryProviderPaymentDataUrlModel =
        new DeliveryProviderPaymentDataUrlModel(clientConcept, date, fromDate);

    const employeeRetentionUrlModel = new EmployeeRetentionUrlModel(
        clientConcept,
        businessUnitId,
        null,
        date,
        Xtd.PTD,
        BusinessUnitAggregation.Locations
    );

    useEffect(() => {
        setClientConceptPagesDataStatus(DataStatus.Loading);
        getClientConceptPages(
            () => setClientConceptPagesDataStatus(DataStatus.Loaded),
            () => setClientConceptPagesDataStatus(DataStatus.Failed)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page_PK]);

    let drilldownOptions: DropdownItemProps[] = [];

    const drillDownPages = filter(
        clientConceptPages,
        (p) =>
            p.clientConcept_PK === clientConcept.clientConcept_PK &&
            p.isNavigable === true
    );

    for (let i = 0; i < drillDownPages.length; i++) {
        const page = drillDownPages[i];

        // If user doesn't have access to the Delivery product don't show them the Delivery pages as an option
        if (page.product === Product.Delivery && !isDeliveryPageUser) {
            continue;
        }

        // SOL-4631: For now, only show the Delivery Loss page for Taco and IHOP clients
        if (
            page.pageCode === PageCode.DeliveryLoss &&
            clientConcept.concept !== Concept.Taco &&
            clientConcept.concept !== Concept.IHOP
        ) {
            continue;
        }

        // SOL-4631: For now, only enable the Delivery Reconciliation page for selected clients
        if (page.pageCode === PageCode.DeliveryProviderPaymentData) {
            if (
                !(
                    // For these concepts or clients add the page
                    (
                        clientConcept.concept === Concept.Taco ||
                        clientConcept.concept === Concept.IHOP ||
                        clientConcept.clientConcept_PK === 2243 || // AHM	PERKINS	19
                        clientConcept.clientConcept_PK === 2268 || // AHM	Huddle House	204
                        clientConcept.clientConcept_PK === 2275 || // AHM	Papa Corazon's Quesadillas
                        clientConcept.clientConcept_PK === 2299 || // AHM	Bakery By Perkins
                        clientConcept.clientConcept_PK === 2175 || // GPR	FIVE GUYS	48
                        clientConcept.clientConcept_PK === 2120 || // HISSHO	Hissho Sushi	313
                        clientConcept.clientConcept_PK === 1039 || // HMC	KFC	3
                        clientConcept.clientConcept_PK === 2250 || // HMC	Dairy Queen	32
                        clientConcept.clientConcept_PK === 1764 || // JEM	PIZZA HUT	2
                        clientConcept.clientConcept_PK === 2276 || // MAMBO	Mambo Seafood	325
                        clientConcept.clientConcept_PK === 2055 || // MANNAT	Jack in the Box	73
                        clientConcept.clientConcept_PK === 2122 || // NEWKS	Newk's Eatery	185
                        clientConcept.clientConcept_PK === 2130 || // ROCK	Jack in the Box	73
                        clientConcept.clientConcept_PK === 1922 || // SLIMBBV	Slim Chickens	51
                        clientConcept.clientConcept_PK === 2290 || // SLIMCHXC	Slim Chickens
                        clientConcept.clientConcept_PK === 590 || // TBPTL	KFC	3
                        clientConcept.clientConcept_PK === 2111
                    ) // PRIMB	Primanti Bros	308
                )
            ) {
                continue;
            }
        }

        // If user is not a Home Office pages user don't show them the Home Office pages as an option
        if (
            !isAdmin &&
            page.pageRoleAccess === PageRoleAccess.HomeOffice &&
            !isHomeOfficeUser
        ) {
            continue;
        }
        // If user is not an Admin pages user don't show them the Admin pages as an option
        if (page.pageRoleAccess === PageRoleAccess.Admin && !isAdmin) {
            continue;
        }

        // If user doesn't have access to the Recovery product, don't show them the Recovery page as an option
        if (page.product === Product.Recovery && !isRecoveryUser) {
            continue;
        }

        // If user doesn't have access to the Delivery Operations product, don't show them the Delivery Operations pages as an option
        if (
            page.product === Product.DeliveryOperations &&
            !isDeliveryOperationsUser
        ) {
            continue;
        }

        let url: string;

        if (page.pageCode === PageCode.Dashboard) {
            url = dashboardUrlModel.url;
        } else if (page.pageCode === PageCode.DeliveryProviderPaymentData) {
            url = deliveryProviderPaymentDataUrlModel.url;
        } else if (page.pageCode === PageCode.EmployeeRetention) {
            url = employeeRetentionUrlModel.url;
        } else if (page.pageCode === PageCode.DowntimeDetails) {
            const deliveryOpsFromDate = fromDate ?? addDays(date, -30);

            const urlModel = new DowntimeDetailsUrlModel(
                clientConcept,
                businessUnitId,
                deliveryOpsFromDate,
                date,
                BusinessUnitAggregation.Locations
            );
            url = urlModel.url;
        } else if (page.pageCode === PageCode.DeliveryOperations) {
            const fiveDaysAgo = getXDaysAgo(5);
            let deliveryOperationsFromDate: Date;
            let deliveryOperationsToDate: Date;

            if (date > fiveDaysAgo) {
                // We don't have data for the last 4 days, so default to 5 days ago
                deliveryOperationsToDate = fiveDaysAgo;
                deliveryOperationsFromDate = addDays(deliveryOperationsToDate, -7);
            } else {
                deliveryOperationsToDate = date;
                deliveryOperationsFromDate =
                    fromDate ?? addDays(deliveryOperationsToDate, -7);
            }

            const urlModel = new DeliveryOperationsUrlModel(
                clientConcept,
                businessUnitId,
                deliveryOperationsFromDate,
                deliveryOperationsToDate,
                BusinessUnitAggregation.Locations
            );
            url = urlModel.url;
        } else {
            url = metricDetailUrlModel.generateUrlWithParameters([
                {
                    code: UrlParams.Page_PK,
                    value: page.page_PK.toString()
                },
                {
                    code: UrlParams.Xtd,
                    value: page.defaultXtd
                },
                // Remove the threshold filters when switching between pages
                {
                    code: UrlParams.ThresholdFilters,
                    value: undefined,
                    isQueryStringParameter: true
                },
                {
                    code: UrlParams.FromDate,
                    value: undefined,
                    isQueryStringParameter: true
                }
            ]);
        }

        // Add page role access check
        drilldownOptions.push({
            key: page.page_PK,
            text: page.pageName,
            value: page.page_PK,
            as: Link,
            to: url
        });
    }

    // Order by page name
    drilldownOptions = orderBy(drilldownOptions, (o) =>
        o.text?.toString().toLowerCase()
    );

    // Add "add page" option to the dropdown. It should always be the first option (after other options are sorted alphabetically by page name)
    if (isAdmin) {
        drilldownOptions.unshift({
            key: "addPageOption",
            text: "Add Page",
            value: "addPageOption",
            as: Link,
            to: `/dashboard/pageconfiguration/${dateToString(date)}`,
            icon: "add"
        });
    }

    return (
        <Header size="small">
            <Dropdown
                className="delaget-ui-header-dropdown"
                selection
                options={drilldownOptions}
                header={<Dropdown.Header icon="exchange" content="Change Page" />}
                value={page_PK}
                scrolling={false}
                loading={clientConceptPagesDataStatus === DataStatus.Loading}
                error={clientConceptPagesDataStatus === DataStatus.Failed}
            ></Dropdown>
        </Header>
    );
};

const mapStateToProps = (state: AppState) => ({
    isDeliveryPageUser:
        state.application.doesUserHaveAccessToDeliveryPageForSelectedConcept,
    isHomeOfficeUser: state.application.userInfo.isHomeOfficeUser,
    clientConceptPages: state.application.clientConceptPages,
    isAdmin: state.application.userInfo.isAdmin,
    isRecoveryUser: state.application.doesUserHaveAccessToRecoveryForSelectedConcept,
    isDeliveryOperationsUser:
        state.application.doesUserHaveAccessToDeliveryOperationsForSelectedConcept
});

const connector = connect(mapStateToProps, actionCreators);

type IReduxProps = ConnectedProps<typeof connector>;

export default connector(DrillDownPageSelector);

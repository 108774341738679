import * as React from "react";
import moment from "moment";
import * as QueryString from "query-string";
import {find, includes, isFinite, startsWith, values} from "lodash";
import {
    coachFreemiumPageCodes,
    deliveryFreemiumPageCodes,
    detectFreemiumPageCodes,
    IClientConcept,
    IClientConceptPage
} from "features/application/api/applicationModels";
import {initialClientConcept} from "features/application/state/reducer";
import {Xtd} from "features/dashboard/api/dashboardModels";
import {ErrorMessage as Error} from "components/SystemMessages";
import {
    BusinessUnitAggregation,
    maximumCustomDateRange
} from "features/metricDetails/api/metricDetailsModels";
import {
    isGuid,
    RouteType,
    getNewClientConceptFromSelectedBusinessUnitId,
    isDate,
    IAppRouteProps,
    UrlParams,
    getPage_PKForClientConceptAndPageCode
} from "util/appParamsUtil";
import {dateToString, getDateFromString} from "util/dateUtil";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect, ConnectedComponent, ConnectedProps} from "react-redux";
import {actionCreators} from "features/application/state/actions";
import {AppState} from "state";
import {ErrorMessageContent} from "util/errorUtil";
import FreemiumMarketingSegment from "components/FreemiumMarketingSegment";
import {NoAccessMessage} from "components/SystemMessages";
import {getCurrentSelectedBusinessUnitId} from "util/stateUtil";
import {PageCode} from "features/configuration/api/uiConfigurationModels";

interface IProps extends IReduxProps {
    wrappedComponent:
        | React.LazyExoticComponent<ConnectedComponent<any, any>>
        | React.LazyExoticComponent<React.ComponentClass<any, any>>
        | React.LazyExoticComponent<React.FC>
        | React.ComponentClass<any, any>
        | ConnectedComponent<any, any>;
    isFullVersionOnly: boolean;
    isAdminOnly: boolean;
    isAdminOrRecoveryAdminOnly: boolean;
    isCoachDetect?: boolean;
    isDeliveryOperations?: boolean;
    routeType?: RouteType;
    businessUnitId: string;
}

type WithRouteParametersProps = IProps & RouteComponentProps<IAppRouteProps>;

class WithRouteParameters extends React.Component<WithRouteParametersProps> {
    constructor(props: WithRouteParametersProps) {
        super(props);

        this.updateClientConcept = this.updateClientConcept.bind(this);
        this.updateBusinessUnitId = this.updateBusinessUnitId.bind(this);
        this.updateFromDate = this.updateFromDate.bind(this);
        this.updateDate = this.updateDate.bind(this);
        this.updateBusinessUnitAggregation =
            this.updateBusinessUnitAggregation.bind(this);
        this.updateXtd = this.updateXtd.bind(this);
    }

    public componentDidMount() {
        // Update redux parameters when this components mounts
        this.syncUrlParametersWithRedux();
    }

    componentDidUpdate(prevProps: WithRouteParametersProps) {
        const url = this.props.location.pathname + this.props.location.search;
        const prevUrl = prevProps.location.pathname + prevProps.location.search;

        // Sync with redux when url changes (note that this doesn't trigger when the page loads for the first
        // time. Therefore, the code above.)
        // This needs to be based on the pathName and also the RouteType
        if (url !== prevUrl || this.props.routeType !== prevProps.routeType) {
            this.syncUrlParametersWithRedux();
        }
    }

    updateClientConcept(conceptUrlName: string | undefined): IClientConcept {
        let newClientConcept = this.props.app.selectedClientConcept;

        if (
            conceptUrlName !== undefined &&
            conceptUrlName !== this.props.app.selectedClientConcept.conceptUrlName
        ) {
            // Find if any available concept matches the concept in the url
            newClientConcept =
                find(
                    this.props.app.availableClientConcepts,
                    (c) => c.conceptUrlName === conceptUrlName
                ) || initialClientConcept;
        }

        // If the url doesn't contain client concept unit id and it's not already in redux yet, then we set it here.
        // This can happen when you go to /dashboard, etc.
        if (
            conceptUrlName === undefined &&
            this.props.app.selectedClientConcept.conceptUrlName === ""
        ) {
            newClientConcept =
                getNewClientConceptFromSelectedBusinessUnitId(
                    this.props.app.selectedClientConcept.clientConcept_PK,
                    this.props.app.availableClientConcepts,
                    this.props.app.clientTreeHierarchy,
                    this.props.businessUnitId
                ) || initialClientConcept;

            //Detect has special considerations because the Detect for the ClientConcept for the userDefaultBusinessUnitId might not be enabled, in this case
            //we will send them to Detect for the first Concept in this.props.conceptsforCoachDetect
            if (
                this.props.isCoachDetect &&
                !this.props.app.availableClientConceptsForCoachDetect.some(
                    (item) =>
                        item.clientConcept_PK === newClientConcept.clientConcept_PK
                )
            ) {
                newClientConcept =
                    this.props.app.availableClientConceptsForCoachDetect[0];
            }
        }

        return newClientConcept;
    }

    updateBusinessUnitId(
        businessUnitId: string | undefined,
        clientConcept: IClientConcept | undefined,
        routeType: RouteType | undefined
    ): string {
        let newBusinessUnitId = this.props.app.currentSelectedBusinessUnitId;

        if (
            clientConcept &&
            businessUnitId &&
            businessUnitId !== this.props.app.currentSelectedBusinessUnitId
        ) {
            // The businessUnit needs to belongs to the correct concept. Note that we set concept first,
            // and based on that we determines if we can change businessUnitId accordingly
            const bu = find(
                this.props.app.clientTreeHierarchy,
                (c) =>
                    c.businessUnitId === businessUnitId &&
                    c.clientConcept_PK === clientConcept.clientConcept_PK
            );

            // If the businessUnitId is invalid (not Guid or doesn't exist in the hierarchy)
            if (isGuid(businessUnitId) && bu) {
                newBusinessUnitId = bu.businessUnitId;
            }
        }

        // If the url doesn't contain businessUnitId and it's not already in redux yet, then we set it here.
        // This can happen when you go to /dashboard, /dashboard/monitors/<client_concept>/search etc.
        if (
            clientConcept &&
            businessUnitId === undefined &&
            this.props.app.currentSelectedBusinessUnitId === ""
        ) {
            let bu = this.props.businessUnitId;

            // If the page is Detect Search (/dashboard/monitors/<client_concept>/search), that page doesn't have businessUnitId in the url
            // In that case, we will select the root group of the selected concept
            if (routeType && routeType === RouteType.MONITORS_SEARCH) {
                // Find business unit for given userDefaultBusinessUnitId
                bu = clientConcept.rootGroupId;
            }

            if (isGuid(bu)) {
                newBusinessUnitId = bu;
            }
        }

        return newBusinessUnitId;
    }

    updateFromDate(fromDate: string | undefined): Date | null {
        let newFromDate = this.props.app.fromDate;

        // if the fromDate isn't set or invalid then change redux value to null
        if (fromDate === undefined && this.props.app.fromDate) {
            return null;
        }

        if (isDate(fromDate) && fromDate !== dateToString(this.props.app.fromDate)) {
            newFromDate = getDateFromString(fromDate);
        }

        return newFromDate;
    }

    updateDate(date: string | undefined): Date {
        let newDate = this.props.app.date;

        // Note that date has a default value set directly in redux
        if (isDate(date) && date !== dateToString(this.props.app.date)) {
            newDate = getDateFromString(date);
        }

        return newDate;
    }

    updateBusinessUnitAggregation(
        businessUnitAggregation: string | undefined
    ): BusinessUnitAggregation {
        let newBusinessUnitAggregation = this.props.app.businessUnitAggregation;

        if (
            businessUnitAggregation &&
            businessUnitAggregation !== this.props.app.businessUnitAggregation
        ) {
            if (includes(values(BusinessUnitAggregation), businessUnitAggregation)) {
                newBusinessUnitAggregation =
                    businessUnitAggregation as BusinessUnitAggregation;
            } else {
                newBusinessUnitAggregation = BusinessUnitAggregation.Unknown;
            }
        }

        return newBusinessUnitAggregation;
    }

    updateXtd(xtd: string | undefined): Xtd {
        let newXtd = this.props.app.xtd;

        if (xtd && xtd !== this.props.app.xtd) {
            if (includes(values(Xtd), xtd)) {
                newXtd = xtd as Xtd;
            } else {
                newXtd = Xtd.Unknown;
            }
        }

        return newXtd;
    }

    /**
     * This method syncs url parameters with their equivalents in redux
     */
    syncUrlParametersWithRedux() {
        // Get url params
        // eslint-disable-next-line no-case-declarations
        // !Note that any url parameter which isn't currently present in the url is set to undefined!
        const {
            conceptUrlName,
            businessUnitId,
            date,
            // eslint-disable-next-line prefer-const
            xtd,
            // eslint-disable-next-line prefer-const
            businessUnitAggregation
        } = this.props.match.params;

        // FromDate parameter comes from the query string (not directly the url)
        const fromDateParam = QueryString.parse(this.props.location.search)[
            UrlParams.FromDate
        ];

        let fromDate: string | undefined = undefined;
        if (fromDateParam && typeof fromDateParam === "string") {
            fromDate = fromDateParam;
        }

        // Note that not all parameters are always needed for every page.
        // The methods below have checks to determine if a parameter needs to be updated.
        // Switching concepts and businessUnitIds is little tricky because they are related. The only valid combination
        // is if a businessUnit belongs to a ClientConcept. What we do here, is we return the current concept from
        // updateClientConcept() and pass it to updateBusinessUnitId() and we update businessUnitId only if it belongs
        // to the right concept
        const newClientConcept = this.updateClientConcept(conceptUrlName);
        const newBusinessUnitId = this.updateBusinessUnitId(
            businessUnitId,
            newClientConcept,
            this.props.routeType
        );
        const newFromDate = this.updateFromDate(fromDate);
        const newDate = this.updateDate(date);
        const newBusinessUnitAggregation = this.updateBusinessUnitAggregation(
            businessUnitAggregation
        );
        const newXtd = this.updateXtd(xtd);

        this.props.changeAppParameters(
            newClientConcept,
            newBusinessUnitId,
            newFromDate,
            newDate,
            newXtd,
            newBusinessUnitAggregation
        );
    }

    render() {
        const {
            app,
            isAdminOnly,
            isAdminOrRecoveryAdminOnly,
            isFullVersionOnly,
            isCoachDetect,
            isDeliveryOperations,
            routeType
        } = this.props;

        // The following parameters are not stored in redux, so get them from the url
        // Note: to prevent race conditions we don't put page_PK into Redux. Also, we are currently
        // trying not to put URL params into the url anymore
        const {monitorId, daysToLookBack} = this.props.match.params;
        const page_PK_UrlParam = this.props.match.params.page_PK;

        let page_PK = Number(this.props.match.params.page_PK);

        // Dashboard page is special and doesn't require for the parameters to be in the ULR
        // Find the page_PK if that's the case
        if (page_PK_UrlParam === undefined && routeType === RouteType.DASHBOARD) {
            page_PK = getPage_PKForClientConceptAndPageCode(
                app.clientConceptPages,
                app.selectedClientConcept.clientConcept_PK,
                PageCode.Dashboard
            );
        }

        const page: IClientConceptPage | undefined = find(
            app.clientConceptPages,
            (p) => p.page_PK === Number(page_PK)
        );
        const pageCode: PageCode = page?.pageCode || PageCode.Unknown;

        let hasAccessToFullVersionPage = false;
        if (includes(coachFreemiumPageCodes, pageCode)) {
            hasAccessToFullVersionPage = true;
        } else if (
            includes(deliveryFreemiumPageCodes, pageCode) &&
            app.doesUserHaveAccessToDeliveryPageForSelectedConcept
        ) {
            hasAccessToFullVersionPage = true;
        } else if (
            includes(detectFreemiumPageCodes, pageCode) &&
            app.doesUserHaveAccessToDetectForSelectedConcept
        ) {
            hasAccessToFullVersionPage = true;
        }

        // The order matters here, the access checks (freemium and Coach Detect) should happen
        // before checking the parameters
        if (
            isFullVersionOnly &&
            app.userInfo.isFreemium &&
            !hasAccessToFullVersionPage
        ) {
            return <FreemiumMarketingSegment />;
        } else if (
            isAdminOrRecoveryAdminOnly &&
            !(app.userInfo.isAdmin || app.userInfo.isRecoveryAdmin)
        ) {
            return <NoAccessMessage text={ErrorMessageContent.NoPageAccess} />;
        } else if (isAdminOnly && !app.userInfo.isAdmin) {
            return <NoAccessMessage text={ErrorMessageContent.NoPageAccess} />;
        } else if (
            isCoachDetect &&
            find(
                app.availableClientConceptsForCoachDetect,
                (c) => c.concept === app.selectedClientConcept.concept
            ) === undefined
        ) {
            return (
                <NoAccessMessage text={ErrorMessageContent.NoCoachDetectAccess} />
            );
        } else if (
            isDeliveryOperations &&
            find(
                app.availableClientConceptsForDeliveryOperations,
                (c) => c.concept === app.selectedClientConcept.concept
            ) === undefined
        ) {
            return (
                <NoAccessMessage
                    text={ErrorMessageContent.NoDeliveryOperationsAccess}
                />
            );
        } else if (
            // For the app to work, we need to check if each page has required parameters set in redux.
            // Checking if required params are set guarantees that components which need them on mount will have them.
            !arePageParametersValidBasedOnRouteType(
                routeType,
                Number(page_PK),
                pageCode,
                app.selectedClientConcept.conceptUrlName,
                app.currentSelectedBusinessUnitId,
                app.fromDate,
                app.date,
                app.xtd,
                app.businessUnitAggregation,
                monitorId,
                daysToLookBack
            )
        ) {
            return <Error text={ErrorMessageContent.WrongAppParameters} />;
        } else if (
            // Check if client concept is synced between the url and redux before rendering the underlying component
            !isClientConceptSyncedBetweenUrlAndRedux(
                this.props.match.params,
                app.selectedClientConcept.conceptUrlName
            )
        ) {
            return null;
        } else {
            // render Wrapped component
            return <this.props.wrappedComponent {...this.props} />;
        }
    }
}

const mapStateToProps = (state: AppState) => ({
    app: state.application,
    businessUnitId: getCurrentSelectedBusinessUnitId(state)
});

const connector = connect(mapStateToProps, actionCreators);

type IReduxProps = ConnectedProps<typeof connector>;

export default withRouter(connector(WithRouteParameters));

function arePageParametersValidBasedOnRouteType(
    routeType: RouteType | undefined,
    page_PK: number,
    pageCode: PageCode,
    conceptUrlName: string,
    businessUnitId: string,
    fromDate: Date | null,
    date: Date,
    xtd: Xtd,
    businessUnitAggregation: BusinessUnitAggregation,
    // monitorId and daysToLookBack parameters don't come from redux but directly from the url.
    // For convinience, pass them in as strings directly and do the checks here
    monitorId: string,
    daysToLookBack: string
) {
    switch (routeType) {
        case RouteType.DASHBOARD:
            if (
                conceptUrlName &&
                businessUnitId &&
                date &&
                isFinite(page_PK) &&
                // pageCode for the given page_PK must be Dashboard
                pageCode === PageCode.Dashboard
            ) {
                return true;
            }
            return false;

        case RouteType.METRIC_DETAILS:
        case RouteType.EMPLOYEE_RETENTION:
            if (
                conceptUrlName &&
                businessUnitId &&
                date &&
                xtd &&
                businessUnitAggregation &&
                isFinite(page_PK) &&
                // pageCode for the given page_PK must be one of the Metric Details pages
                // or Employee Retention page
                (pageCode === PageCode.EmployeeRetention ||
                    startsWith(pageCode.toLowerCase(), "metricdetail_"))
            ) {
                if (xtd === Xtd.CUSTOM) {
                    // When xtd = custom is selected we must have a valid fromDate
                    if (!fromDate) {
                        return false;
                    } else if (
                        // The maximum allowed date range is currently $maximumCustomDateRange days. Also check if fromDate < date
                        moment(date).diff(moment(fromDate), "days") >=
                            maximumCustomDateRange ||
                        moment(date).diff(moment(fromDate), "days") < 0
                    ) {
                        return false;
                    }
                } else {
                    // For non-custom xtds the fromDate must be null (note that in the API calls it will be set to @date)
                    if (fromDate) {
                        return false;
                    }
                }

                return true;
            }
            return false;

        case RouteType.USER_SETTINGS:
            if (conceptUrlName && businessUnitId) {
                return true;
            }
            return false;

        case RouteType.MONITORS:
            if (conceptUrlName && businessUnitId) {
                return true;
            }
            return false;

        case RouteType.DIFFERENTIAL_PAY:
            if (conceptUrlName && businessUnitId) {
                return true;
            }
            return false;

        case RouteType.MONITOR_DETAILS:
            if (
                conceptUrlName &&
                businessUnitId &&
                monitorId &&
                daysToLookBack &&
                isFinite(+monitorId) &&
                isFinite(+daysToLookBack)
            ) {
                return true;
            }
            return false;

        case RouteType.CREATE_MONITOR:
            if (monitorId === undefined && conceptUrlName) {
                return true;
            } else if (monitorId && isFinite(+monitorId) && conceptUrlName) {
                return true;
            }
            return false;

        case RouteType.HOME_OFFICE:
            if (
                conceptUrlName &&
                date &&
                fromDate &&
                isFinite(page_PK) &&
                // pageCode for the given page_PK must be DeliveryProviderPaymentData
                pageCode === PageCode.DeliveryProviderPaymentData
            ) {
                return true;
            }
            return false;

        case RouteType.DELIVERY_OPERATIONS:
            if (pageCode === PageCode.DowntimeDetails && date && fromDate) {
                return true;
            } else if (
                pageCode === PageCode.DeliveryOperations &&
                conceptUrlName &&
                businessUnitId &&
                date &&
                fromDate
            ) {
                return true;
            }

            return false;

        default:
            if (conceptUrlName) {
                return true;
            }
            return false;
    }
}

function isClientConceptSyncedBetweenUrlAndRedux(
    urlParams: IAppRouteProps,
    conceptUrlNameFromRedux: string
) {
    const {conceptUrlName: conceptUrlNameFromUrl} = urlParams;

    // If there is no Concept in the url then just return true. This is here to support /dashboard working without any additional url parameters
    if (!conceptUrlNameFromUrl) {
        return true;
    }

    // Compare Concept from the url with Concept from redux. This takes care of the case when Concept in redux is being updated on a page change
    // and the page mounts with the old Concept causing issues like double API calls. Basically, wait for the redux Concept to match
    // the url Concept before rendering the underlying component.
    if (conceptUrlNameFromUrl && conceptUrlNameFromUrl !== conceptUrlNameFromRedux) {
        return false;
    }

    return true;
}

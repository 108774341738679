import moment from "moment";
import * as React from "react";
import ReactDatez from "@delaget/react-datez";
import {connect} from "react-redux";
import {history} from "dashboardhistory";
import {actionCreators} from "features/application/state/actions";
import {AppState} from "state";
import {IAppRouteProps, UrlModel, UrlParams} from "util/appParamsUtil";
import {dateToString, getDateFromString} from "util/dateUtil";
import {Ref} from "semantic-ui-react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {IClientConcept} from "features/application/api/applicationModels";

interface IReduxProps {
    date: Date;
    clientConcept: IClientConcept;
}

interface IProps {
    urlModel: UrlModel;
}

type DatePickerProps = IReduxProps &
    IProps &
    RouteComponentProps<IAppRouteProps> &
    typeof actionCreators;

class DatePicker extends React.Component<DatePickerProps> {
    private datePicker: HTMLDivElement = {} as any as HTMLDivElement;
    private observer = {} as any as MutationObserver;

    constructor(props: DatePickerProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleDatePickerClick = this.handleDatePickerClick.bind(this);
        this.labelPeriodStartDates = this.labelPeriodStartDates.bind(this);

        // Config what events we want to monitor in the observers below
        const bodyConfig = {
            attributes: true,
            childList: true,
            subtree: false,
            attributeFilter: ["class"]
        };
        const calConfig = {
            characterData: true,
            attributes: false,
            childList: false,
            subtree: true
        };

        // The date picker library we are using (react-datez) appends "date-open" class to the body when the calendar is open.
        // We use this event to highlight the selected day in the calendar.
        this.observer = new MutationObserver((m) => {
            for (let i = 0; i < m.length; i++) {
                const el = m[i].target as HTMLElement;
                if (el.classList && el.classList.contains("date-open")) {
                    this.handleDatePickerClick();
                    this.labelPeriodStartDates();

                    // Add mutation observer to rdatez to track changes in the month within the calendar element.
                    // This is used to update period start dates upon month change
                    this.observer.observe(
                        document.getElementsByClassName("rdatez-calendar-title")[0],
                        calConfig
                    );
                    // The rdatez-calendar-title element above is the only text node being observed by the mutation observer
                    // We use that information to make sure we are looking at the rdatez-calendar-title and label periods upon change
                } else if (el.nodeType === Node.TEXT_NODE) {
                    this.labelPeriodStartDates();
                }
            }
        });
        this.observer.observe(document.body, bodyConfig);
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    handleDatePickerClick() {
        // The date picker library we are using (react-datez) has an issue that the first time the calendar is rendered
        // the selected day isn't highlighted in the date picker after opening it.
        // This fixes the issue by appending a class "selected-day" to the correct day.
        const date = moment(this.props.date);

        // The day we would like to highlight has the following class
        const className =
            "rdatez-day" +
            " " +
            date.format("M") +
            "-" +
            date.format("YYYY") +
            "-" +
            date.format("D");

        const dayElement = this.datePicker.getElementsByClassName(className)[0];

        if (dayElement) {
            dayElement.classList.add("selected-day");
        }
    }

    labelPeriodStartDates() {
        const periodStartDates = this.props.clientConcept.periodStartDates;
        for (let i = 0; i < periodStartDates.length; i++) {
            const date = moment(periodStartDates[i].calDt);
            const className =
                "rdatez-day" +
                " " +
                date.format("M") +
                "-" +
                date.format("YYYY") +
                "-" +
                date.format("D");

            const dayElement = this.datePicker.getElementsByClassName(className)[0];

            if (dayElement && dayElement.children.length === 0) {
                dayElement.classList.add("start-of-period");
                const periodNumber = document.createElement("div");
                periodNumber.innerText = "P" + periodStartDates[i].perOfYrNbr;
                periodNumber.classList.add("period-counter");
                dayElement.appendChild(periodNumber);
            }
        }
    }

    handleChange(date: string) {
        // only update the url here, Router will update redux correspondingly
        const newUrl = this.props.urlModel.generateUrlWithParameters([
            // date is a string but is not formatted in the way we want. So, we convert it back to date and then format it.
            {
                code: UrlParams.Date,
                value: dateToString(getDateFromString(date))
            }
        ]);

        history.push(newUrl);
    }

    public render() {
        return (
            <Ref innerRef={(node: HTMLDivElement) => (this.datePicker = node)}>
                <div className="rdatez-picker-custom">
                    <ReactDatez
                        name="dateInput"
                        inputClassName="rdatez-input-custom"
                        allowPast={true}
                        allowFuture={false}
                        dateFormat="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY"
                        handleChange={this.handleChange}
                        value={dateToString(this.props.date)}
                        defaultMonth={dateToString(this.props.date)}
                    />
                </div>
            </Ref>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    date: state.application.date,
    clientConcept: state.application.selectedClientConcept
});

export default withRouter(
    connect(mapStateToProps, actionCreators, (state, actions, props) => ({
        ...state,
        ...actions,
        ...props
    }))(DatePicker)
);

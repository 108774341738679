import {ErrorMessage, LoadingMessage} from "components/SystemMessages";
import uiConfigurationApi from "features/configuration/api/uiConfigurationAPI";
import React, {FC, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {
    Header,
    Segment,
    Dropdown,
    DropdownProps,
    DropdownItemProps
} from "semantic-ui-react";
import {AppState} from "../../../../state";
import {IModuleMetricDefinition} from "../../../dashboard/api/dashboardModels";
import {
    DataStatus,
    IClientConceptMetricDefinition,
    PageRoleAccess,
    Product
} from "../../api/applicationModels";
import ModuleMetricForm from "./ModuleMetricForm";
import {filter, find} from "lodash";

interface IProps extends IReduxProps {
    page_PK: number;
}

const ModuleMetricConfiguration: FC<IProps> = ({
    clientConcept,
    availableClientConceptsForCoachDetect,
    page_PK,
    clientConceptPages,
    isDeliveryPageUser,
    isHomeOfficeUser
}) => {
    const [dataStatus, setDataStatus] = useState<DataStatus>(DataStatus.Undefined);

    const [availableClientConceptMetrics, setAvailableClientConceptMetrics] =
        useState<IClientConceptMetricDefinition[]>([]);

    const [clientConceptPageModuleMetrics, setClientConceptPageModuleMetrics] =
        useState<IModuleMetricDefinition[]>([]);

    const [selectedPage_PK, setSelectedPage_PK] = useState<number>(page_PK);

    const handlePageSelection = (
        e: React.SyntheticEvent<HTMLElement>,
        data: DropdownProps
    ) => {
        if (page_PK !== (data.value as number)) {
            setSelectedPage_PK(data.value as number);
        }
    };

    useEffect(() => {
        setDataStatus(DataStatus.Loading);
        uiConfigurationApi
            .getClientConceptPageModuleMetricConfiguration(
                clientConcept.clientConcept_PK
            )
            .then((data) => {
                setClientConceptPageModuleMetrics(
                    data.sort(
                        (a, b) =>
                            a.page_PK - b.page_PK ||
                            a.moduleType.localeCompare(b.moduleType) ||
                            a.sortOrder - b.sortOrder
                    )
                );
            })
            .then(() => {
                uiConfigurationApi
                    .getClientConceptMetricDefinitions(
                        clientConcept.clientConcept_PK
                    )
                    .then((data) => {
                        setAvailableClientConceptMetrics(
                            data.sort((a, b) =>
                                a.metricCode.localeCompare(b.metricCode)
                            )
                        );
                        setDataStatus(DataStatus.Loaded);
                    });
            })
            .catch((e) => {
                setDataStatus(DataStatus.Failed);
            });
    }, [clientConcept]);

    //only show Detect options in the dropdown if client concept has Detect
    const availablePageOptions: DropdownItemProps[] = [];

    const pagesAvailableForConfiguration = filter(
        clientConceptPages,
        (p) => p.clientConcept_PK === clientConcept.clientConcept_PK
    );

    for (let i = 0; i < pagesAvailableForConfiguration.length; i++) {
        const page = pagesAvailableForConfiguration[i];
        // If user doesn't have access to the Delivery product don't show them the Delivery pages as an option
        if (page.product === Product.Delivery && !isDeliveryPageUser) {
            continue;
        }
        // If user is not a Home Office pages user don't show them the Home Office pages as an option
        if (page.pageRoleAccess === PageRoleAccess.HomeOffice && !isHomeOfficeUser) {
            continue;
        }
        // If user doesn't have access to the Detect product don't show them the Detect pages as an option
        if (
            page.product === Product.Detect &&
            !find(
                availableClientConceptsForCoachDetect,
                (cc) => cc.clientConcept_PK === page.clientConcept_PK
            )
        ) {
            continue;
        }

        availablePageOptions.push({
            key: page.page_PK,
            text: page.pageName,
            value: page.page_PK
        });
    }

    availablePageOptions.push({
        key: 0,
        text: "ALL",
        value: 0
    });

    return (
        <>
            <Header>Module Metrics </Header>
            <Dropdown
                placeholder="Select a Page"
                search
                selection
                options={availablePageOptions}
                onChange={handlePageSelection}
                defaultValue={selectedPage_PK}
            />

            {dataStatus === DataStatus.Loaded && (
                <Segment clearing={true}>
                    <ModuleMetricForm
                        clientConcept_PK={clientConcept.clientConcept_PK}
                        clientConceptPageModuleMetrics={
                            clientConceptPageModuleMetrics
                        }
                        availableClientConceptMetrics={availableClientConceptMetrics}
                        selectedPage_PK={selectedPage_PK}
                    />
                </Segment>
            )}
            {dataStatus === DataStatus.Loading && <LoadingMessage />}
            {dataStatus === DataStatus.Failed && <ErrorMessage />}
        </>
    );
};

const mapStateToProps = (state: AppState) => ({
    clientConcept: state.application.selectedClientConcept,
    availableClientConceptsForCoachDetect:
        state.application.availableClientConceptsForCoachDetect,
    clientConceptPages: state.application.clientConceptPages,
    isDeliveryPageUser:
        state.application.doesUserHaveAccessToDeliveryPageForSelectedConcept,
    isHomeOfficeUser: state.application.userInfo.isHomeOfficeUser
});

const connector = connect(mapStateToProps);

type IReduxProps = ConnectedProps<typeof connector>;
export default connector(ModuleMetricConfiguration);

import React, {lazy} from "react";
import {History} from "history";
import ReactGA from "react-ga";
import {connect} from "react-redux";
import {Route, Router, Switch} from "react-router-dom";
import DelagetFooter from "./components/DelagetFooter";
import DelagetHeader from "./components/DelagetHeader";
import FreemiumMarketingModal from "./components/FreemiumMarketingModal";
import {LoadingMessage} from "components/SystemMessages";
import {actionCreators} from "./features/application/state/actions";
import configurationApi from "./features/configuration/api/configurationAPI";
import {IRootGroupInvalidLocations} from "./features/configuration/api/configurationModels";
import GroupTreeValidationError from "./features/configuration/components/GroupTreeValidationError";
import Dashboard from "./features/dashboard/Dashboard";
import {AppState} from "./state";
import {throttle} from "lodash";
import {RouteType} from "./util/appParamsUtil";
import ProtectedRoute from "features/authentication/components/ProtectedRoute";

// Initialize Google Analytics Tracking
ReactGA.initialize("UA-44917020-1", {
    gaOptions: {
        siteSpeedSampleRate: 100
    }
});

//Load Route Components Asynchronously to speed up initial page load
const AsyncAdminSettings = lazy(
    () => import("./features/application/AdminSettings")
);
const AsyncUserSettings = lazy(() => import("./features/application/UserSettings"));
const AsyncLocationMetricThresholdConfigurationContainer = lazy(
    () =>
        import(
            "./features/application/components/LocationThresholdSettings/LocationMetricThresholdConfigurationContainer"
        )
);
const AsyncMetricDetails = lazy(
    () => import("./features/metricDetails/MetricDetails")
);
const AsyncTasks = lazy(() => import("./features/tasks/Tasks"));
const AsyncTaskDetails = lazy(() => import("./features/tasks/TaskDetails"));
const AsyncMonitors = lazy(() => import("./features/monitors/Monitors"));
const AsyncMonitorSearch = lazy(() => import("./features/monitors/MonitorSearch"));
const AsyncCreateMonitor = lazy(() => import("./features/monitors/CreateMonitor"));
const AsyncMonitorDetails = lazy(
    () => import("./features/monitors/MonitorDetailsContainer")
);
const AsyncDeliveryProvider = lazy(
    () => import("./features/deliveryProvider/DeliveryProvider")
);
const AsyncEmployeeRetention = lazy(
    () => import("./features/homeOffice/HomeOffice")
);
const AsyncDifferentialPay = lazy(
    () => import("./features/DifferentialPay/DifferentialPay")
);
const AsyncDeliveryOperations = lazy(
    () => import("./features/deliveryOperations/DeliveryOperations")
);
const AsyncTheme = lazy(() => import("./components/Theme"));
const AsyncA2HS = lazy(() => import("./features/application/A2HSInstructions"));
const AsyncPageConfiguration = lazy(
    () => import("./features/pageConfiguration/PageConfiguration")
);

const AsyncRecoveryRedirect = lazy(
    () => import("./features/deliveryRecovery/RecoveryRedirect")
);

const AsyncDowntimeDetails = lazy(
    () => import("./features/deliveryOperations/DowntimeDetails")
);

interface IProps {
    history: History;
}

interface IReduxProps {
    rootGroupId: string;
    legacySiteBaseUrl: string;
    clientCode: string;
    username: string;
}

interface IState {
    groupIdSetupInvalidLocations: IRootGroupInvalidLocations;
}

type AppProps = IReduxProps & IProps & typeof actionCreators;

class App extends React.Component<AppProps, IState> {
    handleScreenResizeThrottled: any;

    constructor(props: AppProps) {
        super(props);

        this.state = {
            groupIdSetupInvalidLocations: {groupName: "", invalidLocations: []}
        };

        // Limit the number of resize events fired to once every 500ms to improve performance
        this.handleScreenResizeThrottled = throttle(this.handleScreenResize, 500);
    }

    public componentDidMount() {
        if (this.props.username && this.props.clientCode) {
            //dimension1 is setup in Google Analytics to be username
            ReactGA.set({dimension1: this.props.username});

            //dimension2 is setup in Google Analytics to be clientCode
            ReactGA.set({dimension2: this.props.clientCode});
        }

        if (!this.props.legacySiteBaseUrl) {
            this.props.requestDomainSettings();
        }

        if (this.props.rootGroupId) {
            configurationApi
                .checkForValidGroupTree(this.props.rootGroupId)
                .then((data) => this.setState({groupIdSetupInvalidLocations: data}));
        }

        // Add event listener for screen resize
        window.addEventListener("resize", this.handleScreenResizeThrottled);
    }

    public componentDidUpdate(previousProps: AppProps) {
        if (
            this.props.rootGroupId &&
            this.props.rootGroupId !== previousProps.rootGroupId
        ) {
            configurationApi
                .checkForValidGroupTree(this.props.rootGroupId)
                .then((data) => this.setState({groupIdSetupInvalidLocations: data}));
        }

        if (this.props.username && this.props.username !== previousProps.username) {
            //dimension1 is setup in Google Analytics to be username
            ReactGA.set({dimension1: this.props.username});
        }

        if (
            this.props.clientCode &&
            this.props.clientCode !== previousProps.clientCode
        ) {
            //dimension2 is setup in Google Analytics to be clientCode
            ReactGA.set({dimension2: this.props.clientCode});
        }
    }

    componentWillUnmount() {
        // Remove event listener for screen resize
        window.removeEventListener("resize", this.handleScreenResizeThrottled);
    }

    handleScreenResize = () => {
        this.props.handleScreenResize(window.innerWidth);
    };

    public render(): JSX.Element {
        // Here we can control what pages are accessible for Freemium users
        return (
            <Router history={this.props.history}>
                <DelagetHeader />
                <div className="layout-wrapper dashboard">
                    {this.props.rootGroupId &&
                    this.state.groupIdSetupInvalidLocations.invalidLocations.length >
                        0 ? (
                        <GroupTreeValidationError
                            groupIdSetupInvalidLocations={
                                this.state.groupIdSetupInvalidLocations
                            }
                        />
                    ) : (
                        <React.Suspense fallback={<LoadingMessage />}>
                            <Switch>
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/recovery"
                                    component={AsyncRecoveryRedirect}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    routeType={RouteType.REDIRECT}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/tasks"
                                    component={AsyncTasks}
                                    isFullVersionOnly={true}
                                    isAdminOnly={false}
                                    routeType={RouteType.TASKS}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/settings"
                                    component={AsyncUserSettings}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    routeType={RouteType.USER_SETTINGS}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/admin"
                                    component={AsyncAdminSettings}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    isAdminOrRecoveryAdminOnly={true}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/admin/:conceptUrlName/:metricDefinitionId"
                                    component={
                                        AsyncLocationMetricThresholdConfigurationContainer
                                    }
                                    isFullVersionOnly={false}
                                    isAdminOnly={true}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/addToHomeScreen"
                                    component={AsyncA2HS}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/:conceptUrlName/:businessUnitId/metricDetails/:page_PK/:date/:xtd/:businessUnitAggregation"
                                    component={AsyncMetricDetails}
                                    isFullVersionOnly={true}
                                    isAdminOnly={false}
                                    routeType={RouteType.METRIC_DETAILS}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/tasks/:taskId"
                                    component={AsyncTaskDetails}
                                    isFullVersionOnly={true}
                                    isAdminOnly={false}
                                    routeType={RouteType.TASKS}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/monitors/:conceptUrlName/search"
                                    component={AsyncMonitorSearch}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    routeType={RouteType.MONITORS_SEARCH}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/monitors/:conceptUrlName/create/:monitorId?"
                                    component={AsyncCreateMonitor}
                                    isFullVersionOnly={false}
                                    isAdminOnly={true}
                                    routeType={RouteType.CREATE_MONITOR}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/monitors/:conceptUrlName/:businessUnitId/:monitorId/details/:daysToLookBack"
                                    component={AsyncMonitorDetails}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    routeType={RouteType.MONITOR_DETAILS}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/monitors/:conceptUrlName/:businessUnitId"
                                    component={AsyncMonitors}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    isCoachDetect={true}
                                    routeType={RouteType.MONITORS}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/monitors"
                                    component={AsyncMonitors}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    isCoachDetect={true}
                                    routeType={RouteType.MONITORS}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/HomeOffice/:page_PK/DeliveryProviderPaymentData/:conceptUrlName/:date"
                                    component={AsyncDeliveryProvider}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    isCoachDetect={false}
                                    routeType={RouteType.HOME_OFFICE}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/:conceptUrlName/:businessUnitId/HomeOffice/:page_PK/EmployeeRetention/:date/:xtd/:businessUnitAggregation"
                                    component={AsyncEmployeeRetention}
                                    isFullVersionOnly={true}
                                    isAdminOnly={false}
                                    isCoachDetect={false}
                                    routeType={RouteType.EMPLOYEE_RETENTION}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/DifferentialPay/:conceptUrlName/:businessUnitId"
                                    component={AsyncDifferentialPay}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    isCoachDetect={false}
                                    routeType={RouteType.DIFFERENTIAL_PAY}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/DifferentialPay"
                                    component={AsyncDifferentialPay}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    isCoachDetect={false}
                                    routeType={RouteType.DIFFERENTIAL_PAY}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/pageconfiguration/:page_PK?/:date"
                                    component={AsyncPageConfiguration}
                                    isFullVersionOnly={true}
                                    isAdminOnly={true}
                                    routeType={RouteType.PAGE_CONFIGURATION}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/DeliveryOperations/:page_PK/:conceptUrlName/:businessUnitId/:date/:businessUnitAggregation"
                                    component={AsyncDeliveryOperations}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    isCoachDetect={false}
                                    isDeliveryOperations={true}
                                    routeType={RouteType.DELIVERY_OPERATIONS}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/dashboard/DeliveryOperations/DowntimeDetails/:page_PK/:conceptUrlName/:businessUnitId/:date/:businessUnitAggregation"
                                    component={AsyncDowntimeDetails}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    isDeliveryOperations={true}
                                    routeType={RouteType.DELIVERY_OPERATIONS}
                                />
                                <Route
                                    exact
                                    path="/dashboard/theme"
                                    component={AsyncTheme}
                                />
                                {/* The path below is used so Coach (Dashboard) loads directly from the site root.
                                    However, for local development we need to keep /dashboard in the url.
                                */}
                                <ProtectedRoute
                                    exact
                                    path="/dashboard"
                                    component={Dashboard}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    routeType={RouteType.DASHBOARD}
                                />
                                <ProtectedRoute
                                    exact
                                    path="/"
                                    component={Dashboard}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    routeType={RouteType.DASHBOARD}
                                />
                                <ProtectedRoute
                                    exact={false}
                                    path="/dashboard/:conceptUrlName?/:page_PK?/:businessUnitId?/:date?"
                                    component={Dashboard}
                                    isFullVersionOnly={false}
                                    isAdminOnly={false}
                                    routeType={RouteType.DASHBOARD}
                                />
                                {/* The main dashboard route goes last because of ambiguous matching,
                                it will first make sure that what comes after /dashboard/ isn't one
                                of our other routes, then it will try it as a business date param */}
                            </Switch>
                        </React.Suspense>
                    )}
                </div>
                <DelagetFooter />
                <FreemiumMarketingModal />
            </Router>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    rootGroupId: state.application.selectedClientConcept.rootGroupId,
    legacySiteBaseUrl: state.application.legacySiteBaseUrl,
    clientCode: state.application.userInfo.clientCode,
    username: state.application.userInfo.userName
});

export default connect(mapStateToProps, actionCreators, (state, actions, props) => ({
    ...state,
    ...actions,
    ...props
}))(App);

import {
    IModuleMetricDefinition,
    Xtd
} from "../features/dashboard/api/dashboardModels";
import {IColumnMetadata} from "../features/application/api/applicationModels";
import {ModuleType} from "../features/configuration/api/uiConfigurationModels";
import {orderBy} from "lodash";

const exportUtil = {
    getExportMetricHeaderData(
        metricDefinitions: IModuleMetricDefinition[],
        xtd: Xtd[],
        moduleType: ModuleType | undefined
    ): IColumnMetadata[] {
        const headerArray: IColumnMetadata[] = [];
        const sortedMetricDefinitions = orderBy(metricDefinitions, [
            "sortOrder",
            "toggleOrder",
            "metricOrder"
        ]);

        if (
            moduleType === undefined ||
            moduleType === ModuleType.TransactionDetails ||
            moduleType === ModuleType.LocationDetails ||
            moduleType === ModuleType.InventoryDetails
        ) {
            sortedMetricDefinitions.forEach((m) => {
                headerArray.push({
                    label: m.metricName,
                    key: m.metricCode,
                    dataType: m.dataType,
                    decimalPlaces: m.decimalPlaces
                });
            });
            return headerArray;
        } else {
            sortedMetricDefinitions.forEach((m) => {
                xtd.forEach((x) => {
                    headerArray.push({
                        label: `${m.metricName} ${x}`,
                        key: `metricData.${m.metricCode}.${x}`,
                        dataType: m.dataType,
                        decimalPlaces: m.decimalPlaces
                    });
                });
            });
            return headerArray;
        }
    }
};

export default exportUtil;

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        hj: any;
        _hjSettings: any;
    }
}

export const hj = (function (h: Window, o: Document, t: string, j: string) {
    let a: HTMLHeadElement;
    let r: HTMLScriptElement;
    h.hj =
        h.hj ||
        function () {
            // eslint-disable-next-line prefer-rest-params
            (h.hj.q = h.hj.q || []).push(arguments);
        };
    h._hjSettings = {hjid: 477641, hjsv: 6};
    // eslint-disable-next-line prefer-const
    a = o.getElementsByTagName("head")[0];
    // eslint-disable-next-line prefer-const
    r = o.createElement("script");
    r.async = true;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
})(window, document, "//static.hotjar.com/c/hotjar-", ".js?sv=");

export default window.hj;

import {keys, find, filter, includes, orderBy, sortedUniq} from "lodash";
import {
    IMetricData,
    IModuleMetricDefinition,
    MetricDirection,
    IXtdMetricDataThresholdViolationData,
    IBusinessUnitMetricData,
    Xtd
} from "../features/dashboard/api/dashboardModels";
import {sortingDirections} from "./sortUtil";
import {
    ThresholdContext,
    IThresholdFilters
} from "../features/metricDetails/api/metricDetailsModels";

const metricDataUtil = {
    /**
     * Function to unbox our metric data object for given metricCode and xtd and return its value.
     *
     * @param {IMetricData} metricData - Metric data object to unbox.
     * @param {string} metricCode - Metric code.
     * @param {string} xtd - Xtd.
     * @returns {number | undefined} Value of the given metric.
     */
    getMetricData(
        metricData: IMetricData,
        metricCode: string,
        xtd: string
    ): number | null | undefined {
        if (metricData && metricData[metricCode]) {
            return metricData[metricCode][xtd];
        } else {
            return undefined;
        }
    },

    getMetricSortColumnKey(metricCode: string, xtd?: string): string {
        if (xtd) {
            return "metricData." + metricCode + "." + xtd;
        } else {
            return "metricData." + metricCode;
        }
    },

    // default sorting criteria is always worst on the top
    getDefaultMetricSortDirection(
        metricDefinition: IModuleMetricDefinition | undefined
    ): sortingDirections {
        if (
            !metricDefinition ||
            !metricDefinition.metricDirection ||
            metricDefinition.metricDirection === MetricDirection.None
        ) {
            return sortingDirections.Descending;
        }

        switch (metricDefinition.metricDirection) {
            case MetricDirection.HigherIsBetter:
                return sortingDirections.Ascending;
            case MetricDirection.LowerIsBetter:
                return sortingDirections.Descending;
            default:
                //Note: other metric direction like CloserToTarget is not handled for now.
                return sortingDirections.Descending;
        }
    },

    /**
     * Returns an array containing metrics which are displayed in a component and set their active status.
     * @param metricDefinitions
     */
    getMetricsInComponent(
        metricDefinitions: IModuleMetricDefinition[],
        displayToggledMetrics?: boolean // Adding this parameter makes this function assume that there are only 2 unique toggle orders
    ): IModuleMetricDefinition[][] {
        const activeMetrics: IModuleMetricDefinition[][] = [];
        // We need to sort metric definitions on all orders so this method displays all metrics correctly
        const sortedMetricDefinitions = orderBy(metricDefinitions, [
            "sortOrder",
            "toggleOrder",
            "metricOrder"
        ]);

        if (sortedMetricDefinitions && sortedMetricDefinitions.length > 0) {
            sortedMetricDefinitions.forEach((metric, index) => {
                // Get the min toggle order --> that's the metrics we want to display initially
                const toggleOrders = sortedUniq(
                    sortedMetricDefinitions
                        .filter((m) => m.sortOrder === metric.sortOrder)
                        .map((m) => m.toggleOrder)
                ); // Should be sorted because of the orderBy above
                let activeToggleOrder = 0;

                if (toggleOrders && toggleOrders.length > 0) {
                    // If DisplayToggledMetrics then determine whether metrics for each sort order have a toggle on them
                    // We assume that there are at msot two toggle orders
                    if (toggleOrders.length === 1 || !displayToggledMetrics) {
                        activeToggleOrder = toggleOrders[0];
                    } else {
                        // We assume there are only two active toggle orders
                        activeToggleOrder = toggleOrders[1];
                    }
                }

                if (
                    index > 0 &&
                    activeMetrics[activeMetrics.length - 1][0].sortOrder ===
                        metric.sortOrder
                ) {
                    activeMetrics[activeMetrics.length - 1].push({
                        ...metric,
                        active:
                            metric.toggleOrder === activeToggleOrder ? true : false
                    });
                } else {
                    // If it's the first metric or a new sort order then just push into the activeMetrics
                    activeMetrics.push([
                        {
                            ...metric,
                            active:
                                metric.toggleOrder === activeToggleOrder
                                    ? true
                                    : false
                        }
                    ]);
                }
            });
        }

        return activeMetrics;
    },

    getMetricDataThresholdViolation(
        metricDataThresholdViolation: IXtdMetricDataThresholdViolationData,
        metricCode: string,
        xtd: Xtd
    ): boolean {
        if (
            metricDataThresholdViolation &&
            metricDataThresholdViolation[metricCode]
        ) {
            return metricDataThresholdViolation[metricCode][xtd];
        } else {
            return false;
        }
    },

    /**
     * Returns filtered array of metric data based on what filters are applied.
     * @param metricDefinitions
     * @param metricData
     * @param thresholdFilters
     * @param xtd
     */
    filterMetricDataByThresholdFilter(
        metricData: IBusinessUnitMetricData[],
        metricDefinition: IModuleMetricDefinition[],
        thresholdFilters: IThresholdFilters | undefined,
        xtd: Xtd // xtd is needed to get the metric value
    ): IBusinessUnitMetricData[] {
        // If no filters applied, do nothing
        if (!thresholdFilters) {
            return metricData;
        }

        // Get metrics which we are filtering on
        const metricsFromAppliedFilters = keys(thresholdFilters);

        // If no metric data or no filters are applied then return
        if (metricsFromAppliedFilters.length === 0 || metricData.length === 0) {
            return metricData;
        }

        // If no filters (empty threshold context) are applied then we return all rows
        if (
            find(thresholdFilters, (t) => {
                return t.length > 0;
            }) === undefined
        ) {
            return metricData;
        }

        // Initialize new array
        const updatedMetricData: IBusinessUnitMetricData[] = [];
        // Loop through all rows and check which should be returned based on all applied filter.
        // Note that filters can be applied to multiple metrics.
        for (let i = 0; i < metricData.length; i++) {
            let includeRow = true;
            for (let j = 0; j < metricsFromAppliedFilters.length; j++) {
                let metricValueThresholdType = ThresholdContext.Unknown;
                const metricCode = metricsFromAppliedFilters[j]; // metric code of the filtered metric
                const filters = thresholdFilters[metricsFromAppliedFilters[j]]; // all applied threshold contexts

                // Check if the metric is present on the page, otherwise return nothing
                if (find(metricDefinition, (m) => m.metricCode === metricCode)) {
                    // If metric data is undefined or the value is null --> not reporting
                    if (
                        metricData[i].metricData[metricCode] === undefined ||
                        metricData[i].metricData[metricCode][xtd] === null
                    ) {
                        metricValueThresholdType = ThresholdContext.NotReporting;
                    } else {
                        // If the threshold is undefined or metric value is withing threshold --> good
                        if (
                            metricData[i].metricDataThresholdViolation[
                                metricCode
                            ] === undefined ||
                            !metricData[i].metricDataThresholdViolation[metricCode][
                                xtd
                            ]
                        ) {
                            metricValueThresholdType = ThresholdContext.Good;
                        } else {
                            metricValueThresholdType = ThresholdContext.Bad;
                        }
                    }
                }

                if (!includes(filters, metricValueThresholdType)) {
                    includeRow = false;
                    break;
                }
            }

            if (includeRow) {
                updatedMetricData.push(metricData[i]);
            }
        }

        return updatedMetricData;
    },

    /**
     * Returns an array of threshold filters. If `filter` was already applied then we remove it.
     * Otherwise we add it.
     * @param currentFilters
     * @param thresholdFilter
     */
    applyOrRemoveThresholdFilter(
        currentFilters: ThresholdContext[] | undefined,
        thresholdFilter: ThresholdContext
    ): ThresholdContext[] {
        if (currentFilters === undefined) {
            currentFilters = [];
        }
        // We check if the filter was already applied and if so the remove it.
        // Otherwise we add it.
        if (includes(currentFilters, thresholdFilter)) {
            return filter(currentFilters, (f) => {
                return f !== thresholdFilter;
            });
        } else {
            currentFilters.push(thresholdFilter);
            return currentFilters;
        }
    },

    getThresholdFilterLabel(thresholdFilter: ThresholdContext): string {
        switch (thresholdFilter) {
            case ThresholdContext.Good:
                return "Within Threshold";

            case ThresholdContext.Bad:
                return "Violating Threshold";

            case ThresholdContext.NotReporting:
                return "Not Reporting";

            default:
                return "No Filter";
        }
    }
};

export default metricDataUtil;

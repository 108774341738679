/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import TreeSelect from "rc-tree-select";
import "rc-tree-select/assets/index.css";
// eslint-disable-next-line no-use-before-define
import {Component} from "react";
import {BusinessUnitType} from "../api/applicationModels";
import {isArray} from "lodash";

class DropdownTreeSelector extends Component {
    //customize expand/colapse icon
    arrowPath = "M7.5,7.1h-7L4,0.9L7.5,7.1z";

    getSvg = (path, iStyle = {}, style = {}, color) => {
        return (
            <i style={iStyle}>
                <svg
                    viewBox="0 0 8 8"
                    width=".60em"
                    height=".60em"
                    fill={color}
                    style={{...style}}
                >
                    <path d={path} />
                </svg>
            </i>
        );
    };

    switcherIcon = (obj) => {
        return obj.isLeaf ? (
            <></>
        ) : (
            this.getSvg(
                this.arrowPath,
                {cursor: "pointer", backgroundColor: "white"},
                {
                    transform: `rotate(${obj.expanded ? 180 : 90}deg)`,
                    marginLeft: ".4em"
                },
                "#16729b"
            )
        );
    };

    inputIcon = (obj) => {
        return this.getSvg(
            this.arrowPath,
            {},
            {
                transform: `rotate(${obj.open ? 0 : 180}deg)`,
                verticalAlign: "-0.5em"
            },
            "#112459"
        );
    };

    render() {
        const {
            selectedValue,
            groupTreeData,
            clientTreeHierarchy,
            className,
            disabled,
            placeholder,
            onSelect,
            onChange,
            multiple = false,
            treeCheckable = false,
            treeDefaultExpandedKeys = [selectedValue],
            showSearch = true
        } = this.props;

        // Custom GroupTreeData can be passed in from the parent component otherwise we will use the ClientTreeHierarchy
        // to build the GroupTreeData options (used to make the treeNodes links, not strings)
        let groupTreeDataUpdated = [];

        if (groupTreeData && groupTreeData.length > 0) {
            groupTreeDataUpdated = groupTreeData;
        } else {
            if (clientTreeHierarchy && clientTreeHierarchy.length > 0) {
                groupTreeDataUpdated = clientTreeHierarchy.map((bu) => {
                    const displayName =
                        bu.businessUnitType === BusinessUnitType.Location &&
                        bu.businessUnitDescription // businessUnitDescription can sometimes be null
                            ? bu.businessUnitName +
                              " - " +
                              bu.businessUnitDescription
                            : bu.businessUnitName;

                    //When a group is selected it's a mechanism to actually select the underlying locations, therefore only allow the group to be selected
                    //if locations actually exist under it.
                    const isDisabled =
                        bu.businessUnitType === BusinessUnitType.Group &&
                        !clientTreeHierarchy.some(
                            (otherBu) =>
                                otherBu.parentBusinessUnitId === bu.businessUnitId
                        );

                    return {
                        id: bu.businessUnitId,
                        pId: bu.parentBusinessUnitId || "",
                        disabled: isDisabled,
                        value: bu.businessUnitId,
                        name: displayName, // this prop is used for the tree filtering
                        title: displayName // this is what is displayed
                    };
                });
            }
        }
        return (
            <TreeSelect
                className={className || "delaget-dropdown"}
                dropdownClassName="delaget-dropdown-list"
                allowClear={false}
                showSearch={showSearch}
                multiple={multiple}
                treeLine
                treeCheckable={treeCheckable}
                switcherIcon={this.switcherIcon}
                inputIcon={this.inputIcon}
                treeDataSimpleMode={{id: "id", pId: "pId"}} //field name for node id and parent node id
                treeNodeFilterProp={"name"}
                treeData={groupTreeDataUpdated}
                value={
                    groupTreeDataUpdated && groupTreeDataUpdated.length > 0
                        ? selectedValue
                        : null
                }
                onSelect={onSelect ? onSelect : undefined}
                treeDefaultExpandedKeys={
                    isArray(treeDefaultExpandedKeys)
                        ? treeDefaultExpandedKeys
                        : [treeDefaultExpandedKeys]
                }
                placeholder={placeholder || "Please select..."}
                disabled={disabled}
                onChange={onChange ? onChange : undefined}
            />
        );
    }
}

export default DropdownTreeSelector;

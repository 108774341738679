import * as React from "react";
import {Button, Container, Grid, Segment} from "semantic-ui-react";
import {
    freemiumMarketingModalContent,
    freemiumMarketingUrl
} from "./FreemiumMarketingModal";

class FreemiumMarketingSegment extends React.Component {
    render(): JSX.Element {
        return (
            <Container fluid style={{marginTop: "80px"}}>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column stretched>
                            <Segment size="big" padded="very">
                                {freemiumMarketingModalContent}
                                <Button
                                    className={"btn-orange"}
                                    onClick={() => {
                                        window.location.assign(freemiumMarketingUrl);
                                    }}
                                >
                                    I want to learn more!
                                </Button>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

export default FreemiumMarketingSegment;

/* eslint-disable no-case-declarations */
import {combineReducers} from "redux";
import * as auth from "../../authentication/state/actions";
import {Xtd} from "../../dashboard/api/dashboardModels";
import {BusinessUnitAggregation} from "../../metricDetails/api/metricDetailsModels";
import {
    IUserInfo,
    IUserSettings,
    IClientConcept,
    Concept,
    IBusinessUnit,
    IClientConceptMetricDefinition,
    IMetricConfigurationParams,
    IClientConceptPage
} from "../api/applicationModels";
import * as application from "./actions";
import {getYesterday} from "../../../util/dateUtil";
import {includes} from "lodash";
import {IDeliveryProviderPayoutDate} from "features/deliveryProvider/api/deliveryProviderModels";

export type ApplicationState = Readonly<{
    fromDate: Date | null;
    date: Date;
    xtd: Xtd;
    businessUnitAggregation: BusinessUnitAggregation;
    userInfo: IUserInfo;
    userSettings: IUserSettings;
    savingUserSettings: boolean;
    saveUserSettingsResult: number;
    userSettingsErrorMessage: string | null;
    clientTreeHierarchy: IBusinessUnit[];
    currentSelectedBusinessUnitId: string;
    openTaskCount: number;
    legacySiteBaseUrl: string;
    websiteBaseUrl: string;
    isClientOptedOutOfTaskEmailing: boolean;
    freemiumMarketingModalOpen: boolean;
    availableClientConcepts: IClientConcept[];
    availableClientConceptsForCoachDetect: IClientConcept[];
    availableClientConceptsForCoachDeliveryPage: IClientConcept[];
    availableClientConceptsForCoachDifferentialPay: IClientConcept[];
    availableClientConceptsForCoachRecovery: IClientConcept[];
    availableClientConceptsForDeliveryOperations: IClientConcept[];
    clientConceptPages: IClientConceptPage[];
    selectedClientConcept: IClientConcept;
    clientConceptMetricDefinitions: IClientConceptMetricDefinition[];
    doesUserHaveAccessToDetectForSelectedConcept: boolean;
    doesUserHaveAccessToDeliveryPageForSelectedConcept: boolean;
    doesUserHaveAccessToRecoveryForSelectedConcept: boolean;
    doesUserHaveAccessToDeliveryOperationsForSelectedConcept: boolean;
    errorMessage: string;
    screenWidth: number;
    metricConfigurationParams: IMetricConfigurationParams;
    deliveryProviderPayoutDatesForBusinessUnit: IDeliveryProviderPayoutDate[];
}>;

const initialUserSettings: IUserSettings = {
    defaultBusinessUnitId: "",
    isLocation: false
};

export const initialClientConcept: IClientConcept = {
    clientConcept_PK: 0,
    concept: Concept.Unknown,
    conceptName: "",
    conceptUrlName: "",
    calendarId: 0,
    rootGroupId: "",
    periodStartDates: []
};

const initialUserInfo: IUserInfo = {
    userName: "",
    isAdmin: false,
    isRecoveryAdmin: false,
    isHomeOfficeUser: false,
    isDelagetUser: false,
    isFreemium: false,
    emailAddress: "",
    lastAction: "",
    clientUserID: "",
    clientId: "",
    clientCode: ""
};

type Actions = application.ApplicationAction | auth.AuthAction;

export default combineReducers<ApplicationState, Actions>({
    fromDate: (state = null, action) => {
        switch (action.type) {
            case application.CHANGE_FROM_DATE:
            case application.CHANGE_APP_PARAMETERS:
                return action.fromDate;
            default:
                return state;
        }
    },

    date: (state = getYesterday(), action) => {
        switch (action.type) {
            case application.CHANGE_DATE:
            case application.CHANGE_APP_PARAMETERS:
                return action.date;
            default:
                return state;
        }
    },

    xtd: (state = Xtd.Unknown, action) => {
        switch (action.type) {
            case application.CHANGE_XTD:
            case application.CHANGE_APP_PARAMETERS:
                return action.xtd;
            default:
                return state;
        }
    },

    businessUnitAggregation: (state = BusinessUnitAggregation.Unknown, action) => {
        switch (action.type) {
            case application.CHANGE_BUSINESS_UNIT_AGGREGATION:
            case application.CHANGE_APP_PARAMETERS:
                return action.businessUnitAggregation;
            default:
                return state;
        }
    },

    userInfo: (state = initialUserInfo, action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.userInfo;

            default:
                return state;
        }
    },

    userSettings: (state = initialUserSettings, action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                if (action.userSettings) {
                    return action.userSettings;
                } else {
                    return state;
                }

            case application.USER_SETTINGS_CONFIRM:
                return action.userSettings;

            default:
                return state;
        }
    },

    savingUserSettings: (state = false, action) => {
        switch (action.type) {
            case application.USER_SETTINGS_SAVE:
                return true;

            case application.USER_SETTINGS_CONFIRM:
            case application.USER_SETTINGS_ERROR:
                return false;

            default:
                return state;
        }
    },

    saveUserSettingsResult: (state = 0, action) => {
        switch (action.type) {
            case application.USER_SETTINGS_SAVE:
                return 0;

            case application.USER_SETTINGS_CONFIRM:
                return 1;

            default:
                return state;
        }
    },

    userSettingsErrorMessage: (state = null, action) => {
        switch (action.type) {
            case application.USER_SETTINGS_SAVE:
                return null;

            case application.USER_SETTINGS_ERROR:
                return action.errorMessage;

            default:
                return state;
        }
    },

    clientTreeHierarchy: (state = [], action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.clientTreeHierarchy;
            default:
                return state;
        }
    },

    currentSelectedBusinessUnitId: (state = "", action) => {
        switch (action.type) {
            case application.SWITCH_CURRENT_SELECTED_BUSINESS_UNIT:
            case application.CHANGE_APP_PARAMETERS:
                return action.businessUnitId;
            default:
                return state;
        }
    },

    openTaskCount: (state = 0, action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.openTaskCount;

            case application.GET_OPEN_TASK_COUNT:
                return action.openTaskCount;

            default:
                return state;
        }
    },

    legacySiteBaseUrl: (state = "", action) => {
        switch (action.type) {
            case application.RECEIVE_DOMAIN_SETTINGS:
                return action.domainSettings.legacySiteBaseUrl;
            default:
                return state;
        }
    },

    websiteBaseUrl: (state = "", action) => {
        switch (action.type) {
            case application.RECEIVE_DOMAIN_SETTINGS:
                return action.domainSettings.websiteBaseUrl;
            default:
                return state;
        }
    },

    selectedClientConcept: (state = initialClientConcept, action) => {
        switch (action.type) {
            case application.CHANGE_CLIENT_CONCEPT:
            case application.CHANGE_APP_PARAMETERS:
                return action.clientConcept;
            default:
                return state;
        }
    },

    isClientOptedOutOfTaskEmailing: (state = false, action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.isClientOptedOutOfTaskEmailing;
            default:
                return state;
        }
    },

    freemiumMarketingModalOpen: (state = false, action) => {
        switch (action.type) {
            case application.HANDLE_FREEMIUM_MARKETING_MODAL:
                return action.freemiumMarketingModalOpen;
            default:
                return state;
        }
    },

    metricConfigurationParams: (
        state: IMetricConfigurationParams = {
            saveButtonClicked: false
        },
        action
    ) => {
        switch (action.type) {
            case application.HANDLE_METRIC_CONFIGURATION_SAVE_BUTTON:
                return {
                    saveButtonClicked: action.saveButtonClicked
                };
            default:
                return state;
        }
    },

    availableClientConcepts: (state = [], action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.clientConcepts;
            default:
                return state;
        }
    },

    availableClientConceptsForCoachDetect: (state = [], action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.clientConceptsForCoachDetect;
            default:
                return state;
        }
    },

    availableClientConceptsForCoachDeliveryPage: (state = [], action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.clientConceptsForCoachDeliveryPage;
            default:
                return state;
        }
    },

    availableClientConceptsForCoachDifferentialPay: (state = [], action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.clientConceptsForCoachDifferentialPay;
            default:
                return state;
        }
    },

    availableClientConceptsForCoachRecovery: (state = [], action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.clientConceptsForCoachRecovery;
            default:
                return state;
        }
    },

    availableClientConceptsForDeliveryOperations: (state = [], action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.clientConceptsForDeliveryOperations;
            default:
                return state;
        }
    },

    clientConceptPages: (state = [], action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.clientConceptPages;
            case application.GET_CLIENT_CONCEPT_PAGES:
                return action.clientConceptPages;
            default:
                return state;
        }
    },

    clientConceptMetricDefinitions: (state = [], action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.clientConceptMetricDefinitions;
            default:
                return state;
        }
    },

    doesUserHaveAccessToDetectForSelectedConcept: (state = false, action) => {
        switch (action.type) {
            case application.CHANGE_CLIENT_CONCEPT:
            case application.CHANGE_APP_PARAMETERS:
                // eslint-disable-next-line no-case-declarations
                const doesUserHaveAccess = includes(
                    action.clientConceptsForDetect.map((x) => x.clientConcept_PK),
                    action.clientConcept.clientConcept_PK
                );
                return doesUserHaveAccess;
            default:
                return state;
        }
    },

    doesUserHaveAccessToDeliveryPageForSelectedConcept: (state = false, action) => {
        switch (action.type) {
            case application.CHANGE_CLIENT_CONCEPT:
            case application.CHANGE_APP_PARAMETERS:
                const doesUserHaveAccess = includes(
                    action.clientConceptsForDeliveryPage.map(
                        (x) => x.clientConcept_PK
                    ),
                    action.clientConcept.clientConcept_PK
                );
                return doesUserHaveAccess;
            default:
                return state;
        }
    },

    doesUserHaveAccessToRecoveryForSelectedConcept: (state = false, action) => {
        switch (action.type) {
            case application.CHANGE_CLIENT_CONCEPT:
            case application.CHANGE_APP_PARAMETERS:
                const doesUserHaveAccess = includes(
                    action.clientConceptsForRecovery.map((x) => x.clientConcept_PK),
                    action.clientConcept.clientConcept_PK
                );
                return doesUserHaveAccess;
            default:
                return state;
        }
    },

    doesUserHaveAccessToDeliveryOperationsForSelectedConcept: (
        state = false,
        action
    ) => {
        switch (action.type) {
            case application.CHANGE_CLIENT_CONCEPT:
            case application.CHANGE_APP_PARAMETERS:
                const doesUserHaveAccess = includes(
                    action.clientConceptsForDeliveryOperations.map(
                        (x) => x.clientConcept_PK
                    ),
                    action.clientConcept.clientConcept_PK
                );
                return doesUserHaveAccess;
            default:
                return state;
        }
    },

    deliveryProviderPayoutDatesForBusinessUnit: (state = [], action) => {
        switch (action.type) {
            case application.CHANGE_DELIVERY_PROVIDER_PAYOUT_DATES:
                return action.deliveryProviderPayoutDatesForBusinessUnit;
            default:
                return state;
        }
    },

    errorMessage: (state = "", action) => {
        switch (action.type) {
            case auth.AUTH_SUCCESS:
                return action.errorMessage;
            default:
                return state;
        }
    },

    screenWidth: (state = window.innerWidth, action) => {
        switch (action.type) {
            case application.HANDLE_SCREEN_RESIZE:
                return action.screenWidth;
            default:
                return state;
        }
    }
});

import {FC, useState} from "react";
import {Button, Icon, Table} from "semantic-ui-react";
import {IModuleMetricDefinition} from "features/dashboard/api/dashboardModels";
import {
    dayPartTypeOptions,
    drilldownPageCodeOptions,
    drilldownTypeOptions,
    metricOrderOptions,
    moduleTypeSortOrderOptions,
    toggleOrderOptions,
    trueFalseOptions
} from "./MetricConfigurationModels";
import {
    DataStatus,
    IClientConceptMetricDefinition,
    MetricType
} from "features/application/api/applicationModels";
import {
    ModuleType,
    PageCode
} from "features/configuration/api/uiConfigurationModels";
import {UseFieldArrayRemove, UseFormSetValue} from "react-hook-form";
import SemanticHookFormDropdown from "features/monitors/components/forms/fields/SemanticHookFormDropdown";
import SemanticHookFormInputField from "features/monitors/components/forms/fields/SemanticHookFormInput";

//Some fields should be enabled/disabled based on the ModuleType selected
interface IModuleMetricFormSetup {
    isDefaultSortDisabled: boolean;
    isDrilldownPageCodeDisabled: boolean;
    isMetricOrderDisabled: boolean;
    isToggleOrderDisabled: boolean;
    isMetricOrderCategoryDisabled: boolean;
    isModuleTypeSortOrderDisabled: boolean;
    isDayPartTypeDisabled: boolean;
    isDrilldownTypeDisabled: boolean;
    isHasFilterDisabled: boolean;
}

interface IProps {
    clientConceptPageModuleMetric: IModuleMetricDefinition;
    availableClientConceptMetrics: IClientConceptMetricDefinition[];
    selectedPage_PK: number;
    index: number;
    moduleTypeOptions: {key: string; text: string; value: string}[];
    setValue: UseFormSetValue<any>;
    remove: UseFieldArrayRemove;
    control: any;
    isSubmitting: boolean;
    deleteClientConceptPageModuleMetric: (
        clientConcept_PK: number,
        moduleMetricId: number
    ) => Promise<void>;
}

const ModuleMetricFormRow: FC<IProps> = ({
    clientConceptPageModuleMetric,
    availableClientConceptMetrics,
    selectedPage_PK,
    index,
    moduleTypeOptions,
    setValue,
    remove,
    deleteClientConceptPageModuleMetric,
    control,
    isSubmitting
}) => {
    const [removeConfigurationStatus, setRemoveConfigurationStatus] =
        useState<DataStatus>(DataStatus.Undefined);

    //There are three scenarios to account for:
    //1. If the moduleType is unknown show everything
    //2. If the Module Type is TransactionDetails, only show metrics that are of Type Transaction
    //3. If the Module Type is NOT TransactionDetails, show all metrics that are NOT Transaction Type
    let filteredMetricOptions: IClientConceptMetricDefinition[];

    if (clientConceptPageModuleMetric.moduleType === ModuleType.Unknown) {
        filteredMetricOptions = availableClientConceptMetrics;
    } else if (
        clientConceptPageModuleMetric.moduleType === ModuleType.TransactionDetails
    ) {
        filteredMetricOptions = availableClientConceptMetrics.filter(
            (metric) => metric.metricType === MetricType.Transaction
        );
    } else {
        filteredMetricOptions = availableClientConceptMetrics.filter(
            (metric) => metric.metricType !== MetricType.Transaction
        );
    }

    ///If the current value is not present in the dropdown then, clear out the value
    if (
        filteredMetricOptions.filter(
            (e) =>
                e.metricDefinitionId ===
                clientConceptPageModuleMetric.metricDefinitionId
        ).length === 0
    ) {
        setValue(
            `clientConceptPageModuleMetrics.${index}.metricDefinitionId`,
            undefined
        );
    }

    //determine enabled/disabled fields based on the moduleType
    const formSetup = determineEnabledAndDisabledFields(
        clientConceptPageModuleMetric
    );

    //If a field is disabled, also clear its value
    clearFormValuesAsNeeded(
        formSetup,
        clientConceptPageModuleMetric,
        setValue,
        index
    );

    if (
        clientConceptPageModuleMetric.page_PK === selectedPage_PK ||
        selectedPage_PK === 0
    ) {
        return (
            <Table.Row>
                <Table.Cell>
                    <Button
                        negative
                        type="button"
                        disabled={removeConfigurationStatus === DataStatus.Loading}
                        loading={removeConfigurationStatus === DataStatus.Loading}
                        onClick={async () => {
                            if (clientConceptPageModuleMetric.id) {
                                setRemoveConfigurationStatus(DataStatus.Loading);
                                await deleteClientConceptPageModuleMetric(
                                    clientConceptPageModuleMetric.clientConcept_PK,
                                    clientConceptPageModuleMetric.id
                                )
                                    .then(() => {
                                        setRemoveConfigurationStatus(
                                            DataStatus.Loaded
                                        );
                                        remove(index);
                                    })
                                    .catch(() => {
                                        setRemoveConfigurationStatus(
                                            DataStatus.Failed
                                        );
                                    });
                            } else {
                                //todo: Improve this UX
                                //The UX of this is that there's a long freeze as the form re-renders, but also
                                //this setRemoveConfigurationStatus doesn't complete before the Remove happens so the spinner is
                                //never shown
                                setRemoveConfigurationStatus(DataStatus.Loading);
                                remove(index);
                            }
                        }}
                    >
                        Remove
                    </Button>

                    {removeConfigurationStatus === DataStatus.Failed && (
                        <Button
                            negative
                            floated={"right"}
                            type="button"
                            style={{cursor: "default"}}
                        >
                            <Icon name="exclamation circle" /> Remove Failed
                        </Button>
                    )}
                </Table.Cell>
                <Table.Cell>{clientConceptPageModuleMetric.id}</Table.Cell>
                <Table.Cell
                    key={"moduleMetric_" + index + "_pageCode"}
                    className="moduleMetric"
                >
                    {clientConceptPageModuleMetric.pageCode}
                </Table.Cell>
                <Table.Cell
                    key={"moduleMetric_" + index + "_moduleType"}
                    style={{
                        minWidth: "200px"
                    }}
                >
                    <SemanticHookFormDropdown
                        control={control}
                        setValue={setValue}
                        name={`clientConceptPageModuleMetrics.${index}.moduleType`}
                        type="text"
                        options={moduleTypeOptions}
                        errorLabelPointingDirection="left"
                        disabled={isSubmitting}
                    />
                </Table.Cell>
                <Table.Cell
                    key={"moduleMetric_" + index + "_sortOrder"}
                    style={{
                        minWidth: "100px"
                    }}
                >
                    <SemanticHookFormInputField
                        control={control}
                        setValue={setValue}
                        name={`clientConceptPageModuleMetrics.${index}.sortOrder`}
                        type="number"
                        errorLabelPointingDirection="left"
                        disabled={false}
                    />
                </Table.Cell>
                <Table.Cell
                    key={"moduleMetric_" + index + "_metricDefinitionId"}
                    style={{
                        minWidth: "200px"
                    }}
                >
                    <SemanticHookFormDropdown
                        control={control}
                        name={`clientConceptPageModuleMetrics.${index}.metricDefinitionId`}
                        type="text"
                        options={filteredMetricOptions.map((clientConceptMetric) => {
                            return {
                                key: clientConceptMetric.metricDefinitionId,
                                value: clientConceptMetric.metricDefinitionId,
                                text: clientConceptMetric.metricCode
                            };
                        })}
                        errorLabelPointingDirection="left"
                        disabled={false}
                        compact={true}
                        search={true}
                        setValue={setValue}
                    />
                </Table.Cell>
                <Table.Cell
                    key={"moduleMetric_" + index + "_defaultSort"}
                    style={{
                        minWidth: "50px"
                    }}
                >
                    <SemanticHookFormDropdown
                        control={control}
                        name={`clientConceptPageModuleMetrics.${index}.defaultSort`}
                        type="text"
                        options={trueFalseOptions}
                        errorLabelPointingDirection="left"
                        disabled={formSetup.isDefaultSortDisabled}
                        compact={true}
                        setValue={setValue}
                    />
                </Table.Cell>
                <Table.Cell
                    key={"moduleMetric_" + index + "_drilldownPageCode"}
                    style={{
                        minWidth: "200px"
                    }}
                >
                    <SemanticHookFormDropdown
                        control={control}
                        name={`clientConceptPageModuleMetrics.${index}.drilldownPageCode`}
                        type="text"
                        options={drilldownPageCodeOptions}
                        errorLabelPointingDirection="left"
                        disabled={formSetup.isDrilldownPageCodeDisabled}
                        clearable={true}
                        setValue={setValue}
                    />
                </Table.Cell>
                <Table.Cell key={"moduleMetric_" + index + "_metricOrder"}>
                    <SemanticHookFormDropdown
                        control={control}
                        name={`clientConceptPageModuleMetrics.${index}.metricOrder`}
                        type="text"
                        errorLabelPointingDirection="left"
                        disabled={formSetup.isMetricOrderDisabled}
                        options={metricOrderOptions}
                        compact={true}
                        setValue={setValue}
                    />
                </Table.Cell>
                <Table.Cell key={"moduleMetric_" + index + "_toggleOrder"}>
                    <SemanticHookFormDropdown
                        control={control}
                        name={`clientConceptPageModuleMetrics.${index}.toggleOrder`}
                        type="text"
                        errorLabelPointingDirection="left"
                        disabled={formSetup.isToggleOrderDisabled}
                        options={toggleOrderOptions}
                        compact={true}
                        setValue={setValue}
                    />
                </Table.Cell>
                <Table.Cell
                    key={"moduleMetric_" + index + "_metricOrderCategory"}
                    style={{
                        minWidth: "200px"
                    }}
                >
                    <SemanticHookFormInputField
                        control={control}
                        setValue={setValue}
                        name={`clientConceptPageModuleMetrics.${index}.metricOrderCategory`}
                        type="text"
                        errorLabelPointingDirection="left"
                        disabled={formSetup.isMetricOrderCategoryDisabled}
                    />
                </Table.Cell>
                <Table.Cell key={"moduleMetric_" + index + "_moduleTypeSortOrder"}>
                    <SemanticHookFormDropdown
                        control={control}
                        name={`clientConceptPageModuleMetrics.${index}.moduleTypeSortOrder`}
                        type="text"
                        errorLabelPointingDirection="left"
                        disabled={formSetup.isModuleTypeSortOrderDisabled}
                        options={moduleTypeSortOrderOptions}
                        compact={true}
                        setValue={setValue}
                    />
                </Table.Cell>
                <Table.Cell key={"moduleMetric_" + index + "_dayPartType"}>
                    <SemanticHookFormDropdown
                        control={control}
                        name={`clientConceptPageModuleMetrics.${index}.dayPartType`}
                        type="text"
                        errorLabelPointingDirection="left"
                        disabled={formSetup.isDayPartTypeDisabled}
                        options={dayPartTypeOptions}
                        compact={true}
                        clearable={true}
                        setValue={setValue}
                    />
                </Table.Cell>
                <Table.Cell key={"moduleMetric_" + index + "_drilldownType"}>
                    <SemanticHookFormDropdown
                        control={control}
                        name={`clientConceptPageModuleMetrics.${index}.drilldownType`}
                        type="text"
                        options={drilldownTypeOptions}
                        errorLabelPointingDirection="left"
                        disabled={formSetup.isDrilldownTypeDisabled}
                        clearable={true}
                        setValue={setValue}
                    />
                </Table.Cell>
                <Table.Cell key={"moduleMetric_" + index + "_hasFilter"}>
                    <SemanticHookFormDropdown
                        control={control}
                        name={`clientConceptPageModuleMetrics.${index}.hasFilter`}
                        type="text"
                        errorLabelPointingDirection="left"
                        disabled={formSetup.isHasFilterDisabled}
                        options={trueFalseOptions}
                        compact={true}
                        setValue={setValue}
                    />
                </Table.Cell>
            </Table.Row>
        );
    } else {
        return <></>;
    }
};

export default ModuleMetricFormRow;

function determineEnabledAndDisabledFields(
    clientConceptPageModuleMetric: IModuleMetricDefinition
) {
    let formSetup: IModuleMetricFormSetup;
    if (clientConceptPageModuleMetric.pageCode === PageCode.EmployeeRetention) {
        formSetup = {
            isDefaultSortDisabled: false,
            isDrilldownPageCodeDisabled: true,
            isMetricOrderDisabled: true,
            isToggleOrderDisabled: false,
            isMetricOrderCategoryDisabled: true,
            isModuleTypeSortOrderDisabled: true,
            isDayPartTypeDisabled: true,
            isDrilldownTypeDisabled: true,
            isHasFilterDisabled: false
        };

        return formSetup;
    }

    switch (clientConceptPageModuleMetric.moduleType) {
        case ModuleType.ColumnChart:
            formSetup = {
                isDefaultSortDisabled: false,
                isDrilldownPageCodeDisabled: true,
                isMetricOrderDisabled: false,
                isToggleOrderDisabled: false,
                isMetricOrderCategoryDisabled: true,
                isModuleTypeSortOrderDisabled: false,
                isDayPartTypeDisabled: false,
                isDrilldownTypeDisabled: true,
                isHasFilterDisabled: true
            };
            break;
        case ModuleType.LineChart:
            formSetup = {
                isDefaultSortDisabled: false,
                isDrilldownPageCodeDisabled: true,
                isMetricOrderDisabled: true,
                isToggleOrderDisabled: true,
                isMetricOrderCategoryDisabled: true,
                isModuleTypeSortOrderDisabled: false,
                isDayPartTypeDisabled: false,
                isDrilldownTypeDisabled: true,
                isHasFilterDisabled: true
            };
            break;
        case ModuleType.InventoryDetails:
            formSetup = {
                isDefaultSortDisabled: false,
                isDrilldownPageCodeDisabled: true,
                isMetricOrderDisabled: true,
                isToggleOrderDisabled: true,
                isMetricOrderCategoryDisabled: true,
                isModuleTypeSortOrderDisabled: true,
                isDayPartTypeDisabled: true,
                isDrilldownTypeDisabled: true,
                isHasFilterDisabled: true
            };
            break;
        case ModuleType.LocationDetails:
            formSetup = {
                isDefaultSortDisabled: true,
                isDrilldownPageCodeDisabled: true,
                isMetricOrderDisabled: true,
                isToggleOrderDisabled: true,
                isMetricOrderCategoryDisabled: true,
                isModuleTypeSortOrderDisabled: true,
                isDayPartTypeDisabled: true,
                isDrilldownTypeDisabled: true,
                isHasFilterDisabled: false
            };
            break;
        case ModuleType.MetricDetailGrid:
            formSetup = {
                isDefaultSortDisabled: false,
                isDrilldownPageCodeDisabled: true,
                isMetricOrderDisabled: false,
                isToggleOrderDisabled: false,
                isMetricOrderCategoryDisabled: true,
                isModuleTypeSortOrderDisabled: false,
                isDayPartTypeDisabled: true,
                isDrilldownTypeDisabled: false,
                isHasFilterDisabled: true
            };
            break;
        case ModuleType.MetricStanding:
            formSetup = {
                isDefaultSortDisabled: false,
                isDrilldownPageCodeDisabled: true,
                isMetricOrderDisabled: true,
                isToggleOrderDisabled: true,
                isMetricOrderCategoryDisabled: true,
                isModuleTypeSortOrderDisabled: true,
                isDayPartTypeDisabled: true,
                isDrilldownTypeDisabled: true,
                isHasFilterDisabled: true
            };
            break;
        case ModuleType.MetricTile:
            formSetup = {
                isDefaultSortDisabled: false,
                isDrilldownPageCodeDisabled: false,
                isMetricOrderDisabled: false,
                isToggleOrderDisabled: false,
                isMetricOrderCategoryDisabled: false,
                isModuleTypeSortOrderDisabled: true,
                isDayPartTypeDisabled: true,
                isDrilldownTypeDisabled: true,
                isHasFilterDisabled: true
            };
            break;
        case ModuleType.TransactionDetails:
            formSetup = {
                isDefaultSortDisabled: true,
                isDrilldownPageCodeDisabled: true,
                isMetricOrderDisabled: true,
                isToggleOrderDisabled: true,
                isMetricOrderCategoryDisabled: true,
                isModuleTypeSortOrderDisabled: true,
                isDayPartTypeDisabled: true,
                isDrilldownTypeDisabled: true,
                isHasFilterDisabled: false
            };
            break;
        default:
            formSetup = {
                isDefaultSortDisabled: false,
                isDrilldownPageCodeDisabled: false,
                isMetricOrderDisabled: false,
                isToggleOrderDisabled: false,
                isMetricOrderCategoryDisabled: false,
                isModuleTypeSortOrderDisabled: false,
                isDayPartTypeDisabled: false,
                isDrilldownTypeDisabled: false,
                isHasFilterDisabled: false
            };
            break;
    }
    return formSetup;
}

function clearFormValuesAsNeeded(
    formSetup: IModuleMetricFormSetup,
    clientConceptPageModuleMetric: IModuleMetricDefinition,
    setValue: UseFormSetValue<any>,
    index: number
) {
    if (
        formSetup.isDayPartTypeDisabled &&
        clientConceptPageModuleMetric.dayPartType !== null
    ) {
        setValue(`clientConceptPageModuleMetrics.${index}.dayPartType`, null);
    }

    if (
        formSetup.isDefaultSortDisabled &&
        clientConceptPageModuleMetric.defaultSort !== false
    ) {
        setValue(`clientConceptPageModuleMetrics.${index}.defaultSort`, false);
    }

    if (
        formSetup.isDrilldownPageCodeDisabled &&
        clientConceptPageModuleMetric.drilldownPageCode !== null
    ) {
        setValue(`clientConceptPageModuleMetrics.${index}.drilldownPageCode`, null);
    }

    if (
        formSetup.isDrilldownTypeDisabled &&
        clientConceptPageModuleMetric.drilldownType !== null
    ) {
        setValue(`clientConceptPageModuleMetrics.${index}.drilldownType`, null);
    }

    if (
        formSetup.isHasFilterDisabled &&
        clientConceptPageModuleMetric.hasFilter !== false
    ) {
        setValue(`clientConceptPageModuleMetrics.${index}.hasFilter`, false);
    }

    if (
        formSetup.isMetricOrderCategoryDisabled &&
        clientConceptPageModuleMetric.metricOrderCategory !== null
    ) {
        setValue(
            `clientConceptPageModuleMetrics.${index}.metricOrderCategory`,
            null
        );
    }

    if (
        formSetup.isMetricOrderDisabled &&
        clientConceptPageModuleMetric.metricOrder !== 0
    ) {
        setValue(`clientConceptPageModuleMetrics.${index}.metricOrder`, 0);
    }

    if (
        formSetup.isModuleTypeSortOrderDisabled &&
        clientConceptPageModuleMetric.moduleTypeSortOrder !== 1
    ) {
        setValue(`clientConceptPageModuleMetrics.${index}.moduleTypeSortOrder`, 1);
    }

    if (
        formSetup.isToggleOrderDisabled &&
        clientConceptPageModuleMetric.toggleOrder !== 0
    ) {
        setValue(`clientConceptPageModuleMetrics.${index}.toggleOrder`, 0);
    }
}

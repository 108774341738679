import apiUtil from "../../../util/apiUtil";
import {dateToString} from "../../../util/dateUtil";
import {Concept} from "../../application/api/applicationModels";
import {
    IBusinessUnitMetricDataResult,
    ICardRequest,
    IGroupKpiData,
    IMetricDataDailyThresholdViolationCounts,
    IMetricRequest,
    IModuleMetricDefinition
} from "./dashboardModels";

const URL = "/api/metric";
const metricApi = {
    async fetchMetrics({
        clientConcept_PK,
        date,
        calendarId,
        concept,
        metricCodes,
        abortSignal
    }: IMetricRequest): Promise<IBusinessUnitMetricDataResult> {
        const requestBody = {
            metricCodes: metricCodes
        };
        return await apiUtil.postData(
            `${URL}/MetricStandingsData/${clientConcept_PK}/${dateToString(
                date
            )}/${calendarId}/${concept}`,
            requestBody,
            abortSignal
        );
    },

    async fetchCardsMetricDefinitions(
        clientConcept_PK: number,
        abortSignal: AbortSignal
    ): Promise<IModuleMetricDefinition[]> {
        return await apiUtil.fetchData(
            `${URL}/CardsMetricDefinitions/${clientConcept_PK}`,
            abortSignal
        );
    },

    async fetchCards({
        clientConcept_PK,
        businessUnit,
        date,
        calendarId,
        concept,
        metricCodes,
        abortSignal
    }: ICardRequest): Promise<IGroupKpiData> {
        const requestBody = {
            businessUnit: businessUnit,
            metricCodes: metricCodes
        };
        return await apiUtil.postData(
            `${URL}/Cards/${clientConcept_PK}/${dateToString(
                date
            )}/${calendarId}/${concept}`,
            requestBody,
            abortSignal
        );
    },

    async fetchHouses({
        clientConcept_PK,
        businessUnit,
        date,
        calendarId,
        concept,
        page_PK,
        abortSignal
    }: ICardRequest): Promise<IMetricDataDailyThresholdViolationCounts[]> {
        return await apiUtil.postData(
            `${URL}/Houses/${clientConcept_PK}/${dateToString(
                date
            )}/${calendarId}/${concept}/${page_PK}`,
            businessUnit,
            abortSignal
        );
    },

    async fetchDailyMetricData(
        clientConcept_PK: number,
        locationPk: number,
        date: Date,
        calendarId: number,
        concept: Concept,
        abortSignal: AbortSignal
    ): Promise<IBusinessUnitMetricDataResult> {
        return apiUtil.fetchData(
            `${URL}/DailyMetricData/${clientConcept_PK}/${locationPk}/${dateToString(
                date
            )}/${calendarId}/${concept}`,
            abortSignal
        );
    }
};

export default metricApi;

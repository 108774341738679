export enum ErrorMessageContent {
    SomethingWentWrong = "Something went wrong.",
    NoDataToDisplay = "No data to display.",
    NotAuthenticated = "You are not authenticated.",
    NoPageAccess = "You do not have access to this resource. Please contact support if you believe this is incorrect.",
    NoDashboardAccess = "You do not have access to Delaget Coach.",
    NoCoachDetectAccess = "You do not have access to Delaget Detect.",
    NoDeliveryOperationsAccess = "You do not have access to Delaget Delivery Operations.",
    WrongAppParameters = "You do not have access to this resource. Application parameters are invalid or not set correctly."
}

import {Component} from "react";
import {Button, Segment} from "semantic-ui-react";
import {
    defaultLegacySiteBaseUrl,
    legacySiteLoginPageUrl
} from "../../../util/apiEndPointUtil";

interface IProps {
    legacySiteBaseUrl: string;
}

class SessionTimeout extends Component<IProps> {
    handleContinueButtonClick = (): void => {
        const legacyLoginPath =
            (this.props.legacySiteBaseUrl || defaultLegacySiteBaseUrl) +
            legacySiteLoginPageUrl;
        const url = window.location.href.split("?")[0];
        const queryString = encodeURIComponent(window.location.search.substring(1)); //remove the "?" from the query string - encode inner query parameter to perserve values
        const redirectToPath = `${legacyLoginPath}?RU=${url}&RUQ=${queryString}`;
        window.location.assign(redirectToPath);
    };

    render(): JSX.Element {
        // Automatically redirect if user is using PWA
        if (window.matchMedia("(display-mode: standalone)").matches) {
            this.handleContinueButtonClick();
            return <></>;
        } else {
            return (
                <div style={sessionTimeoutContainerStyle}>
                    <Segment className="body-segment">
                        <h3>Session Expired</h3>
                        <p>For security purposes, your session has expired.</p>
                        <Button
                            className="btn btn-blue-solid"
                            onClick={this.handleContinueButtonClick}
                        >
                            Click here to continue
                        </Button>
                    </Segment>
                </div>
            );
        }
    }
}

export default SessionTimeout;

const sessionTimeoutContainerStyle = {
    width: 350,
    marginRight: "auto",
    marginLeft: "auto",
    height: "auto",
    marginTop: 100,
    padding: 10
};

import {orderBy} from "lodash";
import * as React from "react";
import {connect} from "react-redux";
import {Divider, Grid} from "semantic-ui-react";
import Filter from "../../../components/Filter";
import PaginationComponent from "../../../components/Pagination";
import {
    ErrorMessage,
    LoadingMessage,
    NoAccessMessage,
    NoResultsMessage
} from "../../../components/SystemMessages";
import {AppState} from "../../../state";
import {itemsPerPage} from "../../../util/dataFormatUtil";
import {dateToString} from "../../../util/dateUtil";
import {Concept, DataStatus} from "../../application/api/applicationModels";
import {
    IBusinessUnitMetricData,
    IModuleMetricDefinition
} from "../api/dashboardModels";
import {actionCreators} from "../state/actions";
import MetricTable from "./MetricTable";
import {ErrorMessageContent} from "util/errorUtil";

// props we receive from the redux State
interface IReduxProps {
    concept: Concept;
    isGridLoading: boolean;
    fetchMetricsDataStatus: DataStatus;
    locationMetricData: IBusinessUnitMetricData[];
    metricDefinitions: IModuleMetricDefinition[];
    screenWidth: number;
    metricConfigurationSaveButtonClicked: boolean;
}

interface IProps {
    clientConcept_PK: number;
    rootGroupId: string;
    date: Date;
    calendarId: number;
}

interface IState {
    activePage: number; // this is set by the Pagination Component through handlePageChange
    filter: string;
}

type MetricTableContainerProps = IReduxProps & IProps & typeof actionCreators;

class MetricTableContainer extends React.Component<
    MetricTableContainerProps,
    IState
> {
    abortController = {
        current: new AbortController()
    };

    public state = {
        activePage: 1,
        filter: ""
    };

    // this lifecycle method simply fires when component mounts
    public componentDidMount() {
        this.props.fetchMetrics({
            clientConcept_PK: this.props.clientConcept_PK,
            date: this.props.date,
            calendarId: this.props.calendarId,
            concept: this.props.concept,
            abortSignal: this.abortController.current.signal
        });
    }

    // this lifecycle method is here to check for date changes and it can access both old and new props
    public componentDidUpdate(prevProps: MetricTableContainerProps) {
        if (
            dateToString(this.props.date) !== dateToString(prevProps.date) ||
            this.props.rootGroupId !== prevProps.rootGroupId ||
            (this.props.metricConfigurationSaveButtonClicked === true &&
                this.props.metricConfigurationSaveButtonClicked !==
                    prevProps.metricConfigurationSaveButtonClicked)
        ) {
            this.abortController.current.abort();
            this.abortController.current = new AbortController();

            this.props.fetchMetrics({
                clientConcept_PK: this.props.clientConcept_PK,
                date: this.props.date,
                calendarId: this.props.calendarId,
                concept: this.props.concept,
                abortSignal: this.abortController.current.signal
            });
        }
    }

    // this lifecyle method will cancel or clean up changes before the component is removed
    public componentWillUnmount() {
        this.abortController.current.abort();
    }

    public handlePageChange = (
        e: React.MouseEvent<HTMLAnchorElement>,
        {activePage}: {activePage?: number | string}
    ) => {
        this.setState({
            activePage: activePage as number
        });
    };

    public handleFilterChange(filter: string) {
        this.setState({
            filter,
            activePage: 1 // set active page to 1 on filtering
        });
    }

    // used to move to Page 1 on sort
    public handleSortPageChange = () => {
        this.setState({
            activePage: 1
        });
    };

    public renderMetricTable(filteredData: IBusinessUnitMetricData[]) {
        // Sort metric definition on all sorting orders
        const sortedMetricDefinitions = orderBy(this.props.metricDefinitions, [
            "sortOrder",
            "toggleOrder",
            "metricOrder"
        ]);

        const isFiltered = !(this.state.filter == null || this.state.filter === "");

        if (
            filteredData &&
            filteredData.length !== 0 &&
            !this.props.isGridLoading &&
            this.props.fetchMetricsDataStatus === DataStatus.Loaded
        ) {
            return (
                <React.Fragment>
                    <Divider hidden fitted />
                    <MetricTable
                        metricData={filteredData}
                        metricDefinitions={sortedMetricDefinitions}
                        date={this.props.date}
                        groupID={this.props.rootGroupId}
                        calendarId={this.props.calendarId}
                        concept={this.props.concept}
                        clientConcept_PK={this.props.clientConcept_PK}
                        startIndex={(this.state.activePage - 1) * itemsPerPage}
                        itemsPerPage={itemsPerPage}
                        handleSortPageChange={this.handleSortPageChange}
                        screenWidth={this.props.screenWidth}
                    />
                </React.Fragment>
            );
        } else if (this.props.fetchMetricsDataStatus === DataStatus.Failed) {
            return <ErrorMessage />;
        } else if (this.props.fetchMetricsDataStatus === DataStatus.Forbidden) {
            return <NoAccessMessage text={ErrorMessageContent.NoPageAccess} />;
        } else if (isFiltered) {
            return <NoResultsMessage text="locations" />;
        } else if (this.props.isGridLoading) {
            return <LoadingMessage />;
        } else {
            return null;
        }
    }

    public render() {
        const filteredData = filterData(
            this.state.filter,
            this.props.locationMetricData
        );

        return (
            <React.Fragment>
                <Grid stackable>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Filter
                            filter={this.state.filter}
                            changeFilter={this.handleFilterChange.bind(this)}
                        />
                    </Grid.Column>
                    <Grid.Column
                        mobile={16}
                        tablet={8}
                        computer={8}
                        textAlign="right"
                    >
                        <PaginationComponent
                            activePage={this.state.activePage}
                            changePage={this.handlePageChange}
                            totalPages={Math.ceil(
                                filteredData.length / itemsPerPage
                            )}
                        />
                    </Grid.Column>
                    <Grid.Row className="padding-row-14px">
                        {this.renderMetricTable(filteredData)}
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign="right">
                            <PaginationComponent
                                activePage={this.state.activePage}
                                changePage={this.handlePageChange}
                                totalPages={Math.ceil(
                                    filteredData.length / itemsPerPage
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        );
    }
}

function filterData(filter: string, metricData: IBusinessUnitMetricData[]) {
    const filteredMetricData: IBusinessUnitMetricData[] = [];
    const trimmedFilter = filter.trim().toLowerCase();
    metricData.forEach((row) => {
        if (
            row.businessUnitName.toLowerCase().indexOf(trimmedFilter) !== -1 ||
            row.parentBusinessUnitName.toLowerCase().indexOf(trimmedFilter) !== -1 ||
            row.businessUnitDescription.toLowerCase().indexOf(trimmedFilter) !== -1
        ) {
            return filteredMetricData.push(row);
        }
        return null;
    });
    return filteredMetricData;
}

const mapStateToProps = (state: AppState) => ({
    concept: state.application.selectedClientConcept.concept,
    isGridLoading: state.dashboard.isGridLoading,
    fetchMetricsDataStatus: state.dashboard.fetchMetricsDataStatus,
    locationMetricData: state.dashboard.locationMetricData,
    metricDefinitions: state.dashboard.metricDefinitions,
    screenWidth: state.application.screenWidth,
    metricConfigurationSaveButtonClicked:
        state.application.metricConfigurationParams.saveButtonClicked
});

export default connect(mapStateToProps, actionCreators, (state, actions, props) => ({
    ...state,
    ...actions,
    ...props
}))(MetricTableContainer);
